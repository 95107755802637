import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { IAWSAccessRequests } from '../interface/awsaccessrequests';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-declineaccessrequest',
  templateUrl: './declineaccessrequest.component.html',
  styleUrls: ['./declineaccessrequest.component.css']
})
export class DeclineaccessrequestComponent implements OnInit {
  denyAccessForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  local_data: any;
  disableButton = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  // Tetsing code 

  searchUserCtrl = new FormControl();
  filteredUser: any;
  isLoading = false;
  errorMsg: string;


  /// testing code




  constructor(@Inject(DOCUMENT) private document: any, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccessRequests, public dialogRef: MatDialogRef<DeclineaccessrequestComponent>, private http: HttpClient, private rs: RepositoryService, private router: Router, private fb: FormBuilder) {
    this.local_data = data;
    //console.table(this.local_data);
    //console.log(this.local_data['accessRequests']['groupName'])
  }
  ngOnInit() {
    this.denyAccessForm = this.fb.group({
      cloudAccount: [{ value: this.local_data['accessRequests']['accountName'], disabled: true }],
      groupName: [{ value: this.local_data['accessRequests']['groupName'], disabled: true }],
      groupID: [{ value: this.local_data['accessRequests']['groupID'], disabled: true }],
      requestID: [{ value: this.local_data['accessRequests']['requestID'], disabled: true }],
      additionalApprovers: [{ value: this.local_data['accessRequests']['additionalApprovers'], disabled: true }],
      requestorID: [{ value: this.local_data['accessRequests']['requestorID'], disabled: true }],
      requestorName: [{ value: this.local_data['accessRequests']['requestorName'], disabled: true }],
      comments: [{ value: this.local_data['accessRequests']['comments'], disabled: true }],
      //approverID: [{ value: sessionStorage.getItem("wwid"), disabled : true }],
      approvercomments: ['', [Validators.required, Validators.maxLength(100),Validators.minLength(3)]],





      //approverID : [{ value: this.local_data['awsAccountInfo']['accountOwners'], disabled: true }],
      //accountID : [{ value: this.local_data['awsAccountInfo']['id'], disabled: true }],
      // accountIDAzureRef : [{ value: this.local_data['awsAccountInfo']['azureADRef'], disabled: true }],
      //requestorID : [{ value:  sessionStorage.getItem('wwid'), disabled: true }],
      // requestorName : [{ value:  sessionStorage.getItem('Username'), disabled: true }],
      //requestcloudType : [{ value:  cloudType, disabled: true }]

    });

    

  }

 
  get f() {
    return this.denyAccessForm.controls;
  }
  get approvercomments() {
    return this.denyAccessForm.get('approvercomments');
  }
  get cloud_account() {
    return this.denyAccessForm.get('cloudAccount');
  }
  get cloud_role() {
    return this.denyAccessForm.get('groupName').value;
  }



  declineRequest() {
    this.submitted = true;
    this.disableButton = true;
    if (this.denyAccessForm.invalid) {
      return;
    }
    this.denyAccessForm.getRawValue()
    //console.table("accessRequests:" + JSON.stringify(this.delegateAccessForm.getRawValue()));


    let wwids = this.denyAccessForm.getRawValue()['requestorID'];
    let groupID = this.denyAccessForm.getRawValue()['groupID'];
    let groupName = this.denyAccessForm.getRawValue()['groupName'];
    let accountName = this.denyAccessForm.getRawValue()['cloudAccount'];
    let accountIDAzureRef = "NA";
    let requestType = "SSR";
    let action = "declined";
    let approverID = sessionStorage.getItem("wwid");
    let requestID = this.denyAccessForm.getRawValue()['requestID'];
    let additionalApprovers = this.denyAccessForm.getRawValue()['additionalApprovers'];
    let requestorName = this.denyAccessForm.getRawValue()['requestorName'];
    let delegatedUserID = this.denyAccessForm.getRawValue()['wwid'];
    let comments = this.denyAccessForm.getRawValue()['approvercomments'];
    //console.log("comments:" + comments);


    this.rs.actionRequestsAWSAccess(wwids, groupID, groupName, accountName, accountIDAzureRef, requestType, action, approverID, requestID, additionalApprovers, requestorName, delegatedUserID, comments).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("declined") != -1) {
        //console.log('Approved');
        //console.log(res['body']);
        this.showMsg = true;
        this.showErrMsg = false;
        this.dialogRef.close({ event: 'Save' });
      }
      else {
        //console.log('Declined');
        this.showErrMsg = true;
        this.showMsg = false;
        this.dialogRef.close({ event: 'Falied' });
      }

    })
  }
  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}