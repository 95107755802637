import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { RepositoryService } from './../service/repository.service';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
@Component({
  selector: 'app-requestazureadsso',
  templateUrl: './requestazureadsso.component.html',
  styleUrls: ['./requestazureadsso.component.css']
})
export class RequestazureadssoComponent implements OnInit {
  contactForm: FormGroup;
  contactFormAllDetails: FormGroup;
  appRepoApps: any
  filteredappRepoApps: any
  appRepoAppDetails: any
  loading = true;
  submitted: boolean = false;
  showAccessAPI: boolean = false;
  showXMLMetadata: boolean = false;
  showManualMetadata: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private rs: RepositoryService, private router: Router) { }

  ngOnInit() {
    var isAuthSuccess = sessionStorage.getItem('isAuthSuccess');
    this.getAppsFromAppRepo();

    //if (isLoggedIn == null || isAuthSuccess == null) {
    if (isAuthSuccess == null) {
      this.router.navigate(['']);
    }
    this.contactForm = this.fb.group({

      appname: ['', [Validators.required, Validators.maxLength(500), Validators.minLength(2)]],

    });

    this.contactFormAllDetails = this.fb.group({
      //appidname: [{ value: '', disabled: true }],
      //appidownername: [{ value: '', disabled: true }],
      //appidownerWWID: [{ value: '', disabled: true }],
      //appidBC: [{ value: '', disabled: true }],
      //appidRC: [{ value: '', disabled: true }],
      //appidBU: [{ value: '', disabled: true }],
      appidname: ['', [Validators.required, Validators.maxLength(1000), Validators.minLength(2)]],
      appidownername: ['', [Validators.required, Validators.maxLength(200), Validators.minLength(2)]],
      appidownerWWID: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(5)]],
      appidBC: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(1)]],
      redirectURL: ['http://localhost', [Validators.required, Validators.maxLength(500), Validators.minLength(1)]],
      appidRC: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(1)]],
      appidBU: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(1)]],
      appid: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(1)]],
      appregname: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(1)]],
      headerresponse: ['', [Validators.required, Validators.maxLength(500), Validators.minLength(1)]],
      metadataXML: ['-NA-', [Validators.required]],
      environment: ['', [Validators.required]],
      requestType: ['', [Validators.required]],
      appType: ['', [Validators.required]],
      serviceDetails: ['', [Validators.required]],
      accessAPI: ['-NA-', [Validators.required, Validators.maxLength(500), Validators.minLength(1)]],
      spID: ['-NA-', [Validators.required]],
      ASCUrl: ['-NA-', [Validators.required]],
      logoutRequestURL: ['-NA-', [Validators.required]],
      logoutResponseURL: ['-NA-', [Validators.required]],
      comments: ['', [Validators.required, Validators.maxLength(500), Validators.minLength(1)]]


      //networkAttached: [{ value: this.local_data['AccountInfo']['networkAttached'], disabled: true }],

      //: ['', [Validators.required, Validators.maxLength(500), Validators.minLength(2)]],
      //appidname: ['', [Validators.required, Validators.maxLength(500), Validators.minLength(2)]],
      //appidname: ['', [Validators.required, Validators.maxLength(500), Validators.minLength(2)]],

    });

    this.onChanges()
  }

  get f() {
    return this.contactForm.controls;
  }
  get appname() {
    return this.contactForm.get('appname');
  }

  get fd() {
    return this.contactFormAllDetails.controls;
  }

  get comments() {
    return this.contactFormAllDetails.get('comments');
  }
  get metadataXML() {
    return this.contactFormAllDetails.get('metadataXML');
  }
  get headerresponse() {
    return this.contactFormAllDetails.get('headerresponse');
  }

  get accessAPI() {
    return this.contactFormAllDetails.get('accessAPI');
  }



  onKey(value) {
    //console.log(value)
    this.appRepoApps = this.search(value);
  }

  search(value: string) {

    let filter = value.toLowerCase();
    //console.log(filter)
    return this.filteredappRepoApps.filter(option => option.appname.toLowerCase().includes(filter) || option.appid.includes(filter));
  }

  onChanges() {
    //console.log("Printing...");
    this.contactForm.valueChanges.subscribe(userInput => {
      //console.log(userInput);
      //console.log(userInput['appname']);
      this.appRepoApps = this.search(userInput['appname']);



      //}

    });
  }


  getAppsFromAppRepo() {
    try {
      this.rs.getAppsFromAppRepo("All").subscribe(res => {
        //console.log(JSON.stringify(res))
        this.appRepoApps = res["Items"];
        this.loading = false;
        this.showErrMsg = false
        this.showMsg = false
        this.filteredappRepoApps = this.appRepoApps;

        //console.log('azureResourceGroups: ' + this.azureResourceGroups);
        //console.log('filteredResourceGroups: ' + this.filteredResourceGroups);
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }

  }
  onSelectionChangeEnv(event: MatRadioChange) {
    this.loading = true;

    //console.log(event.value)
    this.contactFormAllDetails.controls.environment.setValue(event.value);
    

  }
  onSelectionChangeReqType(event: MatRadioChange) {
    this.loading = true;
    //console.log(event.value)
    if (event.value == 'Client Registration') {
      this.showAccessAPI = true;
      this.contactFormAllDetails.controls.accessAPI.setValue("");
      this.contactFormAllDetails.controls.appregname.setValue("XXX_" + this.contactFormAllDetails.controls.environment.value + "_" + this.contactFormAllDetails.controls.appid.value + "_WebApp01_Ctl01");
    }
    else if (event.value == 'SPN (Service Principal Name)') {
      this.showAccessAPI = false;
      this.contactFormAllDetails.controls.accessAPI.setValue("-NA-");
      this.contactFormAllDetails.controls.appregname.setValue("XXX_" + this.contactFormAllDetails.controls.environment.value + "_" + this.contactFormAllDetails.controls.appid.value + "_WebApp01_SPN");
    }
    else if (event.value == 'Service to Service') {
      this.showAccessAPI = false;
      this.contactFormAllDetails.controls.accessAPI.setValue("-NA-");
      this.contactFormAllDetails.controls.appregname.setValue("XXX_" + this.contactFormAllDetails.controls.environment.value + "_" + this.contactFormAllDetails.controls.appid.value + "_WebApp01_S2S");
    }
    else if (event.value == 'SAML') {
      this.showAccessAPI = false;
      this.contactFormAllDetails.controls.accessAPI.setValue("-NA-");
      this.contactFormAllDetails.controls.appregname.setValue("XXX_" + this.contactFormAllDetails.controls.environment.value + "_" + this.contactFormAllDetails.controls.appid.value + "_WebApp01_SAML");
    }
    this.contactFormAllDetails.controls.requestType.setValue(event.value);


  }
  onSelectionChangeAppType(event: MatRadioChange) {
    this.loading = true;

    //console.log(event.value)
    this.contactFormAllDetails.controls.appType.setValue(event.value);
  }
  onSelectionChangeserviceDetails(event: MatRadioChange) {

    //console.log(event.value)
    this.contactFormAllDetails.controls.serviceDetails.setValue(event.value);

    this.loading = true;
    if (event.value == 'Load From Provider Metadata') {
      this.showXMLMetadata = true;
      this.showManualMetadata = false;
      this.contactFormAllDetails.controls.metadataXML.setValue("");
      this.contactFormAllDetails.controls.spID.setValue("-NA-");
      this.contactFormAllDetails.controls.ASCUrl.setValue("-NA-");
      this.contactFormAllDetails.controls.logoutRequestURL.setValue("-NA-");
      this.contactFormAllDetails.controls.logoutResponseURL.setValue("-NA-");

    }
    else {
      this.showXMLMetadata = false;
      this.showManualMetadata = true;
      this.contactFormAllDetails.controls.metadataXML.setValue("-NA-");
      this.contactFormAllDetails.controls.spID.setValue("");
      this.contactFormAllDetails.controls.ASCUrl.setValue("");
      this.contactFormAllDetails.controls.logoutRequestURL.setValue("");
      this.contactFormAllDetails.controls.logoutResponseURL.setValue("");

    }

  }
  appSelectAction(data: string) {

    //console.log("data:::", data)
    try {
      this.rs.getAppsFromAppRepo(data).subscribe(res => {
        //console.log(JSON.stringify(res))
        this.appRepoAppDetails = res["Items"];
        this.loading = false;
        //console.log(JSON.stringify(this.appRepoAppDetails))
        //console.log('techownerwwid: ' + this.appRepoAppDetails[0]['techownerwwid']);
        this.contactFormAllDetails.controls.appidname.setValue(this.appRepoAppDetails[0]['appname']);
        this.contactFormAllDetails.controls.appidownername.setValue(this.appRepoAppDetails[0]['techowner']);
        this.contactFormAllDetails.controls.appidownerWWID.setValue(this.appRepoAppDetails[0]['techownerwwid']);
        this.contactFormAllDetails.controls.appidBC.setValue(this.appRepoAppDetails[0]['BC']);
        this.contactFormAllDetails.controls.appidRC.setValue(this.appRepoAppDetails[0]['RC']);
        this.contactFormAllDetails.controls.appidBU.setValue(this.appRepoAppDetails[0]['BU']);
        this.contactFormAllDetails.controls.appid.setValue(this.appRepoAppDetails[0]['appid']);


      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
    //Added to clear form on selection changes
    //this.contactForm.markAsUntouched();
    //this.contactForm.controls.comments.setValue("");


    //let techowner: string = data['techowner'];
    //this.contactForm.controls.appname.setValue(data['appname']);
    //this.contactForm.controls.appowner.setValue(techowner);
  }

  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;
    if (this.contactFormAllDetails.invalid) {
      return;
    }
    console.table(this.contactFormAllDetails.getRawValue())
    let appidname = this.contactFormAllDetails.getRawValue().appidname;
    let appidownername = this.contactFormAllDetails.getRawValue().appidownername;
    let appidownerWWID = this.contactFormAllDetails.getRawValue().appidownerWWID;
    let appidBC = this.contactFormAllDetails.getRawValue().appidBC;
    let appidRC = this.contactFormAllDetails.getRawValue().appidRC;
    let appidBU = this.contactFormAllDetails.getRawValue().appidBU;
    let redirectURL = this.contactFormAllDetails.getRawValue().redirectURL;
    let appid = this.contactFormAllDetails.getRawValue().appid;
    let appregname = this.contactFormAllDetails.getRawValue().appregname;
    let headerresponse = this.contactFormAllDetails.getRawValue().headerresponse;
    let metadataXML = this.contactFormAllDetails.getRawValue().metadataXML;
    let environment = this.contactFormAllDetails.getRawValue().environment;
    let requestType = this.contactFormAllDetails.getRawValue().requestType;
    let appType = this.contactFormAllDetails.getRawValue().appType;
    let serviceDetails = this.contactFormAllDetails.getRawValue().serviceDetails;
    let accessAPI = this.contactFormAllDetails.getRawValue().accessAPI;
    let spID = this.contactFormAllDetails.getRawValue().spID;
    let ASCUrl = this.contactFormAllDetails.getRawValue().ASCUrl;
    let logoutRequestURL = this.contactFormAllDetails.getRawValue().logoutRequestURL;
    let logoutResponseURL = this.contactFormAllDetails.getRawValue().logoutResponseURL;
    let comments = this.contactFormAllDetails.getRawValue().comments;
    let requestorWWID = sessionStorage.getItem("wwid")
    let requestorName = sessionStorage.getItem("Username")

    this.rs.createAzureADSSORequest(appidname, appidownername, appidownerWWID, appidBC, appidRC, appidBU, redirectURL, appid, appregname, headerresponse, metadataXML, environment, requestType, appType, serviceDetails, accessAPI, spID, ASCUrl, logoutRequestURL, logoutResponseURL, comments, requestorWWID, requestorName).subscribe(res => {
      //console.log(res)
      let statusCode = res['statusCode'];
      //console.log(statusCode)


      if (statusCode == '200') {
        this.showMsg = true
        this.showErrMsg = false
        //this.router.navigate(['/azuressorequests?message=success']);
        const queryParams: Params = { message: 'success' };

        this.router.navigate(
          ['/azuressorequests'],
          {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            //queryParamsHandling: 'merge', // remove to replace all query params by provided
          });

      }
      else {
        this.showErrMsg = true
        this.showMsg = false
        //this.router.navigate(['/azuressorequests?message=failure']);

        const queryParams: Params = { message: 'failure' };

        this.router.navigate(
          ['/azuressorequests'],
          {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            //queryParamsHandling: 'merge', // remove to replace all query params by provided
          });

      }
      //window.scroll(0,0); 
      //this.resetEverything()
    })

    //this.dialogRef.close({event:'Send', data: this.contactForm.getRawValue() });
  }
  closeDialog() {
    //console.table("Clicked Cancel")
    this.router.navigate(
      ['/azuressorequests'],
      {
        relativeTo: this.activatedRoute,
        //queryParams: queryParams,
        //queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

  }
  resetEverything() {
    this.contactFormAllDetails.reset();
    this.contactForm.reset();
    this.contactFormAllDetails.markAsUntouched();
    this.formGroupDirective.resetForm();

  }
}

