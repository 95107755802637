import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { IAWSAccounts } from '../interface/awsaccounts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-azuremgaccess',
  templateUrl: './azuremgaccess.component.html',
  styleUrls: ['./azuremgaccess.component.css']
})
export class AzuremgaccessComponent implements OnInit {
  resourceTypes: string[] = ['CBS-IT', 'Non_CBS_IT','CBS-IT-CN','CBS-IT-CN-POC'];
  mgRoles: any;
  requestAccessForm: FormGroup;
  selectAccessForm: FormGroup;
  card_header: string = "";
  resourceType : string;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  submitted: boolean = false;
  disableButton = false;
  constructor(public dialogRef: MatDialogRef<AzuremgaccessComponent>, private rs: RepositoryService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.getRootMGRoles("CBS-IT");
    this.card_header = "Select the Azure Root Management Group to request access"

    this.selectAccessForm = this.fb.group({
      selectedResourceType: ['CBS-IT', [Validators.required]]

    });


    this.requestAccessForm = this.fb.group({

      azure_role: ['', [Validators.required]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      resourceType: ['CBS-IT', [Validators.required]]

    });
  }
  onSelectionChange(event: MatRadioChange) {

    if (event.value == "CBS-IT") {

      this.resourceType = "CBS-IT"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.mgRoles = [];
      this.getRootMGRoles(this.resourceType);
      this.card_header = "Select the Azure Root Management Group to request access"
    }
   
    
    else if (event.value == "Non_CBS_IT") {
      //console.log(event.value)
      this.resourceType = "Non_CBS_IT"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.mgRoles = [];
      this.getRootMGRoles(this.resourceType);
      this.card_header = "Select the Azure Root Management Group to request access"
     
    }
    else if (event.value == "CBS-IT-CN-Legacy") {
      //console.log(event.value)
      this.resourceType = "CBS-IT-CN-Legacy"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.mgRoles = [];
      this.getRootMGRoles(this.resourceType);
      this.card_header = "Select the Azure Root Management Group to request access"
     
    }
    else if (event.value == "CBS-IT-CN") {
      //console.log(event.value)
      this.resourceType = "CBS-IT-CN"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.mgRoles = [];
      this.getRootMGRoles(this.resourceType);
      this.card_header = "Select the Azure Root Management Group to request access"
     
    }
    else if (event.value == "CBS-IT-CN-POC") {
      //console.log(event.value)
      this.resourceType = "CBS-IT-CN-POC"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.mgRoles = [];
      this.getRootMGRoles(this.resourceType);
      this.card_header = "Select the Azure Root Management Group to request access"
     
    }

  }
  get f() {
    return this.requestAccessForm.controls;
  }
  get comments() {
    return this.requestAccessForm.get('comments');
  }


  get card_title() {
    return this.card_header;
  }
  getRootMGRoles(resourceType: string) {
    //console.log('resourceType: ' + resourceType);
    try {
      this.rs.getAzureGroups("RootManagementGroup", "NA", "NA", resourceType,"NA",resourceType).subscribe(res => {
        this.mgRoles = res['value'];
        //console.log('azureGroups: ' + this.azureGroups);
      })
    }
    catch (error) {
      //console.log("error message:" + error['message']);
    }
  }

  onFormSubmit(azureAccountsRequest: object){
    //console.log(azureAccountsRequest);
    let groupName = azureAccountsRequest['azure_role']['displayName'];
    let groupID = azureAccountsRequest['azure_role']['id'];
    let approverID = "it069,ov229";
    let accountID = azureAccountsRequest['resourceType'];
    let accountIDAzureRef = azureAccountsRequest['resourceType'];
    let accountName = azureAccountsRequest['resourceType'];
    let comments = azureAccountsRequest['comments'];
    let requestorID = sessionStorage.getItem('wwid');
    let requestorName = sessionStorage.getItem('Username');
    let requestcloudType = "Azure Root Management Group";

    //console.log('groupName: ' + groupName);
    //console.log('groupID: ' + groupID);
    //console.log('approverID: ' + approverID);
    //console.log('accountID: ' + accountID);
    //console.log('accountIDAzureRef: ' + accountIDAzureRef);
    //console.log('accountName: ' + accountName);
    //console.log('comments: ' + comments);
    //console.log('requestorID: ' + requestorID);
    //console.log('requestorName: ' + requestorName);
    //console.log('requestcloudType: ' + requestcloudType);
    
    this.rs.requestCloudAccess(groupName, groupID, approverID, accountID, accountIDAzureRef, accountName, comments, requestorID, requestorName, requestcloudType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      //console.log(JSON.stringify(res).indexOf("Error"))
      if (JSON.stringify(res).indexOf("Error") == -1) {
        console.log('Success');

        this.dialogRef.close({ event: 'Success' });
        //this.resetEverything();
      }
      else {

        this.dialogRef.close({ event: 'Falied' });
      }

    })
    

  }
  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;
    this.disableButton = true;
    //console.log(this.requestAccessForm.invalid)
    if (this.requestAccessForm.invalid) {
      return;
    }
    //console.table(this.requestAccessForm.getRawValue())
    this.onFormSubmit(this.requestAccessForm.getRawValue())
    //this.dialogRef.close({event:'Save', data: this.requestAccessForm.getRawValue() });

  }

  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}
