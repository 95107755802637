import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable()
export class AuthService {

  isValidSession(){
    let isAuthSuccess = sessionStorage.getItem('isAuthSuccess');
    let email = sessionStorage.getItem('email');
    let wwid = sessionStorage.getItem('wwid')+"@cummins.com";
    let idToken = sessionStorage.getItem('id_token');
    const helper = new JwtHelperService();
    let decodedToken = helper.decodeToken(idToken);
    let preferred_username = decodedToken['preferred_username']
    //console.log("preferred_username:",preferred_username)
    //console.log("email:",email)
    //console.log("wwid:",wwid)

    let currentTime = new Date();
    let sessionTime = new Date(sessionStorage.getItem('tokenExpiry'));
    let validSessionTime = (sessionTime.getTime() - currentTime.getTime()) / 1000;
    //console.log("isAuthSuccess::",isAuthSuccess);
    //console.log("validSessionTime:::",validSessionTime);
    //if (isLoggedIn == null || isAuthSuccess == null) {
    if (isAuthSuccess == null) {
      console.log("User not authenticated");
      return "userForceLogin";
      //this.router.navigate(['']);
   }
    else if (validSessionTime < 10){
      console.log("Session expired");
      return "sessionInvalid"
      //this.logout();
    }
    else if (email != wwid || email != preferred_username ){
      console.log("Session Variables Corrupted");
      return "sessionInvalid"
      //this.logout();
    }
    else{
      console.log("Session is valid");
      return "sessionValid";
    }
  }
   get isLoggedIn() {
    return localStorage.getItem('isLoggedIn');
  }

  get isUserAuthIn() {
    return sessionStorage.getItem('isAuthSuccess');
  }

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) { }

  login() {
    localStorage.setItem('isLoggedIn', 'true');
    this.document.location.href = environment.callbackUrl;
  }

  logout() {
    
    sessionStorage.clear();
    localStorage.clear();
    this.document.location.href = environment.logoutUrl;
  }
}