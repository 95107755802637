import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IcloudDocuments } from './../interface/cloudDocs';
import { IDisplayOptions } from './../interface/displayOptions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../auth/auth.service';



@Component({
  selector: 'app-managedocuments',
  templateUrl: './managedocuments.component.html',
  styleUrls: ['./managedocuments.component.css']
})
export class ManagedocumentsComponent implements OnInit {
  editForm: FormGroup;
  json: string;
  submitted: boolean = false;
  action: string;
  local_data: any;
  isAdd = false;
  isEdit = false;
  isDelete = false;
  disableButton = false;
  documentDisplayedOnHomePageValue:string;
  displayOptions: IDisplayOptions[] = [
    { value: 'No', viewValue: 'No' },
    { value: 'DisplayOnHomePage', viewValue: 'Yes' }
  ];

  documentTypeOptions: IDisplayOptions[] = [
    { value: 'AWS', viewValue: 'AWS' },
    { value: 'Azure', viewValue: 'Azure' },
    { value: 'OCI', viewValue: 'OCI' },
    { value: 'Generic', viewValue: 'Generic' }
  ];
  constructor(private fb: FormBuilder, private auth: AuthService, public dialogRef: MatDialogRef<ManagedocumentsComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IcloudDocuments) {

    this.local_data = data;
    //console.log("data in dialog:::::::::"+ JSON.stringify(data));
    //console.table(this.local_data);
    //console.log("data in dialog:::::::::" + data['awsAccountInfo']['accountOwners']);
  }

  ngOnInit() {
    //console.log("data in dialog:::::::::" + this.local_data['accountName']);
    //console.table(this.local_data);
    this.action = this.local_data['userAction'];
    if (this.action == "Edit") {
      this.isEdit = true;
      this.isDelete = false;
      this.isAdd = false;
      this.documentDisplayedOnHomePageValue = this.local_data['cloudDocumentsInfo']['documentDisplayedOnHomePage'];
      this.editForm = this.fb.group({
        documentName: [this.local_data['cloudDocumentsInfo']['documentName'], [Validators.required, Validators.maxLength(50)]],
        documentDescription: [this.local_data['cloudDocumentsInfo']['documentDescription'], [Validators.required, Validators.maxLength(100)]],
        documentDisplayedOnHomePage: [this.local_data['cloudDocumentsInfo']['documentDisplayedOnHomePage'], [Validators.required]],
        documentLink: [this.local_data['cloudDocumentsInfo']['documentLink'], [Validators.required, Validators.maxLength(500)]],
        documentTags: [this.local_data['cloudDocumentsInfo']['documentTags'], [Validators.required, Validators.maxLength(50)]],
        documentID: [{ value:this.local_data['cloudDocumentsInfo']['documentID'], disabled: true }],
        documentStatus: [{ value:this.local_data['cloudDocumentsInfo']['documentStatus'], disabled: true }],
        documentType: [{ value:this.local_data['cloudDocumentsInfo']['documentType'], disabled: true }]
      });

    }
    else if (this.action == "Add") {
      this.isAdd = true;
      this.isDelete = false;
      this.isEdit = false;
      this.documentDisplayedOnHomePageValue = 'No';
      this.editForm = this.fb.group({
        documentName: ['', [Validators.required, Validators.maxLength(50)]],
        documentDescription: ['', [Validators.required, Validators.maxLength(100)]],
        documentDisplayedOnHomePage: ['', [Validators.required]],
        documentLink: ['', [Validators.required, Validators.maxLength(500)]],
        documentTags: ['', [Validators.required, Validators.maxLength(50)]],
        documentType: ['', [Validators.required]]
      });
    }
    else {
      this.isDelete = true;
      this.isAdd = false;
      this.isEdit = false;
      this.documentDisplayedOnHomePageValue = this.local_data['cloudDocumentsInfo']['documentDisplayedOnHomePage'];
      this.editForm = this.fb.group({
        documentName: [{ value: this.local_data['cloudDocumentsInfo']['documentName'], disabled: true }],
        documentDescription: [{ value: this.local_data['cloudDocumentsInfo']['documentDescription'], disabled: true }],
        documentDisplayedOnHomePage: [{ value: this.local_data['cloudDocumentsInfo']['documentDisplayedOnHomePage'], disabled: true }],
        documentLink: [{ value: this.local_data['cloudDocumentsInfo']['documentLink'], disabled: true }],
        documentTags: [{ value: this.local_data['cloudDocumentsInfo']['documentTags'], disabled: true }],
        documentID: [{ value:this.local_data['cloudDocumentsInfo']['documentID'], disabled: true }],
        documentStatus: [{ value:this.local_data['cloudDocumentsInfo']['documentStatus'], disabled: true }],
        documentType: [{ value:this.local_data['cloudDocumentsInfo']['documentType'], disabled: true }]
        
      });
    }
      this.editForm.get('documentDisplayedOnHomePage').setValue(this.documentDisplayedOnHomePageValue);
 
  }

  get f() {
    return this.editForm.controls;
  }

  get documentName() {
    if (this.isEdit==true) {
      return 'Manage Document: ' + this.editForm.get('documentName').value
    }
    else {
      return 'Upload new document to Cloud Governance Portal'

    }
  }

  doAction(userEvent: string) {
    //console.log("userEvent::", userEvent)
    this.disableButton = true;
    if (userEvent == 'Close') {
      this.dialogRef.close({ event: 'Cancel' });
    }
    else if (userEvent == 'Save') {
      this.submitted = true;
      if (this.editForm.invalid) {
        return;
      }
      //console.table(this.editForm.getRawValue())
      this.dialogRef.close({ event: 'Save', data: this.editForm.getRawValue() });
    }
    else if (userEvent == 'Upload') {
      this.submitted = true;
      if (this.editForm.invalid) {
        return;
      }
      //console.table(this.editForm.getRawValue())
      this.dialogRef.close({ event: 'Upload', data: this.editForm.getRawValue() });
    }
    else {
      this.submitted = true;
      if (this.editForm.invalid) {
        return;
      }
      //console.table(this.editForm.getRawValue())
      this.dialogRef.close({ event: 'Delete', data: this.editForm.getRawValue() });
    }
  }


}
