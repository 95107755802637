import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAWSAccounts } from './../interface/awsaccounts';
import { RepositoryService } from './../service/repository.service';
import { AwseditComponent } from './../awsedit/awsedit.component';
import { OcieditComponent } from './../ociedit/ociedit.component';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AwsaccessrequestComponent } from '../awsaccessrequest/awsaccessrequest.component';
import { SendemailsComponent } from './../sendemails/sendemails.component';
import { Ng2CsvService } from 'ng2csv';
import { IAzureSubscriptions } from '../interface/azureSubscriptions';
import { IOCICompartments } from '../interface/ociCompartments';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RequestazureaccessComponent } from '../requestazureaccess/requestazureaccess.component';
import { AzureeditComponent } from '../azureedit/azureedit.component';
import { AzurepimComponent } from '../azurepim/azurepim.component';
import { AzuremgaccessComponent } from '../azuremgaccess/azuremgaccess.component';
import { AwsmgaccessComponent } from '../awsmgaccess/awsmgaccess.component';


@Component({
  selector: 'app-awslist',
  templateUrl: './awslist.component.html',
  styleUrls: ['./awslist.component.css'],
  providers: [Ng2CsvService]
})
export class AwslistComponent implements OnInit {
  awsAccoutsTable: IAWSAccounts[] = [];
  azureSubscriptionsTable: IAzureSubscriptions[] = [];
  ociCompartmentTable: IOCICompartments[] = [];

  awsAccoutsTableDataSource = new MatTableDataSource<IAWSAccounts>(this.awsAccoutsTable);
  azureSubscriptionsTableDataSource = new MatTableDataSource<IAzureSubscriptions>(this.azureSubscriptionsTable);
  ociCompartmentTableDataSource = new MatTableDataSource<IOCICompartments>(this.ociCompartmentTable);

  selection = new SelectionModel<IAWSAccounts>(true, []);
  //customerListlength: string ;
  filterVal = '';
  loading = true;
  isAdmin = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;

  @ViewChild(MatTable) table: MatTable<any>;
  //displayedColumns: string[] = ['id', 'accountName', 'accountEmail', 'accountOwners', 'isActive', 'azureADRef', 'actions'];
  displayedColumns: string[] = ['accountName', 'accountOwners', 'actions'];
  displayedColumnsAzure: string[] = ['subscriptionName', 'accountOwners', 'actions'];

  pageSize = 5;


  @ViewChild('PaginatorAWS', { static: true }) paginatorAWS: MatPaginator;
  @ViewChild('PaginatorAzure', { static: true }) paginatorAzure: MatPaginator;
  @ViewChild('PaginatorOCI', { static: true }) paginatorOCI: MatPaginator;


  @ViewChild('SortAWS', { static: true }) sortAWS: MatSort;
  @ViewChild('SortAzure', { static: true }) sortAzure: MatSort;
  @ViewChild('SortOCI', { static: true }) sortOCI: MatSort;

  constructor(private ng2Csv: Ng2CsvService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, @Inject(DOCUMENT) private document: any, private http: HttpClient, private rs: RepositoryService, private auth: AuthService, private router: Router, private dialog: MatDialog) {
    this.matIconRegistry.addSvgIcon(
      "edit-admin",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/edit-admin.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "aws-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Amazon_Web_Services_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "azure-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Azure_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "oci-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/OCI_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "pim-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/APIM.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "mg-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/AMG.svg")
    );

  }

  ngOnInit(): void {
    /* Only filter certail columns*/
    this.awsAccoutsTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.accountName.toLowerCase().includes(filter) || data.awsAccountName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter);

    };
    this.ociCompartmentTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter);

    };

    this.azureSubscriptionsTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.subscriptionName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter) || data.department.toLowerCase()===(filter);

    };
    /* Only filter certail columns*/

    this.getAllAWSAccounts();


    //console.log("userrole",userRole);


    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  ngAfterViewInit() {
    this.awsAccoutsTableDataSource.paginator = this.paginatorAWS,
    this.awsAccoutsTableDataSource.sort = this.sortAWS;

 

    this.ociCompartmentTableDataSource.paginator = this.paginatorOCI;
    this.ociCompartmentTableDataSource.sort = this.sortOCI;

    this.azureSubscriptionsTableDataSource.paginator = this.paginatorAzure;
    this.azureSubscriptionsTableDataSource.sort = this.sortAzure;

  }
  _setDataSource(indexNumber) {
    //console.log("indexNumber:", indexNumber)
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.showErrMsg = false;
          this.showMsg = false;
          //this.loading = true;
          this.awsAccoutsTableDataSource.paginator = this.paginatorAWS;
          this.awsAccoutsTableDataSource.sort = this.sortAWS;
          break;
        case 1:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this.getAllOCIAccounts();
          !this.ociCompartmentTableDataSource.paginator ? this.ociCompartmentTableDataSource.paginator = this.paginatorOCI : null;
          this.ociCompartmentTableDataSource.sort = this.sortOCI;
          
          break;
        case 2:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this. getAllAzureAccounts();
          !this.azureSubscriptionsTableDataSource.paginator ? this.azureSubscriptionsTableDataSource.paginator = this.paginatorAzure : null;
          //this.azureSubscriptionsTableDataSource.paginator = this.paginatorAzure;
          this.azureSubscriptionsTableDataSource.sort = this.sortAzure;
      }
    });
  }
  get loggedinUser() {
    return sessionStorage.getItem('wwid');
  }
  //https://github.com/rars/ng2csv
  public downloadCSV(cloudType: string): void {
    if (cloudType.includes("AWS")) {
      this.ng2Csv.download(this.awsAccoutsTable, 'aws-accounts-master.csv');
    }
    else if (cloudType.includes("Azure")) {
      this.ng2Csv.download(this.azureSubscriptionsTable, 'azure-subscriptions-master.csv');
    }
    else {
      this.ng2Csv.download(this.ociCompartmentTable, 'oci-compartments-master.csv');
    }

  }
  getAllAWSAccounts() {
    try {
      this.rs.getAWSAccounts("All", "List").subscribe(res => {
        // console.log("res",res);
        this.awsAccoutsTable = res['Items'];
        this.awsAccoutsTableDataSource.data = this.awsAccoutsTable;

        //this.azureSubscriptionsTable = res['Items'];
        //this.azureSubscriptionsTableDataSource.data = this.azureSubscriptionsTable;

        //this.ociCompartmentTable = res['Items'];
        //this.ociCompartmentTableDataSource.data = this.ociCompartmentTable;


        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }


  getInActiveAWSAccounts() {
    try {
      this.rs.getInActiveAWSAccounts("0").subscribe(res => {
        // console.log("res",res);
        this.awsAccoutsTable = res['Items'];
        this.awsAccoutsTableDataSource.data = this.awsAccoutsTable;
        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  getAllOCIAccounts() {
    try {
      this.rs.getOCIAccounts("All", "List").subscribe(res => {
        // console.log("res",res);
        this.ociCompartmentTable = res['Items'];
        this.ociCompartmentTableDataSource.data = this.ociCompartmentTable;



        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  getAllAzureAccounts() {
    try {
      this.rs.getAzureAccounts("All", "List", "Subscription").subscribe(res => {
        // console.log("res",res);
        this.azureSubscriptionsTable = res['Items'];
        this.azureSubscriptionsTableDataSource.data = this.azureSubscriptionsTable;



        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  applyFilterAWS(filterValue: string) {
    this.awsAccoutsTableDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterAzure(filterValue: string) {
    this.azureSubscriptionsTableDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterOCI(filterValue: string) {
    this.ociCompartmentTableDataSource.filter = filterValue.trim().toLowerCase();

  }

  editAccount(awsAccount: object, action: string, cloudType: string) {
    const dialogConfig = new MatDialogConfig();
    let dialogRef;
    //console.table(awsAccount);
    //console.log("action:::"+action);


    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      AccountInfo: awsAccount,
      userAction: action,
      cloudType: cloudType
    };
    //console.log("dialogConfig:::"+JSON.stringify(dialogConfig));
    if(cloudType == "AWS"){
      dialogRef = this.dialog.open(AwseditComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        dialogData => {
  
          //console.table(dialogData)
          let userResponse = dialogData['event'];
          //console.log("userResponse:", userResponse)
          if (userResponse == 'Save') {
            //console.log("Dialog events:::",dialogData['data']['accountEmail'])
  
  
            try {
              let status = dialogData['data'];
              //console.log("status:", status)
  
              if (status == '200') {
                this.getAllAWSAccounts();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully updated AWS Account details"
  
  
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied updating AWS Account details. Please try again"
              }
  
            }
            catch{
              this.showErrMsg = true;
              this.errorMessage = "Falied updating AWS Account details. Please try again"
            }
  
          }
          else {
            this.showErrMsg = false;
            this.showMsg = false;
          }
        }
      );
    }
    else if (cloudType == "OCI"){
      dialogRef = this.dialog.open(OcieditComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        dialogData => {
  
          //console.table(dialogData)
          let userResponse = dialogData['event'];
          //console.log("userResponse:", userResponse)
          if (userResponse == 'Save') {
            //console.log("Dialog events:::",dialogData['data']['accountEmail'])
  
  
            try {
              let status = dialogData['data'];
              //console.log("status:", status)
  
              if (status == '200') {
                this.getAllOCIAccounts();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully updated OCI Compartment details"
  
  
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied updating OCI Compartment details. Please try again"
              }
  
            }
            catch{
              this.showErrMsg = true;
              this.errorMessage = "Falied updating OCI Compartment details. Please try again"
            }
  
          }
          else {
            this.showErrMsg = false;
            this.showMsg = false;
          }
        }
      );
    }

    else{
      
      dialogRef = this.dialog.open(AzureeditComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        dialogData => {
  
          //console.table(dialogData)
          let userResponse = dialogData['event'];
          //console.log("userResponse:", userResponse)
          if (userResponse == 'Save') {
            //console.log("Dialog events:::",dialogData['data']['accountEmail'])
  
  
            try {
              let status = dialogData['data'];
              //console.log("status:", status)
  
              if (status == '200') {
                this.getAllAzureAccounts();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully updated Azure Subscription"
  
  
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied updating Azure Subscription. Please try again"
              }
  
            }
            catch{
              this.showErrMsg = true;
              this.errorMessage = "Falied updating Azure Subscription. Please try again"
            }
  
          }
          else {
            this.showErrMsg = false;
            this.showMsg = false;
          }
        }
      );
    
    }



    

  }
  contactOwners(awsRequest: object, messageType: string, cloudType: string) {
    const dialogConfig = new MatDialogConfig();
    //console.table(awsRequest);
    //console.log("messageType:::" + messageType);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      awsAccountInfo: awsRequest,
      awsMessageType: messageType,
      cloudType:cloudType
    };
    const dialogRef = this.dialog.open(SendemailsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", dialogData)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Success') {

          this.showErrMsg = false;
          this.showMsg = true;
          this.successMessage = "Sucessfully Contacted Cloud Owner(s)"

        }
        else if (userResponse == 'Fail') {
          this.showErrMsg = true;
          this.showMsg = false;
          this.errorMessage = "Error while contacting owner(s). Please try again"

        }
        else {
          this.showErrMsg = false;
          this.showMsg = false;

        }
      });

  }
  // CHG0160198 & CHG0160199
  manageAWSRootMGAccess(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      
    };
    const dialogRef = this.dialog.open(AwsmgaccessComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", dialogData)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Success') {

          this.showErrMsg = false;
          this.showMsg = true;
          this.successMessage = "Sucessfully submitted the request. Please wait for approval"

        }
        else if (userResponse == 'Falied') {
          this.showErrMsg = true;
          this.showMsg = false;
          this.errorMessage = "Error while processing the request. Please try again"

        }
        else {
          this.showErrMsg = false;
          this.showMsg = false;

        }
      });
  }


  manageRootMGAccess(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      
    };
    const dialogRef = this.dialog.open(AzuremgaccessComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", dialogData)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Success') {

          this.showErrMsg = false;
          this.showMsg = true;
          this.successMessage = "Sucessfully submitted the request. Please wait for approval"

        }
        else if (userResponse == 'Falied') {
          this.showErrMsg = true;
          this.showMsg = false;
          this.errorMessage = "Error while processing the request. Please try again"

        }
        else {
          this.showErrMsg = false;
          this.showMsg = false;

        }
      });
  }
  managePIMLink(){
    window.open(environment.callbackUrlAccessToken, '_blank');
  }
  managePIM (){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      
    };
    const dialogRef = this.dialog.open(AzurepimComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", dialogData)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Success') {

          this.showErrMsg = false;
          this.showMsg = true;
          this.successMessage = "Sucessfully activated PIM Access. You will recieve confirmation email from Microsoft"

        }
        else if (userResponse == 'Falied') {
          this.showErrMsg = true;
          this.showMsg = false;
          this.errorMessage = "Error while activating for PIM Access. Please try again"

        }
        else {
          this.showErrMsg = false;
          this.showMsg = false;

        }
      });

  }
  requestAzureAccess(azureAccount: object, cloudType: string) {
    
    const dialogConfig = new MatDialogConfig();
    //console.table(awsAccount);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      azureAccountInfo: azureAccount,
      cloudType: cloudType
    };
    const dialogRef = this.dialog.open(RequestazureaccessComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", data)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Save') {
          this.showMsg = true;

          this.successMessage = "Sucessfully submitted the request. Please wait for approval"
        }
        else if (userResponse == 'Failed') {
          this.showErrMsg = true;
          this.errorMessage = "Error while processing the request. Please try again"

        }
        else {

          this.showErrMsg = false;
          this.showMsg = false;

        }
      }
    );

  
  }

  //Both for OCI and AWS
  requestAccess(awsAccount: object, cloudType: string) {
    const dialogConfig = new MatDialogConfig();
    //console.table(awsAccount);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      awsAccountInfo: awsAccount,
      cloudType: cloudType
    };
    const dialogRef = this.dialog.open(AwsaccessrequestComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", data)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Save') {
          this.showMsg = true;
          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
          this.successMessage = "Sucessfully submitted the request. Please wait for approval"
        }
        else if (userResponse == 'Failed') {
          this.showErrMsg = true;
          this.errorMessage = "Error while processing the request. Please try again"

          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
        }
        else {
          //console.log("userResponse:::",userResponse)
          this.showErrMsg = false;
          this.showMsg = false;

        }
      }
    );

  }

inactiveAWSAccounts(event:MatCheckboxChange): void {
    //console.log(event.checked);
    if (event.checked == true){
      this.loading = true;
      this.getInActiveAWSAccounts();
    }
    else{
      this.loading = true;
      this.getAllAWSAccounts();
    }
}

}
