import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from './../service/repository.service';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Ng2CsvService } from 'ng2csv';
import { IPMCServers } from '../interface/pmcservers';
import { IPMCTeamStats } from '../interface/pmcstats';
import { IPMCTeams } from '../interface/pmcteams';
import { PmcrequestaccessComponent } from '../pmcrequestaccess/pmcrequestaccess.component';

@Component({
  selector: 'app-pmcdata',
  templateUrl: './pmcdata.component.html',
  styleUrls: ['./pmcdata.component.css']
})
export class PmcdataComponent implements OnInit {

  pmcTeamsTable: IPMCTeams[] = [];
  pmcServersTable: IPMCServers[] = [];
  pmcStatsTable: IPMCTeamStats[] = [];

  pmcTeamsTableDataSource = new MatTableDataSource<IPMCTeams>(this.pmcTeamsTable);
  pmcServersTableDataSource = new MatTableDataSource<IPMCServers>(this.pmcServersTable);
  pmcStatsTableDataSource = new MatTableDataSource<IPMCTeamStats>(this.pmcStatsTable);

  selection = new SelectionModel<IPMCTeams>(true, []);
  //customerListlength: string ;
  filterVal = '';
  loading = true;
  isAdmin = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;

  @ViewChild(MatTable) table: MatTable<any>;
  //displayedColumns: string[] = ['id', 'accountName', 'accountEmail', 'accountOwners', 'isActive', 'azureADRef', 'actions'];
  displayedColumnsTeams: string[] = ['teamName', 'actions'];
  displayedColumnsServers: string[] = ['resourcename', 'targetname', 'appid'];
  displayedColumnsStats: string[] = ['teamname', 'appBU', 'techownername','mothlyactualsavings','monthlypotentialsavings','totalservers','scheduledservers'];

  pageSize = 5;


  @ViewChild('PaginatorTeams', { static: true }) paginatorTeams: MatPaginator;
  @ViewChild('PaginatorServers', { static: true }) paginatorServers: MatPaginator;
  @ViewChild('PaginatorStats', { static: true }) paginatorStats: MatPaginator;


  @ViewChild('SortTeams', { static: true }) sortTeams: MatSort;
  @ViewChild('SortServers', { static: true }) sortServers: MatSort;
  @ViewChild('SortStats', { static: true }) sortStats: MatSort;

  constructor(private ng2Csv: Ng2CsvService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, @Inject(DOCUMENT) private document: any, private http: HttpClient, private rs: RepositoryService, private auth: AuthService, private router: Router, private dialog: MatDialog) {
    this.matIconRegistry.addSvgIcon(
      "pmc-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/pmc.svg")
    );
    
  }

  ngOnInit(): void {
    /* Only filter certail columns
    this.pmcTeamsTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.accountName.toLowerCase().includes(filter) || data.awsAccountName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter);

    };
    this.ociCompartmentTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter);

    };

    this.azureSubscriptionsTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.subscriptionName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter) || data.department.toLowerCase()===(filter);

    };
    /* Only filter certail columns*/

    this.getAllPMCTeams();


    //console.log("userrole",userRole);


    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  ngAfterViewInit() {
    this.pmcTeamsTableDataSource.paginator = this.paginatorTeams,
    this.pmcTeamsTableDataSource.sort = this.sortTeams;

    this.pmcServersTableDataSource.paginator = this.paginatorServers;
    this.pmcServersTableDataSource.sort = this.sortServers;

    this.pmcStatsTableDataSource.paginator = this.paginatorStats;
    this.pmcStatsTableDataSource.sort = this.sortStats;

   

  }
  _setDataSource(indexNumber) {
    //console.log("indexNumber:", indexNumber)
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.showErrMsg = false;
          this.showMsg = false;
          //this.loading = true;
          this.pmcTeamsTableDataSource.paginator = this.paginatorTeams;
          this.pmcTeamsTableDataSource.sort = this.sortTeams;
          break;
        case 1:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this.getAllPMCServers();
          !this.pmcServersTableDataSource.paginator ? this.pmcServersTableDataSource.paginator = this.paginatorServers : null;
          this.pmcServersTableDataSource.sort = this.sortServers;
          
          break;
        case 2:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this. getAllPMCTeamStats();
          !this.pmcStatsTableDataSource.paginator ? this.pmcStatsTableDataSource.paginator = this.paginatorStats : null;
          //this.azureSubscriptionsTableDataSource.paginator = this.paginatorAzure;
          this.pmcStatsTableDataSource.sort = this.sortStats;
      }
    });
  }
  get loggedinUser() {
    return sessionStorage.getItem('wwid');
  }
  //https://github.com/rars/ng2csv
  public downloadCSV(cloudType: string): void {
    if (cloudType.includes("Teams")) {
      this.ng2Csv.download(this.pmcTeamsTable, 'pmc-teams.csv');
    }
    else if (cloudType.includes("Stats")) {
      this.ng2Csv.download(this.pmcStatsTable, 'pmc-team-stats.csv');
    }
    else {
      this.ng2Csv.download(this.pmcServersTable, 'pmc-servers-without-teams.csv');
    }

  }
  getAllPMCTeams() {
    try {
      this.rs.getPMCTableData("PMC-Teams").subscribe(res => {
        // console.log("res",res);
        this.pmcTeamsTable = res['Items'];
        this.pmcTeamsTableDataSource.data = this.pmcTeamsTable;
        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  getAllPMCServers() {
    try {
      this.rs.getPMCTableData("PMC-UnAssignedServers").subscribe(res => {
        // console.log("res",res);
        this.pmcServersTable = res['Items'];
        this.pmcServersTableDataSource.data = this.pmcServersTable;
        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  getAllPMCTeamStats() {
    try {
      this.rs.getPMCTableData("PMC-Teams-Stats").subscribe(res => {
        // console.log("res",res);
        this.pmcStatsTable = res['Items'];
        this.pmcStatsTableDataSource.data = this.pmcStatsTable;
        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }
  goToLink(url: string){
    window.open(url, "_blank");
  } 
  applyFilterTeams(filterValue: string) {
    this.pmcTeamsTableDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterStats(filterValue: string) {
    this.pmcStatsTableDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterServers(filterValue: string) {
    this.pmcServersTableDataSource.filter = filterValue.trim().toLowerCase();

  }

  
  requestAccess(pmcData: object) {
    const dialogConfig = new MatDialogConfig();
    //console.table(pmcData);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      pmcDataInfo: pmcData,
      cloudType: "PMC"
    };
    const dialogRef = this.dialog.open(PmcrequestaccessComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", data)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Save') {
          this.showMsg = true;
          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
          this.successMessage = "Sucessfully submitted the request. Please wait for approval"
        }
        else if (userResponse == 'Failed') {
          this.showErrMsg = true;
          this.errorMessage = "Error while processing the request. Please try again"

          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
        }
        else {
          //console.log("userResponse:::",userResponse)
          this.showErrMsg = false;
          this.showMsg = false;

        }
      }
    );

  }



}
