import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IcloudDocuments } from './../interface/cloudDocs';
import { RepositoryService } from './../service/repository.service';
import { ManagedocumentsComponent } from './../managedocuments/managedocuments.component';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Ng2CsvService } from 'ng2csv';


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css'],
  providers: [Ng2CsvService]
})
export class DocumentsComponent implements OnInit {
  cloudDocuments: IcloudDocuments[] = [];
  cloudDocumentsTableDataSource = new MatTableDataSource<IcloudDocuments>(this.cloudDocuments);
  selection = new SelectionModel<IcloudDocuments>(true, []);
  filterVal = '';
  loading = true;
  isAdmin = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;
  displayedColumns: string[];

  @ViewChild(MatTable) table: MatTable<any>;
  //displayedColumns: string[] = ['id', 'accountName', 'accountEmail', 'accountOwners', 'isActive', 'azureADRef', 'actions'];

  pageSize = 5;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private ng2Csv: Ng2CsvService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, @Inject(DOCUMENT) private document: any, private http: HttpClient, private rs: RepositoryService, private auth: AuthService, private router: Router, private dialog: MatDialog) {
    this.matIconRegistry.addSvgIcon(
      "edit-admin",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/edit-admin.svg")
    );

  }

  ngOnInit(): void {
    /* Only filter certail columns
        this.awsAccoutsTableDataSource.filterPredicate = function (data, filter: string): boolean {
          return data.accountEmail.toLowerCase().includes(filter) || data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
        };
    */
    var userRole = sessionStorage.getItem('Role');
    if (userRole.includes("Admin")) {
      this.isAdmin = true;
      //console.log("this.isAdmin", this.isAdmin);
    }
    this.getAllDocuments();
    if (this.isAdmin == true) {
      this.displayedColumns = ['documentName', 'documentDescription', 'actions'];
    }
    else {
      this.displayedColumns = ['documentName', 'documentDescription'];
    }

    //console.log("userrole",userRole);


    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  ngAfterViewInit() {
    this.cloudDocumentsTableDataSource.paginator = this.paginator,
      this.cloudDocumentsTableDataSource.sort = this.sort

  }
  get loggedinUser() {
    return sessionStorage.getItem('wwid');
  }
  //https://github.com/rars/ng2csv
  public downloadCSV(): void {
    this.ng2Csv.download(this.cloudDocuments, 'cloud-documents-master.csv');
  }
  getAllDocuments() {
    try {
     
      this.rs.manageCloudDocuments('Get','','','','','','','','','').subscribe(res => {
        // console.log("res",res);
        this.cloudDocuments = res['Items'];
        this.cloudDocumentsTableDataSource.data = this.cloudDocuments;
        this.loading = false;


      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }


  applyFilter(filterValue: string) {
    this.cloudDocumentsTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  goToLink(documentLink: string) {
    //console.log(documentLink)
    window.open(documentLink, "_blank");
  }

  manageDocuments(cloudDocuments: object, action: string) {
    const dialogConfig = new MatDialogConfig();
    //console.table(awsAccount);
    //console.log("action:::"+action);


    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      cloudDocumentsInfo: cloudDocuments,
      userAction: action
    };
    //console.log("dialogConfig:::"+JSON.stringify(dialogConfig));
    const dialogRef = this.dialog.open(ManagedocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.table(dialogData)
        let userResponse = dialogData['event'];
       
        if (userResponse == 'Save') {
          //console.log("userResponse:", userResponse)
          let documentID = dialogData['data']['documentID']
          let documentName = dialogData['data']['documentName'];
          let documentType = dialogData['data']['documentType'];
          let documentManagedBy = this.loggedinUser;
          let documentLink = dialogData['data']['documentLink'];
          let documentDisplayedOnHomePage = dialogData['data']['documentDisplayedOnHomePage'];
          let documentDescription = dialogData['data']['documentDescription'];
          let documentTags = dialogData['data']['documentTags'];
          let documentStatus = '1';
          try {
     
            this.rs.manageCloudDocuments('Edit',documentID,documentName,documentType,documentManagedBy,documentLink,documentDisplayedOnHomePage,documentDescription,documentTags,documentStatus).subscribe(res => {
              //console.log("res",res);
              let status = res['ResponseMetadata']['HTTPStatusCode'];
              if (status == '200') {
                this.getAllDocuments();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully updated the document"
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied updating the document. Please try again"
              }
            })
          }
          catch (error) {
            console.log("error message:" + error['message']);
            this.showErrMsg = true;
            this.errorMessage = "Falied updating the document. Please try again"
          }
        }
        else if (userResponse == 'Delete') {
          //console.log("userResponse:", userResponse)
          let documentID = dialogData['data']['documentID']
          let documentName = dialogData['data']['documentName'];
          let documentType = dialogData['data']['documentType'];
          let documentManagedBy = this.loggedinUser;
          let documentLink = dialogData['data']['documentLink'];
          let documentDisplayedOnHomePage = dialogData['data']['documentDisplayedOnHomePage'];
          let documentDescription = dialogData['data']['documentDescription'];
          let documentTags = dialogData['data']['documentTags'];
          let documentStatus = '0';
          try {
     
            this.rs.manageCloudDocuments('Edit',documentID,documentName,documentType,documentManagedBy,documentLink,documentDisplayedOnHomePage,documentDescription,documentTags,documentStatus).subscribe(res => {
              //console.log("res",res);
              let status = res['ResponseMetadata']['HTTPStatusCode'];
              if (status == '200') {
                this.getAllDocuments();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully deleted the document"
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied deleted the document. Please try again"
              }
            })
          }
          catch (error) {
            console.log("error message:" + error['message']);
            this.showErrMsg = true;
            this.errorMessage = "Falied updating the document. Please try again"
          }
        }

        else if (userResponse == 'Upload') {
          //console.log("userResponse:", userResponse)
          
          let documentName = dialogData['data']['documentName'];
          let documentType = dialogData['data']['documentType'];
          let documentManagedBy = this.loggedinUser;
          let documentLink = dialogData['data']['documentLink'];
          let documentDisplayedOnHomePage = dialogData['data']['documentDisplayedOnHomePage'];
          let documentDescription = dialogData['data']['documentDescription'];
          let documentTags = dialogData['data']['documentTags'];
          let documentStatus = '1';
          try {
     
            this.rs.manageCloudDocuments('Upload','',documentName,documentType,documentManagedBy,documentLink,documentDisplayedOnHomePage,documentDescription,documentTags,documentStatus).subscribe(res => {
              //console.log("res",res);
              let status = res['ResponseMetadata']['HTTPStatusCode'];
              if (status == '200') {
                this.getAllDocuments();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully added the document"
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied adding the document. Please try again"
              }
            })
          }
          catch (error) {
            console.log("error message:" + error['message']);
            this.showErrMsg = true;
            this.errorMessage = "Falied adding the document. Please try again"
          }
        }
        else {
          //userResponse == 'Close'
          //console.log("userResponse:", userResponse)
          this.showErrMsg = false;
          this.showMsg = false;
        }
      }
    );

  }
 
}
