import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { IAWSAccounts } from '../interface/awsaccounts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-azurepim',
  templateUrl: './azurepim.component.html',
  styleUrls: ['./azurepim.component.css']
})
export class AzurepimComponent implements OnInit {
  resourceTypes: string[] = ['Azure AD Roles', 'Azure Resources'];
  pimRoles: any;
  loading = true;
  requestAccessForm: FormGroup;
  selectAccessForm: FormGroup;
  card_header: string = "";
  resourceType : string;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  submitted: boolean = false;
  disableButton = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;
  //public dialogRef: MatDialogRef<AzurepimComponent>,
  constructor(  @Inject(DOCUMENT) private document: any,private rs: RepositoryService, private fb: FormBuilder) {
    
   }

  ngOnInit(): void {
    this.showErrMsg = false;
    this.showMsg = false;
    this.loading = true;
    this.getUserEligiblePIMRoles("aadRoles");
    this.card_header = "Select the eligible PIM Azure Resources Role to activate"

    this.selectAccessForm = this.fb.group({
      selectedResourceType: ['Azure AD Roles', [Validators.required]]

    });


    this.requestAccessForm = this.fb.group({

      pim_role: ['', [Validators.required]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      resourceType: ['aadRoles', [Validators.required]]

    });
    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }
  onSelectionChange(event: MatRadioChange) {

    if (event.value == "Azure AD Roles") {
      this.showErrMsg = false;
      this.showMsg = false;
      this.loading = true;
      this.resourceType = "aadRoles"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.pim_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.pimRoles = [];
      this.getUserEligiblePIMRoles(this.resourceType);
      this.card_header = "Select the eligible PIM Azure AD Role to activate"
    }
   
    
    else {
      this.loading = true;
      this.showErrMsg = false;
      this.showMsg = false;
      //console.log(event.value)
      this.resourceType = "azureResources"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.pim_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.pimRoles = [];
      this.getUserEligiblePIMRoles(this.resourceType);
      
      this.card_header = "Select the eligible PIM Azure Resources Role to activate"
     
    }

  }
  get f() {
    return this.requestAccessForm.controls;
  }
  get comments() {
    return this.requestAccessForm.get('comments');
  }


  get card_title() {
    return this.card_header;
  }
  getUserEligiblePIMRoles(resourceType: string) {
    //console.log('resourceType: ' + resourceType);
    try {
      this.rs.getUserPIMRoles(resourceType).subscribe(res => {
        this.pimRoles = res['value'];
        this.loading = false;
        //console.log('pimRoles: ' + this.pimRoles);
        if(this.pimRoles === undefined){
          console.log("No Eligible Roles" )
        }
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  onFormSubmit(azurePIMRequest: object){
    
    //console.log(azurePIMRequest);
    let resourceId = azurePIMRequest['pim_role']['resourceId'];
    let roleDefinitionId = azurePIMRequest['pim_role']['roleDefinitionId'];
    let actionType = "UserAdd";
    let reason = azurePIMRequest['comments']
    let resourceType = azurePIMRequest['resourceType']
    //console.log('resourceId: ' + resourceId);
    //console.log('roleDefinitionId: ' + roleDefinitionId);
    //console.log('actionType: ' + actionType);
    //console.log('reason: ' + reason);
    //console.log('reason: ' + resourceType);
    this.rs.activateUserPIMRole(resourceId, roleDefinitionId, actionType, reason, resourceType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      //console.log(JSON.stringify(res).indexOf("Error"))
      if (JSON.stringify(res).indexOf("Error") == -1) {
        console.log('Success');
        this.showErrMsg = false;
        this.showMsg = true;
        this.successMessage = "Sucessfully activated PIM Access. You will recieve confirmation email from Microsoft"
        this.disableButton = false;
        this.resetEverything();

      }
      else {
        this.showErrMsg = true;
        this.showMsg = false;
        this.errorMessage = "Error while activating for PIM Access. Please try again"
        this.disableButton = false;
        this.resetEverything();

        //this.dialogRef.close({ event: 'Falied'});
      }

    })
    

  }
  resetEverything() {
    this.requestAccessForm.reset();
    this.formGroupDirective.resetForm();
  }
  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;
    this.disableButton = true;
    //console.log(this.requestAccessForm.invalid)
    if (this.requestAccessForm.invalid) {
      return;
    }
    //console.table(this.requestAccessForm.getRawValue())
    this.onFormSubmit(this.requestAccessForm.getRawValue())
    //this.dialogRef.close({event:'Save', data: this.requestAccessForm.getRawValue() });

  }

  closeDialog() {
    //console.table("Clicked Cancel")
    //this.dialogRef.close({ event: 'Cancel' });
  }
}
