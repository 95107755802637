import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAWSAccessRequests } from './../interface/awsaccessrequests';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { RepositoryService } from './../service/repository.service';

@Component({
  selector: 'app-sendemails',
  templateUrl: './sendemails.component.html',
  styleUrls: ['./sendemails.component.css']
})
export class SendemailsComponent implements OnInit {
  contactForm: FormGroup;
  submitted: boolean = false;
  local_data: any;
  cloud_role : string;
  awsaccount = false;
  awsrole = false;
  successMessage: string;
  errorMessage: string;
  messageType: string;
  cloudType: string;
  disableButton = false;
  accountName:string;
  constructor(private fb: FormBuilder, private auth: AuthService, private rs: RepositoryService,public dialogRef: MatDialogRef<SendemailsComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccessRequests) {
    this.local_data = data;
  }

  ngOnInit() {
    this.messageType = this.local_data['awsMessageType'];
    this.cloudType = this.local_data['cloudType'];
    if(this.cloudType == "Azure"){
      this.accountName = this.local_data['awsAccountInfo']['subscriptionName']
    }
    else{
      this.accountName = this.local_data['awsAccountInfo']['accountName']
    }
    
    //console.table(this.local_data);
    //console.table(this.messageType);
    if(this.messageType == "followup") {
    this.awsaccount = true;
    this.awsrole = true;
    this.cloud_role = this.local_data['awsAccountInfo']['groupName'].split('_').pop().trim();
    this.cloud_role =  this.cloud_role.charAt(0).toUpperCase() + this.cloud_role.slice(1)

    this.contactForm = this.fb.group({
      aws_account: [{ value:  this.accountName, disabled: true }],
      aws_role: [{ value: this.cloud_role, disabled: true }],
      subject: ['', [Validators.required, Validators.maxLength(50)]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      approverID: [{ value: this.local_data['awsAccountInfo']['approverID'], disabled: true }],
      requestID: [{ value: this.local_data['awsAccountInfo']['requestID'], disabled: true }],
      additionalApprovers: [{ value: this.local_data['awsAccountInfo']['additionalApprovers'], disabled: true }],
      requestorID: [{ value: sessionStorage.getItem('wwid'), disabled: true }],
      requestorName: [{ value: sessionStorage.getItem('Username'), disabled: true }],
      messageType: [{ value: this.messageType, disabled: true }]
    });
  }
  else if (this.messageType == "enquire"){
    this.awsaccount = true;
    this.awsrole = false;
    this.contactForm = this.fb.group({
      aws_account: [{ value:  this.accountName, disabled: true }],
      aws_role: [{ value:  this.accountName, disabled: true }],
      subject: ['', [Validators.required, Validators.maxLength(50)]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      approverID: [{ value: this.local_data['awsAccountInfo']['accountOwners'], disabled: true }],
      requestID: [{ value: this.local_data['awsAccountInfo']['requestID'], disabled: true }],
      additionalApprovers: [{ value: this.local_data['awsAccountInfo']['additionalApprovers'], disabled: true }],
      requestorID: [{ value: sessionStorage.getItem('wwid'), disabled: true }],
      requestorName: [{ value: sessionStorage.getItem('Username'), disabled: true }],
      messageType: [{ value: this.messageType, disabled: true }]

    });
  }
  else{
    this.awsaccount = false;
    this.awsrole = false;
    this.contactForm = this.fb.group({
      //aws_account: [{ value: this.local_data['awsAccountInfo']['accountName'], disabled: true }],
      //aws_role: [{ value: this.local_data['awsAccountInfo']['groupName'], disabled: true }],
      subject: ['', [Validators.required, Validators.maxLength(50)]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      approverID: [{ value: this.local_data['awsAccountInfo']['approverID'], disabled: true }],
      requestID: [{ value: this.local_data['awsAccountInfo']['requestID'], disabled: true }],
      //additionalApprovers: [{ value: this.local_data['awsAccountInfo']['additionalApprovers'], disabled: true }],
      requestorID: [{ value: sessionStorage.getItem('wwid'), disabled: true }],
      requestorName: [{ value: sessionStorage.getItem('Username'), disabled: true }],
      messageType: [{ value: this.messageType, disabled: true }]


    });
  }
  }
  get f() {
    return this.contactForm.controls;
  }
  get comments() {
    return this.contactForm.get('comments');
  }

  get subject() {
    return this.contactForm.get('subject');
  }
  
  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;  
    this.disableButton  = true; 
    if (this.contactForm.invalid) {
      return;
    }
    //console.table(this.contactForm.getRawValue())
    //toAddress : string, requestorID: string, requestorName: string, message: string, subject: string,  accountName: string, groupName: string, messageType: string
    let additionalApprovers = this.contactForm.getRawValue().additionalApprovers;
    let approverID = this.contactForm.getRawValue().approverID;
    let toAddress = '';
    if(additionalApprovers == undefined || additionalApprovers == 'None' ){
      toAddress = approverID;
    }
    else{
      toAddress = additionalApprovers +','+ approverID;
    } 
    let requestorID = this.contactForm.getRawValue().requestorID;
    let requestorName = this.contactForm.getRawValue().requestorName;
    let message = this.contactForm.getRawValue().comments;
    let subject = this.contactForm.getRawValue().subject;
    let accountName = this.contactForm.getRawValue().aws_account;
    let groupName = this.contactForm.getRawValue().aws_role;
    let messageType = this.contactForm.getRawValue().messageType;

    //console.log("toAddress:", toAddress);

    this.rs.sendEmails(toAddress, requestorID, requestorName, message, subject, accountName, groupName, messageType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      //console.log(JSON.stringify(res).indexOf("Error"))
      if(JSON.stringify(res).indexOf("Error") == -1){
        console.log('Success');
        this.dialogRef.close({event:'Success'});
      }
      else{
        this.dialogRef.close({event:'Fail'});
      }
    })
    //this.dialogRef.close({event:'Send', data: this.contactForm.getRawValue() });
  }

  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}
