import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAWSAccounts } from './../interface/awsaccounts';
import { RepositoryService } from './../service/repository.service';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AwsissuesComponent } from '../awsissues/awsissues.component';
import { formatDate } from '@angular/common';
import { IAzureSubscriptions } from '../interface/azureSubscriptions';
import { IOCICompartments } from '../interface/ociCompartments';
import { AzureissuesComponent } from '../azureissues/azureissues.component';


@Component({
  selector: 'app-awsaccountsnapshot',
  templateUrl: './awsaccountsnapshot.component.html',
  styleUrls: ['./awsaccountsnapshot.component.css']
})
export class AwsaccountsnapshotComponent implements OnInit {
  awsAccoutsTable: IAWSAccounts[] = [];
  azureSubscriptionsTable: IAzureSubscriptions[] = [];
  ociCompartmentTable: IOCICompartments[] = [];

  awsAccoutsTableDataSource = new MatTableDataSource<IAWSAccounts>(this.awsAccoutsTable);
  azureSubscriptionsTableDataSource = new MatTableDataSource<IAzureSubscriptions>(this.azureSubscriptionsTable);
  ociCompartmentTableDataSource = new MatTableDataSource<IOCICompartments>(this.ociCompartmentTable);

  selection = new SelectionModel<IAWSAccounts>(true, []);
  //customerListlength: string ;
  filterVal = '';
  loading = true;
  isAdmin = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;
  awsAccountLink: string;
  dataUpdatedDate: string;
  @ViewChild(MatTable) table: MatTable<any>;
  //displayedColumns: string[] = ['accountName', 'signinCount','awsadminUsers', 'lastMonthbill', 'currentMonthForecast', 'potentialSavings', 'actions'];

  displayedColumns: string[] = ['accountName', 'lastMonthbill', 'currentMonthForecast', 'potentialSavings', 'actions'];
  displayedColumnsAzure: string[] = ['subscriptionName', 'lastMonthbill', 'currentMonthForecast', 'potentialSavings', 'actions'];

  pageSize = 5;

  @ViewChild('PaginatorAWS', { static: true }) paginatorAWS: MatPaginator;
  @ViewChild('PaginatorAzure', { static: true }) paginatorAzure: MatPaginator;
  @ViewChild('PaginatorOCI', { static: true }) paginatorOCI: MatPaginator;


  @ViewChild('SortAWS', { static: true }) sortAWS: MatSort;
  @ViewChild('SortAzure', { static: true }) sortAzure: MatSort;
  @ViewChild('SortOCI', { static: true }) sortOCI: MatSort;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, @Inject(DOCUMENT) private document: any, private http: HttpClient, private rs: RepositoryService, private auth: AuthService, private router: Router, private dialog: MatDialog) {
    this.matIconRegistry.addSvgIcon(
      "aws-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Amazon_Web_Services_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "azure-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Azure_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "oci-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/OCI_Logo.svg")
    );
  }

  ngOnInit(): void {
    /* Only filter certail columns*/
    this.awsAccoutsTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.accountName.toLowerCase().includes(filter) || data.awsAccountName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter);

    };
    /*
    this.ociCompartmentTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter);

    };
*/
    this.azureSubscriptionsTableDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.subscriptionName.toLowerCase().includes(filter) || data.accountOwners.toLowerCase().includes(filter) || data.accountOwnerNames.toLowerCase().includes(filter);

    };
    /* Only filter certail columns*/

    this.dataUpdatedDate = this.getSunday(new Date());
    //console.log("dataUpdatedDate:::",dataUpdatedDate)
    var userRole = sessionStorage.getItem('Role');
    //console.log("userrole",userRole);
    if (userRole != null) {
      if (userRole.includes("Admin")) {
        this.getAllAWSAccounts("All", "Snapshot");
        this.isAdmin = true;
        //console.log("this.isAdmin",this.isAdmin);
      }
      else {
        this.getAllAWSAccounts(sessionStorage.getItem('wwid'), "Snapshot");
        this.isAdmin = false;
      }
    }
    else {
      sessionStorage.clear();
      this.router.navigate(['']);
    }

    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  ngAfterViewInit() {
    this.awsAccoutsTableDataSource.paginator = this.paginatorAWS,
      this.awsAccoutsTableDataSource.sort = this.sortAWS;

    this.azureSubscriptionsTableDataSource.paginator = this.paginatorAzure;
    this.azureSubscriptionsTableDataSource.sort = this.sortAzure;

    this.ociCompartmentTableDataSource.paginator = this.paginatorOCI;
    this.ociCompartmentTableDataSource.sort = this.sortOCI;

  }
  getAllAWSAccounts(userType: string, queryType: string) {

    this.rs.getAWSAccounts(userType, queryType).subscribe(res => {
      // console.log("res",res);
      this.awsAccoutsTable = res['Items'];
      this.awsAccoutsTableDataSource.data = this.awsAccoutsTable;
      this.loading = false;
    })
  }
  _setDataSource(indexNumber) {
    //console.log("indexNumber:", indexNumber)
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.showErrMsg = false;
          this.showMsg = false;
          //this.loading = true;
          this.awsAccoutsTableDataSource.paginator = this.paginatorAWS;
          this.awsAccoutsTableDataSource.sort = this.sortAWS;
          break;
        case 1:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this.getAllAzureAccounts();
          this.azureSubscriptionsTableDataSource.paginator = this.paginatorAzure;
          this.azureSubscriptionsTableDataSource.sort = this.sortAzure;

          break;
        /*
        case 2:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this.getAllOCIAccounts();
          !this.ociCompartmentTableDataSource.paginator ? this.ociCompartmentTableDataSource.paginator = this.paginatorOCI : null;
          this.ociCompartmentTableDataSource.sort = this.sortOCI;
          
          */
      }
    });
  }
  goToLink(accountName: string, accounAPPID: string) {
    //console.log(accountName)
    //console.log(accounAPPID)
    if (accountName.startsWith('AWS CN')) {
      this.awsAccountLink = environment.azureADLoginLink + accountName + '/' + accounAPPID + environment.awsCNBillingRelayState
    }
    else {
      this.awsAccountLink = environment.azureADLoginLink + accountName + '/' + accounAPPID + environment.awsBillingRelayState
    }
    //console.log(this.awsAccountLink)
    window.open(this.awsAccountLink, "_blank");
  }
  get loggedinUser() {
    return sessionStorage.getItem('wwid');
  }





  applyFilterAWS(filterValue: string) {
    this.awsAccoutsTableDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterAzure(filterValue: string) {
    this.azureSubscriptionsTableDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterOCI(filterValue: string) {
    this.ociCompartmentTableDataSource.filter = filterValue.trim().toLowerCase();

  }
  getAllAzureAccounts() {
    try {
      var userRole = sessionStorage.getItem('Role');
      var userType = sessionStorage.getItem('wwid');
      //console.log("userrole",userRole);
      if (userRole != null) {
        if (userRole.includes("Admin")) {
          userType = "All";
        }
      }
      this.rs.getAzureAccounts(userType, "Snapshot", "Subscription").subscribe(res => {
        // console.log("res",res);
        this.azureSubscriptionsTable = res['Items'];
        this.azureSubscriptionsTableDataSource.data = this.azureSubscriptionsTable;



        this.loading = false;
        var userRole = sessionStorage.getItem('Role');
        if (userRole.includes("Admin")) {
          this.isAdmin = true;
          //console.log("this.isAdmin",this.isAdmin);
        }

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }
  getSunday(d: | Date) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 0); // adjust when day is sunday
    //formatDate(new Date(), 'yyyy/MM/dd', 'en')

    return formatDate(new Date(d.setDate(diff)), 'MMM dd yyyy', 'en');
  }


  getAccountIssues(awsAccount: object, type: string) {
    //console.table(awsAccount);
    //console.log(type);

    const dialogConfig = new MatDialogConfig();
    //console.table(awsAccount);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      awsAccountInfo: awsAccount,
      dataType: type
    };
    const dialogRef = this.dialog.open(AwsissuesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", data)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Close') {
          this.showErrMsg = false;
          this.showMsg = false;
        }

      }
    );

  }

  getAzureIssues(azureAccount: object, type: string) {
    //console.table(azureAccount);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      azureAccountInfo: azureAccount,
      dataType: type
    };
    const dialogRef = this.dialog.open(AzureissuesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", data)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Close') {
          this.showErrMsg = false;
          this.showMsg = false;
        }

      }
    );

  }

}

