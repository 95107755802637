import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { RepositoryService } from '../service/repository.service';
import { Scandata } from '../azurekey-audit/Scandata';
import { MatTableExporterDirective, ExportType } from 'mat-table-exporter';

@Component({
  selector: 'app-azurekey-audit',
  templateUrl: './azurekey-audit.component.html',
  styleUrls: ['./azurekey-audit.component.css']
})
export class AzurekeyAuditComponent implements OnInit {
  viewAuditDataForm: FormGroup;
  dataSource: MatTableDataSource<Scandata>;
  displayedColumns: any;
  scanData: Scandata[];
  

  //@ViewChild(FormGroupDirective)
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
  constructor(private rsd: RepositoryService,private fb: FormBuilder, public dialogRef: MatDialogRef<AzurekeyAuditComponent>) { }

  //dataSource = new MatTableDataSource(PAYMENTS);

  ngOnInit() {
    console.log("Inside audit");
    let login = sessionStorage.getItem('wwid');
    this.rsd.getAllApplicationsforAudit(login).subscribe(res => {
     // console.log("Inside fetch",res['Items']);
      this.scanData = res['Items'];
      // if(this.scanData['ExpiryTimeCert']){
        this.displayedColumns = ['Application Name','Application ID','Renew_Status',
        'Business Owner Name','IT Owner Name','Renewal_Date','Renewed_By',
        //'ExpiryTimeCert', 'ExpiryTimeCert',ExpiryTimeSecret,Renew_Status,Renewed_By,Renewal_Date
        'ExpiryTimeSecret'];  
      // }else{
      //   this.displayedColumns = ['Application Name','Application ID','Renew_Status',
      // 'Business Owner Name','Renewal_Date','Renewed_By',
      // 'IT Owner Name','ID',
      // //'ExpiryTimeCert',ExpiryTimeSecret,Renew_Status,Renewed_By,Renewal_Date
      // 'ExpiryTimeSecret','ExpiryTimeCert'];
      // }
      // this.displayedColumns = ['Application Name','Application ID','Renew_Status',
      // 'Business Owner Name','Renewal_Date','Renewed_By',
      // 'IT Owner Name','ID',
      // //'ExpiryTimeCert',ExpiryTimeSecret,Renew_Status,Renewed_By,Renewal_Date
      // 'ExpiryTimeSecret'];
      console.log("scandata is:",this.scanData);
      this.dataSource = new MatTableDataSource(this.scanData);
      this.dataSource.paginator = this.paginator;
      console.log("Inside fetch",this.dataSource);
    }) //
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    
    //this.viewAuditDataForm = this.fb.group({ 
    //});
  }

  closeDialog() {
    // console.table("Clicked Cancel")
    this.dialogRef.close();
  }

  exportIt() {
    this.exporter.exportTable(ExportType.CSV, {
      fileName: "Completed_Renewals"+new Date().toLocaleString(),
      Props: {
        Author: "Cloud Governance Team"
      }
    });
  }

}
