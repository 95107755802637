import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RepositoryService } from './../service/repository.service';
@Component({
  selector: 'app-manageaccessmessage',
  templateUrl: './manageaccessmessage.component.html',
  styleUrls: ['./manageaccessmessage.component.css']
})
export class ManageaccessmessageComponent implements OnInit {
  local_data: any;
  userID: string;
  selectedGroupIDs:any;
  showGroups :  boolean = false;
  noGroupsSelected :  boolean = false;
  constructor( @Optional() @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ManageaccessmessageComponent>,private rs: RepositoryService) {
    this.local_data = data;
   }

  ngOnInit(): void {
    this.userID = this.local_data['userID'];
    this.selectedGroupIDs = this.local_data['selectedGroupIDs'];
    //console.log(this.userID)
    //console.log(this.selectedGroupIDs)
    if (this.selectedGroupIDs.length == 0){
     
      this.noGroupsSelected = true
    }
    else{
      this.showGroups = true
    }
  }
  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
  deleteAccess(){
  this.rs.manageCloudMemberships(this.userID, this.selectedGroupIDs).subscribe(
    (res) => {
      //console.log('res: ' + JSON.stringify(res));
      this.dialogRef.close({ event: 'Save' });
      //this.getUserAccess()
      //this.selection.clear()

    },
    (error) => {
      this.dialogRef.close({ event: 'Failed' });
      console.log("error message:" + error['message']);
      //console.log("error error:" + JSON.stringify(error));

    }
  )
}
}
