import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IEC2Recommendations } from './../interface/awsEC2Recommendations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RepositoryService } from './../service/repository.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-awsissues',
  templateUrl: './awsissues.component.html',
  styleUrls: ['./awsissues.component.css']
})
export class AwsissuesComponent implements OnInit {
  local_data: any;
  action: string;
  accountName: string;
  accountID: string;
  accountAppID: string;
  signinCount:string;
  awsadminUsers:string;
  accountIssues: string[];
  computeOptimization1: [];
  computeOptimization2: [];
  loading = true;
  noRecords: boolean = false;
  noComputeOptmizerRecomendations: boolean = true;
  awsAccountLink: string;
  showEC2Recommendations: boolean = false;
  showErrosAndWarning: boolean = false;
  showomputeOptmizerRecomendations: boolean = false;
  awsAccoutsTable: IEC2Recommendations[] = [];
  computeOptimization: any;
  awsAccoutsTableDataSource = new MatTableDataSource<IEC2Recommendations>(this.awsAccoutsTable);
  selection = new SelectionModel<IEC2Recommendations>(true, []);
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns: string[] = ['InstanceID', 'InstanceType', 'RecommendedAction', 'EstimatedMonthlySavings'];
  pageSize = 5;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<AwsissuesComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IEC2Recommendations, private rs: RepositoryService, ) {
    this.local_data = data;
    //console.table(this.local_data);
  }

  ngOnInit() {

    this.action = this.local_data['dataType'];
    //console.log(this.local_data['awsAccountInfo']['accountName'])
    //console.log(this.local_data['awsAccountInfo']['errorReport'])
    //console.log(this.local_data['awsAccountInfo']['warningReport'])
    this.accountName = this.local_data['awsAccountInfo']['accountName']
    this.accountID = this.local_data['awsAccountInfo']['id']
    this.accountAppID = this.local_data['awsAccountInfo']['azureADAppID']
    this.awsadminUsers = this.local_data['awsAccountInfo']['awsadminUsers']
    this.signinCount = this.local_data['awsAccountInfo']['signinCount']
    if (this.action === "errors") {
      this.accountIssues = this.local_data['awsAccountInfo']['errorReport']
      //this.accountIssues = this.accountIssues.filter(item => item !== "MFA on Root Account");
      this.showomputeOptmizerRecomendations = false;
      this.showEC2Recommendations = false;
      this.showErrosAndWarning = true;
      this.noRecords = false;
      this.awsAccountLink = environment.azureADLoginLink+this.accountName+'/'+this.accountAppID+environment.awsTrustedAdvisorRelayState
      //console.log( this.awsAccountLink)
    }
    else if (this.action === "warnings") {
      this.accountIssues = this.local_data['awsAccountInfo']['warningReport']
      this.showomputeOptmizerRecomendations = false;
      this.showEC2Recommendations = false;
      this.showErrosAndWarning = true;
      this.noRecords = false;
      this.awsAccountLink = environment.azureADLoginLink+this.accountName+'/'+this.accountAppID+environment.awsTrustedAdvisorRelayState
      //console.log( this.awsAccountLink)
    }
    else if (this.action === "computeoptmizer") {
      this.showEC2Recommendations = false;
      this.showErrosAndWarning = false;
      this.showomputeOptmizerRecomendations = true;
      this.noRecords = false;
      this.getComputeOptmizer(this.accountID);
    }
    else {

      this.awsAccountLink = environment.azureADLoginLink+this.accountName+'/'+this.accountAppID+environment.awsCERelayState
      //console.log( this.awsAccountLink)
      this.getEC2Recommendations(this.accountID);
    }


  }
  goToLink(url: string){
    window.open(url, "_blank");
  } 

  goToLinkNew(accountName:string,accountAppID:string)
  {
    //console.log(accountName)
    //console.log(accountAppID)
    if (accountName.startsWith('AWS CN')) {
      this.awsAccountLink = environment.azureADLoginLink + accountName + '/' + accountAppID + environment.awsCNTrustedAdvisorRelayState
    }
    else {
      this.awsAccountLink = environment.azureADLoginLink + accountName + '/' + accountAppID + environment.awsTrustedAdvisorRelayState
    }
    //console.log(this.awsAccountLink)
    window.open(this.awsAccountLink, "_blank");
  }
  goToLinkCompute(accountName:string,accountAppID:string)
  {
    //console.log(accountName)
    //console.log(accountAppID)
    if (accountName.startsWith('AWS CN')) {
      this.awsAccountLink = environment.azureADLoginLink + accountName + '/' + accountAppID + environment.awsCNTrustedAdvisorRelayState
    }
    else {
      this.awsAccountLink = environment.azureADLoginLink + accountName + '/' + accountAppID + environment.awsComputeOptimizerRelayState
    }
    //console.log(this.awsAccountLink)
    window.open(this.awsAccountLink, "_blank");
  }
  getComputeOptmizer(accountID: string) {
  this.rs.getComputeOptimizer(accountID).subscribe(

    (res1) => {

       this.computeOptimization1 = res1['Items'];
        //console.log( this.computeOptimization1)
        this.rs.getComputeOptimizer2(accountID).subscribe(

          (res2) => {
            this.computeOptimization2 = res2['Items']; 
            if(this.computeOptimization1 != undefined && this.computeOptimization2 != undefined) {
              this.computeOptimization1.push.apply(this.computeOptimization1, this.computeOptimization2)
              //console.log(this.computeOptimization1)
              if (this.computeOptimization1.length == 0){
                this.noComputeOptmizerRecomendations = false;
                //console.log(this.noComputeOptmizerRecomendations)
      
              }
            }
            else{
              this.noComputeOptmizerRecomendations = false;
            }
            
          }
        )
      
    }
   
  )
  
  }
  getEC2Recommendations(accountID: string) {

    this.rs.getEC2Recommendations(accountID).subscribe(

      (res) => {
        //console.log(res['Items'].length)
        if (res['Items'].length > 0) {
          this.awsAccoutsTable = res['Items'];
          this.awsAccoutsTableDataSource.data = this.awsAccoutsTable;
          this.loading = false;
          this.showEC2Recommendations = true;
          this.showErrosAndWarning = false;
          this.noRecords = false;
        }
        else {
          this.noRecords = true;
        }
      },
      (error) => {
        console.log("error message:" + error['message']);
        //console.log("error error:" + JSON.stringify(error));

      }
    )

   
    
    
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Close' });
  }
}