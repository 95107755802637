import { Component, ViewChild, HostBinding, Inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './../auth/auth.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DOCUMENT, JsonPipe } from '@angular/common';
import { RepositoryService } from '../service/repository.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  providers: [AuthService]
})
export class NavigationComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  isLoggedIn$: string;
  
  thumbnail : any;
  imageDisplay = false;
  name = 'Angular 7';
  @HostBinding('class') componentCssClass;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,@Inject(DOCUMENT) private document: any, private activatedRoute: ActivatedRoute, private rs: RepositoryService, private breakpointObserver: BreakpointObserver, private authService: AuthService, public overlayContainer: OverlayContainer) { 
    this.matIconRegistry.addSvgIcon(
      "amazon-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/amazon-icon.svg")
    );
  }

  

  ngOnInit() {
    const helper = new JwtHelperService();
    this.isLoggedIn$ = this.authService.isLoggedIn;
 
    
    let arr: string[] = this.document.location.href.split("id_token=");
    let arraccessToken: string[] = this.document.location.href.split("access_token=");
    if (arr[1]) {
      if(arraccessToken[1]){
        var access_token = arraccessToken[1].split('&token_type')[0];
      }
      
      var idToken = arr[1].split('&state')[0];
      sessionStorage.setItem('isAuthSuccess', 'true');
      sessionStorage.setItem(environment.idTokenKey, idToken);
      sessionStorage.setItem(environment.accessToken, access_token);

      let decodedToken = helper.decodeToken(idToken);
      let jwtHeader = idToken.substring(0, idToken.indexOf("."));
      //console.log(jwtHeader);
      //console.log(JSON.parse(atob(jwtHeader))["kid"]);
      //console.table(decodedToken)
      sessionStorage.setItem("Username", decodedToken['name']);
      sessionStorage.setItem("UserGUID", decodedToken['oid']);
      sessionStorage.setItem("KeyID", JSON.parse(atob(jwtHeader))["kid"]);
      let userRole = decodedToken['roles'];
      if (userRole == undefined) {
        sessionStorage.setItem("Role", "User");
      }
      else {
        sessionStorage.setItem("Role", decodedToken['roles']);
      }
      sessionStorage.setItem("wwid", decodedToken['preferred_username'].split('@')[0])
      sessionStorage.setItem("email", decodedToken['preferred_username'])
      sessionStorage.setItem("tokenExpiry", '' + helper.getTokenExpirationDate(idToken))
      
    }
    this.getImageFromService();
    
  }

  
  getImageFromService() {
    try {
    this.rs.getImage().subscribe((baseImage : any) => {
    //console.log(JSON.stringify(data.image));
    //let objectURL = URL.createObjectURL(baseImage);
    //console.log("objectURL:::",objectURL);
    //console.log("baseImage:::",baseImage);
    //alert(JSON.stringify(baseImage));
   
    if( JSON.stringify(baseImage).indexOf('application/json') >= 0){
      //console.log("No Image:::",baseImage)
      //console.log("No Image:::",this.imageDisplay)
    }
    else{
      this.thumbnail = this.domSanitizer.bypassSecurityTrustUrl(baseImage);
      this.imageDisplay = true;
    }
  });
  }
catch (err) {
  console.log("error message:" + err['message']);

}
}
  get user(): any {
    return sessionStorage.getItem('Username');
  }
  onLogout() {
    this.authService.logout();

  }

  @ViewChild('drawer') drawer: any;
  toggleDrawer() {
    this.drawer.toggle();
    //console.log(this.drawer.toggle);
  }

  
  showToggleMenu() {
    if (this.isLoggedIn$ && this.isHandset$) {
      return true;
    } else {
      return false;
    }
  }

}
