import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Orientation} from '@ngmodule/material-carousel';
import { ThemePalette } from '@angular/material/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { RepositoryService } from './../service/repository.service';
import { IDisplayOptions } from '../interface/displayOptions';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
  private static readonly INSTALL_TEXT =
    'npm install @ngmodule/material-carousel';

  public slidesList = [
    'assets/images/camera-coffee-composition-1509428.jpg',
    'assets/images/artist-bracelet-create-1327214.jpg',
    'assets/images/analysis-blog-blogger-1657152.jpg'
  ];
  public showContent = false;
  public timings = '250ms ease-in';
  public autoplay = true;
  public interval = 5000;
  public loop = true;
  public hideArrows = false;
  public hideIndicators = false;
  public color: ThemePalette = 'accent';
  public maxWidth = 'auto';
  public proportion = 25;
  public slides = this.slidesList.length;
  public overlayColor = '#00000040';
  public hideOverlay = false;
  public useKeyboard = true;
  public useMouseWheel = false;
  public orientation: Orientation = 'ltr';
  displayDocuments: Array<any>;
  loading = true;
  constructor(@Inject(DOCUMENT) private document: any, private domSanitizer: DomSanitizer,private matIconRegistry: MatIconRegistry,private http: HttpClient,private breakpointObserver: BreakpointObserver,private router: Router,private rs: RepositoryService) {
    this.matIconRegistry.addSvgIcon(
      "aws-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Amazon_Web_Services_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "azure-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Azure_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "oci-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/OCI_Logo.svg")
    );
   }

  ngOnInit() {
    var currentDate = new Date().toISOString();
    //console.log("currentDate::",currentDate)
    //var isLoggedIn = sessionStorage.getItem('isLoggedIn');
    var isAuthSuccess = sessionStorage.getItem('isAuthSuccess');
	  //if (isLoggedIn == null || isAuthSuccess == null) {
    if ( isAuthSuccess == null) {
      this.router.navigate(['']);
    }
    this.getAllDocuments();

    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  getAllDocuments() {
    try {
      this.rs.manageCloudDocuments('Homepage','','','','','','','','','').subscribe(res => {
        //console.log("res",res['Items']);
        this.displayDocuments = res['Items']
        this.loading = false;
        //console.log("displayDocuments",this.displayDocuments);
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }
  goToLink(url: string){
    window.open(url, "_blank");
  } 
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      return [
        { title: 'Cummins Cloud Governance Portal', content: 'Serverless Cloud Governance', url:'/assets/images/cummins-profile-banner.jpg', cols: 2, rows: 1 }
        //{ title: 'Account Management', content: 'AWS Accounts Onboarding', url:'/assets/images/aws_landingzone.jpg', cols: 1, rows: 1 },
        //{ title: 'AWS Accounts Access Management', content: 'Access Management & Auditing',  url:'/assets/images/cloud-access.jpg', cols: 1, rows: 1 },
        //{ title: 'Billing & Cost Optimisation', content: 'Access Cost optimasations and and Billing', url:'/assets/images/AWS2.jpg', cols: 1, rows: 1 },
        //{ title: 'Guidelines', content: 'Links to usefull documents', url:'/assets/images/guidelines-generic.jpg', cols: 1, rows: 1 }
      ];
    })
  );
}
