import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAzureADRequests } from './../interface/azureadssorequest';
import { RepositoryService } from './../service/repository.service';
import { AzureadssoactionrequestsComponent } from './../azureadssoactionrequests/azureadssoactionrequests.component';
import { environment } from './../../environments/environment';
import { Router, ActivatedRoute  } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AwsaccessrequestComponent } from '../awsaccessrequest/awsaccessrequest.component';
import { SendemailsComponent } from './../sendemails/sendemails.component';
import { Ng2CsvService } from 'ng2csv';
import { MatCheckboxChange } from '@angular/material/checkbox';



@Component({
  selector: 'app-azuressorequests',
  templateUrl: './azuressorequests.component.html',
  styleUrls: ['./azuressorequests.component.css'],
  providers: [Ng2CsvService]
})
export class AzuressorequestsComponent implements OnInit {
  azureADRequests: IAzureADRequests[] = [];
  azureAllRequests: IAzureADRequests[] = [];
  azureADApprovals: IAzureADRequests[] = [];

  azureADRequestsDataSource = new MatTableDataSource<IAzureADRequests>(this.azureADRequests);
  azureAllRequestsDataSource = new MatTableDataSource<IAzureADRequests>(this.azureAllRequests);
  azureADApprovalsDataSource = new MatTableDataSource<IAzureADRequests>(this.azureADApprovals);

  selection = new SelectionModel<IAzureADRequests>(true, []);
  //customerListlength: string ;
  filterVal = '';
  loading = true;
  isAdmin = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;
  paramsMessage : string;

  @ViewChild(MatTable) table: MatTable<any>;
  //displayedColumns: string[] = ['id', 'accountName', 'accountEmail', 'accountOwners', 'isActive', 'azureADRef', 'actions'];
  displayedColumns: string[] = ['appRegName', 'appRegType', 'environment','userDetails', 'actions'];

  pageSize = 5;


  @ViewChild('PaginatorMyRequests', { static: true }) paginatorMyRequests: MatPaginator;
  @ViewChild('PaginatorAllRequests', { static: true }) paginatorAllRequests: MatPaginator;
  @ViewChild('PaginatorMyApprovals', { static: true }) paginatorMyApprovals: MatPaginator;


  @ViewChild('SortMyRequests', { static: true }) sortMyRequests: MatSort;
  @ViewChild('SortAllRequests', { static: true }) sortAllRequests: MatSort;
  @ViewChild('SortMyApprovals', { static: true }) sortMyApprovals: MatSort;

  constructor(private route: ActivatedRoute, private ng2Csv: Ng2CsvService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, @Inject(DOCUMENT) private document: any, private http: HttpClient, private rs: RepositoryService, private auth: AuthService, private router: Router, private dialog: MatDialog) {
    this.matIconRegistry.addSvgIcon(
      "edit-admin",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/edit-admin.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "aws-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Amazon_Web_Services_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "azure-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Azure_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "oci-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/OCI_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "pim-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/APIM.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "mg-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/AMG.svg")
    );

  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        //console.log(params); // { orderby: "price" }
        this.paramsMessage = params.message;
        //console.log(this.paramsMessage)
        if (this.paramsMessage === 'success'){
          this.showMsg = true;
          this.showErrMsg = false;
          this.successMessage = "Sucessfully requested Azure AD SSO Application Registration. Please wait for approval !!";
          this.errorMessage = "";
        }
        else if (this.paramsMessage === 'failure'){
          this.showMsg = false;
          this.showErrMsg = true;
          this.successMessage = "";
          this.errorMessage = "Error while requesting Azure AD SSO Application Registration. Please try again!.";
        }
        else{
          this.showMsg = false;
          this.showErrMsg = false;
          this.successMessage = "";
          this.errorMessage = "";

        }
      }
    );
  
    
    /* Only filter certail columns*/
    this.azureADRequestsDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.appRegName.toLowerCase().includes(filter) || data.requestID.toLowerCase().includes(filter) || data.appRepoID.toLowerCase().includes(filter) ||  data.appRepoName.toLowerCase().includes(filter) || data.appTechOwnerName.toLowerCase().includes(filter) || data.requestorName.toLowerCase().includes(filter);

    };
    this.azureADApprovalsDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.appRegName.toLowerCase().includes(filter) || data.requestID.toLowerCase().includes(filter) || data.appRepoID.toLowerCase().includes(filter) || data.appRepoName.toLowerCase().includes(filter) || data.appTechOwnerName.toLowerCase().includes(filter) || data.requestorName.toLowerCase().includes(filter);

    };

    this.azureAllRequestsDataSource.filterPredicate = function (data, filter: string): boolean {
      //return data.accountName.toLowerCase().includes(filter) || data.accountOwners.toString() === filter;
      return data.appRegName.toLowerCase().includes(filter) || data.requestID.toLowerCase().includes(filter) || data.appRepoID.toLowerCase().includes(filter) || data.appRepoName.toLowerCase().includes(filter) || data.appTechOwnerName.toLowerCase().includes(filter) || data.requestorName.toLowerCase().includes(filter);

    };
    /* Only filter certail columns*/

    this.getMyAzureADRequests();


    //console.log("userrole",userRole);


    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  ngAfterViewInit() {
    this.azureADRequestsDataSource.paginator = this.paginatorMyRequests,
    this.azureADRequestsDataSource.sort = this.sortMyRequests;

 

    this.azureADApprovalsDataSource.paginator = this.paginatorMyApprovals;
    this.azureADApprovalsDataSource.sort = this.sortMyApprovals;

    this.azureAllRequestsDataSource.paginator = this.paginatorAllRequests;
    this.azureAllRequestsDataSource.sort = this.sortAllRequests;

  }
  _setDataSource(indexNumber) {
    //console.log("indexNumber:", indexNumber)
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.showErrMsg = false;
          this.showMsg = false;
          //this.loading = true;
          this.azureADRequestsDataSource.paginator = this.paginatorMyRequests,
          this.azureADRequestsDataSource.sort = this.sortMyRequests;
      
          break;
        case 1:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this.getMyAzureADApprovals();
          !this.azureADApprovalsDataSource.paginator ? this.azureADApprovalsDataSource.paginator = this.paginatorMyApprovals : null;
          this.azureADApprovalsDataSource.sort = this.sortMyApprovals;
          
          break;
        case 2:
          this.showErrMsg = false;
          this.showMsg = false;
          this.loading = true;
          this. getAllAzureADRequests();
          !this.azureAllRequestsDataSource.paginator ? this.azureAllRequestsDataSource.paginator = this.paginatorAllRequests : null;
          //this.azureSubscriptionsTableDataSource.paginator = this.paginatorAzure;
          this.azureAllRequestsDataSource.sort = this.sortAllRequests;
      }
    });
  }
  get loggedinUser() {
    return sessionStorage.getItem('wwid');
  }
  //https://github.com/rars/ng2csv
  public downloadCSV(cloudType: string): void {
    if (cloudType.includes("MyRequests")) {
      this.ng2Csv.download(this.azureADRequests, 'my-azuread-requests.csv');
    }
    else if (cloudType.includes("AllRequests")) {
      this.ng2Csv.download(this.azureAllRequests, 'azuread-allrequests.csv');
    }
    else {
      this.ng2Csv.download(this.azureADApprovals, 'my-azuread-approvals.csv');
    }

  }
  getMyAzureADRequests() {
    let userWWID =  sessionStorage.getItem('wwid')
    let userRole =  sessionStorage.getItem('Role')

    try {
      this.rs.getAzureSSORequests(userWWID, userRole, "MyRequests").subscribe(res => {
        // console.log("res",res);
        this.azureADRequests = res['Items'];
        this.azureADRequestsDataSource.data = this.azureADRequests;
        this.loading = false;
        

      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }


  

  getMyAzureADApprovals() {
    let userWWID =  sessionStorage.getItem('wwid')
    let userRole =  sessionStorage.getItem('Role')

    try {
      this.rs.getAzureSSORequests(userWWID, userRole, "MyApprovals").subscribe(res => {
        // console.log("res",res);
        this.azureADApprovals = res['Items'];
        this.azureADApprovalsDataSource.data = this.azureADApprovals;
        this.loading = false;
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  getAllAzureADRequests() {
    let userWWID =  sessionStorage.getItem('wwid')
    let userRole =  sessionStorage.getItem('Role')

    try {
      this.rs.getAzureSSORequests(userWWID, userRole, "AllRequests").subscribe(res => {
        // console.log("res",res);
        this.azureAllRequests = res['Items'];
        this.azureAllRequestsDataSource.data = this.azureAllRequests;
        this.loading = false;
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  applyFilterMyRequests(filterValue: string) {
    this.azureADRequestsDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterAllRequests(filterValue: string) {
    this.azureAllRequestsDataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterMyApprovals(filterValue: string) {
    this.azureADApprovalsDataSource.filter = filterValue.trim().toLowerCase();

  }

  editRequests(azureSSORequests: object, userActioning: string) {
    const dialogConfig = new MatDialogConfig();
    let dialogRef;
    console.table(azureSSORequests);
    //console.log("action:::"+action);


    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      azureADRequests: azureSSORequests,
      userActioning: userActioning,
      
    };
    //console.log("dialogConfig:::"+JSON.stringify(dialogConfig));

      dialogRef = this.dialog.open(AzureadssoactionrequestsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        dialogData => {
  
          //console.table(dialogData)
          let userResponse = dialogData['event'];
          //console.log("userResponse:", userResponse)
          if (userResponse == 'ReSubmit' || userResponse == 'Cancelled') {
            //console.log("Dialog events:::",dialogData['data']['accountEmail'])
  
            try {
              let status = dialogData['data'];
              //console.log("status:", status)
  
              if (status == '200') {
                this.getMyAzureADRequests();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully updated Azure AD Application Registration Request"
  
  
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied updating Azure AD Application Registration Request. Please try again"
              }
  
            }
            catch{
              this.showErrMsg = true;
              this.errorMessage = "Falied updating Azure AD Application Registration Request. Please try again"
            }
  
          }
          else if (userResponse == 'OpsApproved' || userResponse == 'OpsRejected' || userResponse == 'OwnerApproved' || userResponse == 'OwnerRejected') {
            //console.log("Dialog events:::",dialogData['data']['accountEmail'])
  
            try {
              let status = dialogData['data'];
              //console.log("status:", status)
  
              if (status == '200') {
                this.getMyAzureADApprovals();
                this.showErrMsg = false;
                this.showMsg = true;
                this.successMessage = "Sucessfully updated Azure AD Application Registration Request"
  
  
              }
              else {
                this.showErrMsg = true;
                this.errorMessage = "Falied updating Azure AD Application Registration Request. Please try again"
              }
  
            }
            catch{
              this.showErrMsg = true;
              this.errorMessage = "Falied updating Azure AD Application Registration Request. Please try again"
            }
  
          }
          else {
            this.showErrMsg = false;
            this.showMsg = false;
          }
        }
      );
   
  }
 
}

