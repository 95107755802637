import { IAWSAccessRequests } from './../interface/awsaccessrequests';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from './../service/repository.service';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
import { SendemailsComponent } from './../sendemails/sendemails.component';

@Component({
  selector: 'app-awsuseropenrequests',
  templateUrl: './awsuseropenrequests.component.html',
  styleUrls: ['./awsuseropenrequests.component.css']
})
export class AwsuseropenrequestsComponent implements OnInit {
  myawsRequestsTable: IAWSAccessRequests[] = [];
  myrequestsTableDataSource = new MatTableDataSource<IAWSAccessRequests>(this.myawsRequestsTable);
  selection = new SelectionModel<IAWSAccessRequests>(true, []);
  loading = true;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;
  filterVal:string = '';
  hasRecords : boolean = true;
  accessReposponse: string;

  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns: string[] = ['approverID', 'accountName', 'groupName', 'actions'];
  pageSize = 5;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(DOCUMENT) private document: any, private auth: AuthService,private http: HttpClient, private rs: RepositoryService, private router: Router, private dialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) { 
  
  }

  ngOnInit(): void {
    this.getPendingAccessRequests();
    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);

  }
  ngAfterViewInit() {
    this.myrequestsTableDataSource.paginator = this.paginator,
    this.myrequestsTableDataSource.sort = this.sort
  }
  

  getPendingAccessRequests() {
    try {
      let groupID = '';
      let userID = sessionStorage.getItem('wwid');
      let userType = 'user';
      this.rs.getAuditforAWSAccess(groupID, userID, userType).subscribe(
        (res) => {
          if(res['Items'].length > 0) {
          //console.table("My Request:::",res['Items'])
          this.myawsRequestsTable = res['Items'];
          this.myrequestsTableDataSource.data = this.myawsRequestsTable;
          this.loading = false;
          this.hasRecords = true;
          }
          else{
            this.hasRecords = false;
            this.accessReposponse = "You don't have any open requests !!"
          }
        },
        (error) => {
          console.log("error message:" + error['message']);
          //console.log("error error:" + JSON.stringify(error));

        }
      )
    }
    catch (err) {
      console.log("error message:" + err['message']);

    }
  }
  applyFilter(filterValue: string) {
    this.myrequestsTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  contactOwners(awsRequest: object, messageType: string) {
    const dialogConfig = new MatDialogConfig();
    //console.table(awsRequest);
    //console.log("messageType:::" + messageType);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      awsAccountInfo: awsRequest,
      awsMessageType: messageType
    };
    const dialogRef = this.dialog.open(SendemailsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", dialogData)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Success') {

          this.showErrMsg = false;
          this.showMsg = true;
          this.successMessage = "Sucessfully contacted approver through Email"

        }
        else if (userResponse == 'Fail') {
          this.showErrMsg = true;
          this.showMsg = false;
          this.errorMessage = "Error while contacting approver through Email. Please try again"

        }
        else {
          this.showErrMsg = false;
          this.showMsg = false;

        }
      });

  }

  revokeRequest(action: string,accessRequests: object) {
    //console.log("accessRequests:" + accessRequests['requestID']);

    //console.table(accessRequests);
    //console.log("action:" + action);
    let wwids = accessRequests['requestorID'];
    let groupID = 'None';
    let groupName = accessRequests['groupName'];
    let accountName = accessRequests['accountName'];
    let accountIDAzureRef = "NA";
    let requestType = "SSR";
    let comments = "None";
    let approverID = accessRequests['approverID'];
    let requestID = accessRequests['requestID'];
    let additionalApprovers = accessRequests['additionalApprovers'];
    let requestorName = accessRequests['requestorName'];
    let delegatedUserID = "None";
    

    

    
    this.rs.actionRequestsAWSAccess(wwids, groupID, groupName, accountName, accountIDAzureRef, requestType, action, approverID, requestID, additionalApprovers, requestorName,delegatedUserID,comments).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("cancelled") == -1) {
       
        console.log('Approved');
        this.showErrMsg = true;
        this.showMsg = false;
        this.errorMessage = "Falied to cancel access request. Please try again"
        this.getPendingAccessRequests();
      }
      else {
        console.log('Declined');
        this.showErrMsg = false;
        this.showMsg = true;
        this.successMessage = "Sucessfully cancelled access request"
        this.getPendingAccessRequests();
        
      }

    })
  }

}
