// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  spinner_delay_time: 2000,
  spinner_delay_time_azure: 1000,
  contentTypeKey: 'Content-Type',
  contentTypeValue: 'application/x-www-form-urlencoded',
  contentTypeValueJSON: 'application/json',


  grantTypeKey: 'grant_type',
  grantTypeValue: 'authorization_code',

  scopevalue: 'openid profile offline_access https://graph.microsoft.com/.default',

  scope: 'scope',
  codeKey: 'code',
  idTokenKey: 'id_token',
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  clientIdKey: 'client_id',
  clientIdValue: '314b08e3-4779-444a-bc8b-5d718ba2c191',
  //clientIdValue: '1kkn8t3mpkoi1ci3e83gihruqo',
  codeToTokenServiceUrl: 'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/v2.0/token',

  redirectUrlKey: 'redirect_uri',
  azureADLoginLink: 'https://myapps.microsoft.com/signin/',
  awsTrustedAdvisorRelayState: '?RelayState=https://console.aws.amazon.com/trustedadvisor/home?region=us-east-1#/dashboard',
  awsCERelayState: '?RelayState=https://console.aws.amazon.com/cost-reports/home?#/recommendations/dashboard',
  awsBillingRelayState: '?RelayState=https://console.aws.amazon.com/billing/home?region=us-east-1#/',
  awsCongifRelayState: '?RelayState=https://console.aws.amazon.com/config/home',
  awsComputeOptimizerRelayState: '?RelayState=https://console.aws.amazon.com/compute-optimizer/home',


  awsCNTrustedAdvisorRelayState: '?RelayState=https://console.amazonaws.cn/trustedadvisor/home?region=cn-north-1#/dashboard',
  awsCNCERelayState: '?RelayState=https://console.amazonaws.cn/billing/home#/',
  awsCNBillingRelayState: '?RelayState=https://console.amazonaws.cn/billing/home#/',
  awsCNCongifRelayState: '?RelayState=hhttps://console.amazonaws.cn/config/home',
  //awsConfigurlAddress: 'https://gw26xkeg5c.execute-api.us-east-1.amazonaws.com/PROD/getAWSAccountsConfigData',
  //awsCNConfigurlAddress: 'https://gw26xkeg5c.execute-api.us-east-1.amazonaws.com/PROD/getAWSAccountsConfigData',
 


  //old
  //callbackUrl: 'https://cummins-dev-pool.auth.us-east-1.amazoncognito.com/authorize?response_type=code&client_id=1kkn8t3mpkoi1ci3e83gihruqo&redirect_uri=https://diqay1fquflbt.cloudfront.net/resources&identity_provider=AzureAD',
  //callbackUrl : 'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/v2.0/authorize?response_type=code&scope=openid profile offline_access https://graph.microsoft.com/.default&client_id=314b08e3-4779-444a-bc8b-5d718ba2c191&redirect_uri=http://localhost:4200/resources&state=state',
  //callbackUrl: 'https://cummins-dev-pool.auth.us-east-1.amazoncognito.com/authorize?response_type=code&client_id=1kkn8t3mpkoi1ci3e83gihruqo&redirect_uri=http://localhost:4200/resources&identity_provider=AzureAD',
  //logoutUrl : 'https://cummins-dev-pool.auth.us-east-1.amazoncognito.com/logout?client_id=1kkn8t3mpkoi1ci3e83gihruqo&&logout_uri=https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0',
  //codeToTokenServiceUrl: 'https://cummins-dev-pool.auth.us-east-1.amazoncognito.com/oauth2/token',
  //old

  //local
    /*
  callbackUrl :  'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/v2.0/authorize?response_type=id_token&scope=openid profile https://graph.microsoft.com/.default&client_id=84d5b2b6-030f-469f-8922-4dc520cb7c99&redirect_uri=http://localhost:4200/auth&nonce=678910&state=12345',
  logoutUrl : 'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/logout?post_logout_redirect_uri=http://localhost:4200',
  redirectUrlValue: 'http://localhost:4200/auth',
  urlAddress: 'https://cloudgovernanceapi-dev.cummins.com/v1/',
  getUserImage: 'https://bsbdani94m.execute-api.us-east-1.amazonaws.com/Dev',
  callbackUrlAccessToken :  'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/v2.0/authorize?response_type=id_token+token&scope=openid profile https://graph.microsoft.com/.default&client_id=84d5b2b6-030f-469f-8922-4dc520cb7c99&redirect_uri=http://localhost:4200/pimstg&nonce=678910&state=12345',
  local*/



  //CloudGov Dev

  callbackUrl :  'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/v2.0/authorize?response_type=id_token&scope=openid profile https://graph.microsoft.com/.default&client_id=84d5b2b6-030f-469f-8922-4dc520cb7c99&redirect_uri=https://cloudgovernance-dev.cummins.com/auth&nonce=678910&state=12345',
  logoutUrl : 'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/logout?post_logout_redirect_uri=https://cloudgovernance-dev.cummins.com',
  redirectUrlValue: 'https://cloudgovernance-dev.cummins.com/auth',
  urlAddress: 'https://cloudgovernanceapi-dev.cummins.com/v1/',
  getUserImage: 'https://bsbdani94m.execute-api.us-east-1.amazonaws.com/Dev',
  callbackUrlAccessToken :  'https://login.microsoftonline.com/b31a5d86-6dda-4457-85e5-c55bbc07923d/oauth2/v2.0/authorize?response_type=id_token+token&scope=openid profile https://graph.microsoft.com/.default&client_id=84d5b2b6-030f-469f-8922-4dc520cb7c99&redirect_uri=https://cloudgovernance-dev.cummins.com/pimstg&nonce=678910&state=12345',


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
