import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { IAWSGroupAudit } from './../interface/awsgroupaudit';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';
import { HttpClient} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { environment } from './../../environments/environment';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-awsaccessaudit',
  templateUrl: './awsaccessaudit.component.html',
  styleUrls: ['./awsaccessaudit.component.css']
})
export class AwsaccessauditComponent implements OnInit {
  awsList: any
  awsGroups: any
  auditForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  hasRecords : boolean = true;
  accessReposponse: string;

  awsGroupAudit: IAWSGroupAudit[] = [];
  awsGroupAuditTableDataSource = new MatTableDataSource<IAWSGroupAudit>(this.awsGroupAudit);
  filterVal='';
  loading = true;
  pageSize = 5;

  //displayedColumns: string[] = ['groupName', 'approverID', 'requestorName', 'statusComments','updateTime','requestType'];
  displayedColumns: string[] = ['approverID', 'requestorName', 'statusComments','updateTime','requestType'];

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective)

  formGroupDirective: FormGroupDirective;
  constructor(@Inject(DOCUMENT) private document: any, private auth: AuthService, private rs: RepositoryService, private router: Router, private fb: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    

    this.getAllAWSAccounts();

    this.auditForm = this.fb.group({
      aws_account: ['', [Validators.required]],
      aws_role: ['', [Validators.required]],
      wwids: ['', [Validators.maxLength(5)]]
    });
    setTimeout(() => {
      this.loading = false;
    }, 
    environment.spinner_delay_time);
  }

  ngAfterViewInit() {
    this.awsGroupAuditTableDataSource.paginator = this.paginator,
    this.awsGroupAuditTableDataSource.sort = this.sort
    
  }

  getAllAWSAccounts() {
    try{
    this.rs.getAWSAccounts(sessionStorage.getItem("wwid"), "List").subscribe(res => {
      //console.log("res['Items']",res['Items'].length);
      if(res['Items'].length>0){
        this.awsList = res['Items'];
        this.hasRecords = true;
      }
      else{
        this.hasRecords = false;
        this.accessReposponse = 'You are not owner of AWS Accounts. You cannot audit access'
      }
    })
  }
  catch (error){
    console.log("error message:" + error['message']);
  }
  }
 
  get f() {
    return this.auditForm.controls;
  }
  get aws_role() {
    return this.auditForm.get('aws_role');
  }
  get wwids() {
    return this.auditForm.get('wwids');
  }

  awsLevelChangeAction(data: string) {
    let awsselected: string = data['azureADRef'];
    //console.log('awsselected: ' + awsselected);
    this.rs.getAWSAccountGroups(awsselected).subscribe(res => {
      this.awsGroups = res['value'];
      //console.log('awsGroups: ' + this.awsGroups);
    })
  }

  onSubmit() {
    this.submitted = true;
    
    //console.log(JSON.stringify(this.requestAccessForm.value));
    //console.table(this.grantAccessForm.value);
    let userID = this.auditForm.value['wwids'];
    let groupID = this.auditForm.value['aws_role'].principalId;
    let userType = 'owner';
    //console.log('userID: ' + userID);
    //console.log('groupID: ' + groupID);
    //console.log('groupID: ' + groupID);
    if(userID === ''){
      userID = 'None';
    }
    //console.log('userID: ' + userID);
    this.rs.getAuditforAWSAccess(groupID, userID, userType).subscribe(res => {
          //console.log('res: ' + JSON.stringify(res));
          if(JSON.stringify(res).indexOf("Error") == -1){
            console.log('Success');
            this.awsGroupAudit = res['Items'];
            this.awsGroupAuditTableDataSource.data = this.awsGroupAudit;
            this.loading = false;
            this.showMsg= true;
            //this.resetEverything();
          }
          else{
            this.showErrMsg= true;
          }
         
    })

  }
  applyFilter(filterValue: string) {
    this.awsGroupAuditTableDataSource.filter = filterValue.trim().toLowerCase();
  }
}
