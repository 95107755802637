import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAWSAccounts } from './../interface/awsaccounts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../auth/auth.service';
import { RepositoryService } from './../service/repository.service';



@Component({
  selector: 'app-awsedit',
  templateUrl: './awsedit.component.html',
  styleUrls: ['./awsedit.component.css']
})
export class AwseditComponent implements OnInit {
  editForm: FormGroup;
  json: string;
  submitted: boolean = false;
  action: string;
  local_data: any;
  isAdmin = false;

  constructor(private fb: FormBuilder, private auth: AuthService,  private rs: RepositoryService,public dialogRef: MatDialogRef<AwseditComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccounts) {

    this.local_data = data;
    //console.log("data in dialog:::::::::"+ JSON.stringify(data));
    //console.table(this.local_data);
    //console.log("data in dialog:::::::::" + data['AccountInfo']['accountOwners']);
  }

  ngOnInit() {

    //console.log("data in dialog:::::::::" + this.local_data['accountName']);
    //console.table(this.local_data);

    this.action = this.local_data['userAction'];
    if (this.action == "edit") {
      this.isAdmin = true;
      this.editForm = this.fb.group({
        id: [{ value: this.local_data['AccountInfo']['id'], disabled: true }],
        accountName: [{ value: this.local_data['AccountInfo']['accountName'], disabled: true }],
        accountEmail: [{ value: this.local_data['AccountInfo']['accountEmail'], disabled: true }],
        // awsAccountName: [this.local_data['AccountInfo']['awsAccountName'], [Validators.required, Validators.maxLength(100)]],
        awsAccountName: [{ value: this.local_data['AccountInfo']['awsAccountName'], disabled: true }],
        mainAccount: [{ value: this.local_data['AccountInfo']['mainAccount'], disabled: true }],
        businessOwner: [{ value: this.local_data['AccountInfo']['businessOwner'], disabled: true }],
        accountOwners: [this.local_data['AccountInfo']['accountOwners'], [Validators.required, Validators.maxLength(12)]],
        azureADRef: [{ value: this.local_data['AccountInfo']['azureADRef'], disabled: true }],
        isActive: [{ value: this.local_data['AccountInfo']['isActive'], disabled: true }],
        //isActive: [this.local_data['AccountInfo']['isActive'], [Validators.required, Validators.maxLength(1)]],
        department: [this.local_data['AccountInfo']['department'], [Validators.required, Validators.maxLength(10)]],
        BC: [this.local_data['AccountInfo']['BC'], [Validators.required, Validators.maxLength(5)]],
        RC: [this.local_data['AccountInfo']['RC'], [Validators.required, Validators.maxLength(5)]],
        BU: [this.local_data['AccountInfo']['BU'], [Validators.required, Validators.maxLength(5)]],
        networkAttached: [{ value: this.local_data['AccountInfo']['networkAttached'], disabled: true }],
        //networkAttached: [this.local_data['AccountInfo']['networkAttached'], [Validators.required, Validators.maxLength(3)]],
        accountType: [{ value: this.local_data['AccountInfo']['accountType'], disabled: true }],
        //accountType: [this.local_data['AccountInfo']['accountType'], [Validators.required, Validators.maxLength(20)]],
        //mainAccount: [this.local_data['AccountInfo']['mainAccount'], [Validators.required, Validators.maxLength(20)]],
        //businessOwner: [this.local_data['AccountInfo']['businessOwner'], [Validators.required, Validators.maxLength(20)]],
        description: [this.local_data['AccountInfo']['description'], [Validators.required, Validators.maxLength(250)]],
      });

    }
    else if (this.action == "adminEdit") {
      this.isAdmin = true;
      this.editForm = this.fb.group({

        //id: [{ value: this.local_data['AccountInfo']['id'], disabled: true }],
        //accountName: [{ value: this.local_data['AccountInfo']['accountName'], disabled: true }],
        //accountEmail: [{value: this.local_data['AccountInfo']['accountEmail'], disabled: true }],
        //awsAccountName: [{value: this.local_data['AccountInfo']['awsAccountName'], disabled: true }],
        //mainAccount: [{value:this.local_data['AccountInfo']['mainAccount'], disabled: true }],
        //businessOwner: [{value:this.local_data['AccountInfo']['businessOwner'], disabled: true }],
        //azureADRef: [{ value: this.local_data['AccountInfo']['azureADRef'], disabled: true }],

        //id: [this.local_data['AccountInfo']['id'], [Validators.required, Validators.maxLength(12)]],
        id: [{ value: this.local_data['AccountInfo']['id'], disabled: true }],
        //accountName: [this.local_data['AccountInfo']['accountName'], [Validators.required, Validators.maxLength(100)]],
        accountName: [{ value: this.local_data['AccountInfo']['accountName'], disabled: true }],
        accountEmail: [this.local_data['AccountInfo']['accountEmail'], [Validators.required, Validators.maxLength(50)]],
        awsAccountName: [this.local_data['AccountInfo']['awsAccountName'], [Validators.required, Validators.maxLength(100)]],
        mainAccount: [this.local_data['AccountInfo']['mainAccount'], [Validators.required, Validators.maxLength(12)]],
        businessOwner: [this.local_data['AccountInfo']['businessOwner'], [Validators.required, Validators.maxLength(12)]],
        azureADRef: [{ value: this.local_data['AccountInfo']['azureADRef'], disabled: true }],
        accountOwners: [this.local_data['AccountInfo']['accountOwners'], [Validators.required, Validators.maxLength(12)]],
        isActive: [this.local_data['AccountInfo']['isActive'], [Validators.required, Validators.maxLength(1)]],
        department: [this.local_data['AccountInfo']['department'], [Validators.required, Validators.maxLength(10)]],
        BC: [this.local_data['AccountInfo']['BC'], [Validators.required, Validators.maxLength(5)]],
        RC: [this.local_data['AccountInfo']['RC'], [Validators.required, Validators.maxLength(5)]],
        BU: [this.local_data['AccountInfo']['BU'], [Validators.required, Validators.maxLength(5)]],
        networkAttached: [this.local_data['AccountInfo']['networkAttached'], [Validators.required, Validators.maxLength(3)]],
        accountType: [this.local_data['AccountInfo']['accountType'], [Validators.required, Validators.maxLength(20)]],
        description: [this.local_data['AccountInfo']['description'], [Validators.required, Validators.maxLength(250)]],
      });

    }
    else {
      this.editForm = this.fb.group({
        id: [{ value: this.local_data['AccountInfo']['id'], disabled: true }],
        accountName: [{ value: this.local_data['AccountInfo']['accountName'], disabled: true }],
        accountEmail: [{ value: this.local_data['AccountInfo']['accountEmail'], disabled: true }],
        awsAccountName: [{ value: this.local_data['AccountInfo']['awsAccountName'], disabled: true }],
        mainAccount: [{ value: this.local_data['AccountInfo']['mainAccount'], disabled: true }],
        businessOwner: [{ value: this.local_data['AccountInfo']['businessOwner'], disabled: true }],
        accountOwners: [{ value: this.local_data['AccountInfo']['accountOwners'], disabled: true }],
        azureADRef: [{ value: this.local_data['AccountInfo']['azureADRef'], disabled: true }],
        isActive: [{ value: this.local_data['AccountInfo']['isActive'], disabled: true }],
        department: [{ value: this.local_data['AccountInfo']['department'], disabled: true }],
        BC: [{ value: this.local_data['AccountInfo']['BC'], disabled: true }],
        RC: [{ value: this.local_data['AccountInfo']['RC'], disabled: true }],
        BU: [{ value: this.local_data['AccountInfo']['BU'], disabled: true }],
        networkAttached: [{ value: this.local_data['AccountInfo']['networkAttached'], disabled: true }],
        accountType: [{ value: this.local_data['AccountInfo']['accountType'], disabled: true }],
        //mainAccount: [this.local_data['AccountInfo']['mainAccount'], [Validators.required, Validators.maxLength(20)]],
        //businessOwner: [this.local_data['AccountInfo']['businessOwner'], [Validators.required, Validators.maxLength(20)]],
        description: [{ value: this.local_data['AccountInfo']['description'], disabled: true }],
      });
    }


  }

  get f() {
    return this.editForm.controls;
  }


  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;
    if (this.editForm.invalid) {
      return;
    }
    //console.table("accountOwners:::", this.editForm.getRawValue().accountOwners)

    let department = this.editForm.getRawValue().department;
    let BC = this.editForm.getRawValue().BC;
    let accountOwners = this.editForm.getRawValue().accountOwners;
    let networkAttached = this.editForm.getRawValue().networkAttached;
    let accountEmail = this.editForm.getRawValue().accountEmail;
    let azureADRef = this.editForm.getRawValue().azureADRef;
    let isActive = this.editForm.getRawValue().isActive;
    let RC = this.editForm.getRawValue().RC;
    let BU = this.editForm.getRawValue().BU;
    let accountType = this.editForm.getRawValue().accountType;
    let mainAccount = this.editForm.getRawValue().mainAccount;
    let accountName = this.editForm.getRawValue().accountName;
    let businessOwner = this.editForm.getRawValue().businessOwner;
    let id = this.editForm.getRawValue().id;
    let description = this.editForm.getRawValue().description;
    let awsAccountName = this.editForm.getRawValue().awsAccountName;
    let updatedBy = sessionStorage.getItem("wwid")

    this.rs.updateAWSAccount(department, BC, accountOwners, networkAttached, accountEmail, azureADRef, isActive, RC, BU, accountType, mainAccount, accountName, businessOwner, id, description, awsAccountName, updatedBy).subscribe(res => {
      let status = res['ResponseMetadata']['HTTPStatusCode'];
      this.dialogRef.close({ event: 'Save', data: status });
    })
      
  }

  closeDialog() {
      //console.table("Clicked Cancel")
      this.dialogRef.close({ event: 'Cancel' });
    }
}
