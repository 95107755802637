import { Component, OnInit } from '@angular/core';
import { Orientation} from '@ngmodule/material-carousel';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  private static readonly INSTALL_TEXT =
  'npm install @ngmodule/material-carousel';

  public authList = [
    'assets/images/authentication.PNG',
    'assets/images/flow.PNG',
    'assets/images/relation.PNG',
    'assets/images/actual.PNG'
];



public showContent = false;

public timings = '250ms ease-in';
public autoplay = true;
public interval = 5000;
public loop = true;
public hideArrows = false;
public hideIndicators = false;
public color: ThemePalette = 'accent';
public maxWidth = 'auto';
public proportion = 50;
public slides = this.authList.length;
public overlayColor = '#00000040';
public hideOverlay = true;
public useKeyboard = true;
public useMouseWheel = false;
public orientation: Orientation = 'ltr';

  constructor(private router: Router) { }

  ngOnInit() {
    var isAuthSuccess = sessionStorage.getItem('isAuthSuccess');
	  if (isAuthSuccess =='true') {
      this.router.navigate(['resources']);
    }
    else{
      this.router.navigate(['login']);
    }
  }

}

