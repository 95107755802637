
import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { IAWSAccounts } from '../interface/awsaccounts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
 
@Component({
  selector: 'app-awsmgaccess',
  templateUrl: './awsmgaccess.component.html',
  styleUrls: ['./awsmgaccess.component.css']
})
export class AwsmgaccessComponent implements OnInit {
  resourceTypes: string[] = ['CBS-IT','CBS-IT-CN'];
  mgRoles: any;
  requestAccessForm: FormGroup;
  selectAccessForm: FormGroup;
  card_header: string = "";
  resourceType : string;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  submitted: boolean = false;
  disableButton = false;
  constructor(public dialogRef: MatDialogRef<AwsmgaccessComponent>, private rs: RepositoryService, private fb: FormBuilder) { }
 
  ngOnInit(): void {
 
    this.getRootMGRoles("CBS-IT");
    this.card_header = "Select the AWS Operations Support Access Management to request access"
 
    this.selectAccessForm = this.fb.group({
      selectedResourceType: ['CBS-IT', [Validators.required]]
 
    });
 
 
    this.requestAccessForm = this.fb.group({
 
      aws_role: ['', [Validators.required]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      resourceType: ['CBS-IT', [Validators.required]]
 
    });
  }
  onSelectionChange(event: MatRadioChange) {
 
    if (event.value == "CBS-IT") {
 
      this.resourceType = "CBS-IT"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.aws_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.mgRoles = [];
      this.getRootMGRoles(this.resourceType);
      this.card_header = "Select the AWS Operations Support Access Management to request access"
    }
   
    else if (event.value == "CBS-IT-CN") {
      //console.log(event.value)
      this.resourceType = "CBS-IT-CN"
      this.requestAccessForm.markAsUntouched();
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.aws_role.setValue("");
      this.requestAccessForm.controls.resourceType.setValue(this.resourceType);
      this.mgRoles = [];
      this.getRootMGRoles(this.resourceType);
      this.card_header = "Select the AWS Operations Support Access Management to request access"
     
    }
 
  }
  get f() {
    return this.requestAccessForm.controls;
  }
  get comments() {
    return this.requestAccessForm.get('comments');
  }
 
 
  get card_title() {
    return this.card_header;
  }
  getRootMGRoles(resourceType: string) {
    //console.log('resourceType: ' + resourceType);
    try {
      this.rs.getAWSGroups("AWSRootManagementGroup", "NA", "NA", resourceType,"NA",resourceType).subscribe(res => {
        this.mgRoles = res['value'];
        console.log('awsGroups: ' + this.mgRoles);
      })
    }
    catch (error) {
      //console.log("error message:" + error['message']);
    }
  }
 
  onFormSubmit(awsAccountsRequest: object){
    //console.log(awsAccountsRequest);
    let groupName = awsAccountsRequest['aws_role']['displayName'];
    let groupID = awsAccountsRequest['aws_role']['id'];
    let approverID = awsAccountsRequest['aws_role']['description'];
    let accountID = awsAccountsRequest['resourceType'];
    let accountIDAzureRef = awsAccountsRequest['resourceType'];
    let accountName = awsAccountsRequest['resourceType'];
    let comments = awsAccountsRequest['comments'];
    let requestorID = sessionStorage.getItem('wwid');
    let requestorName = sessionStorage.getItem('Username');
    let requestcloudType = "awsRoot Management Group";
    
    this.rs.requestCloudAccess(groupName, groupID, approverID, accountID, accountIDAzureRef, accountName, comments, requestorID, requestorName, requestcloudType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("Error") == -1) {
        console.log('Success');
        this.dialogRef.close({ event: 'Success' });
        //this.resetEverything();
      }
      else {
 
        this.dialogRef.close({ event: 'Falied' });
      }
 
    })
    
 
  }
  doActionSave() {
    this.submitted = true;
    this.disableButton = true;
    if (this.requestAccessForm.invalid) {
      return;
    }
    this.onFormSubmit(this.requestAccessForm.getRawValue());
 
  }
 
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
 
