import { IUserCloudAccess } from './../interface/userCloudAccess';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from './../service/repository.service';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { Ng2CsvService } from 'ng2csv';
import { ManageaccessmessageComponent } from '../manageaccessmessage/manageaccessmessage.component';


@Component({
  selector: 'app-useraccessforcloud',
  templateUrl: './useraccessforcloud.component.html',
  styleUrls: ['./useraccessforcloud.component.css'],
  providers: [Ng2CsvService]
})
export class UseraccessforcloudComponent implements OnInit {
  userCloudAccessTable: IUserCloudAccess[] = [];
  userCloudAccessTableDataSource = new MatTableDataSource<IUserCloudAccess>(this.userCloudAccessTable);
  selection = new SelectionModel<IUserCloudAccess>(true, []);
  loading = true;
  successMessage: string;
  errorMessage: string;
  filterVal: string = '';
  hasRecords: boolean = false;
  hasDeleted: boolean = false;
  searchedUser: string;
  accessReposponse: string;
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns: string[] = ['select', 'displayName'];
  isAdmin = false;
  //pageSize = 5;
  //@ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('SortAWS', { static: true }) sortAWS: MatSort;
  // @ViewChild(MatPaginator) 
  //set paginator(value: MatPaginator) {
  //  this.userCloudAccessTableDataSource.paginator = value;
  // }

  userAccessForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  disableButton = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  isLoading = false;
  errorMsg: string;
  filteredUser: any;
  recordcount: string;
  userID: string;

  constructor(@Inject(DOCUMENT) private document: any, private ng2Csv: Ng2CsvService, private http: HttpClient, private rs: RepositoryService, private fb: FormBuilder, private router: Router, private dialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) { }

  ngOnInit() {
    this.userAccessForm = this.fb.group({
      wwid: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(5)]],
    });

    this.onChanges();
  }
  ngAfterViewInit() {
    //this.userCloudAccessTableDataSource.paginator = this.paginator,
    this.userCloudAccessTableDataSource.sort = this.sortAWS
  }

  onChanges() {
    this.userAccessForm.valueChanges.subscribe(userInput => {

      console.log(userInput['wwid']);
      this.searchedUser = userInput['wwid'];
      //if (userInput['wwid'].length > 3) {
      this.isLoading = true;
      this.rs.getUserDetails(userInput['wwid']).subscribe(usersList => {
        //console.log('usersList: ' + JSON.stringify(usersList));
        if (usersList['value'] == undefined) {
          this.errorMsg = usersList['Error'];
          this.filteredUser = [];
        } else {
          this.isLoading = false
          this.errorMsg = "";
          this.filteredUser = usersList['value'];
        }
      })
      //}

    });
  }
  get f() {
    return this.userAccessForm.controls;
  }
  get wwid() {
    return this.userAccessForm.get('wwid');
  }
  applyFilter(filterValue: string) {
    this.userCloudAccessTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  public downloadCSV(cloudUser: string): void {
    //console.log("searchedUser:::",cloudUser)

    this.ng2Csv.download(this.userCloudAccessTable, cloudUser + '-cloud-memberships.csv');



  }
  getUserAccess() {
    this.loading = true;
    this.submitted = true;
    if (this.userAccessForm.invalid) {
      return;
    }
    this.userAccessForm.getRawValue()
    //console.table("accessRequests:" + JSON.stringify(this.userAccessForm.getRawValue()));
    this.userID = this.userAccessForm.getRawValue()['wwid'] + "@cummins.com";;

    //console.log(userID) 
    this.hasDeleted = false;
    this.rs.getUserCloudMemberships(this.userID).subscribe(
      
      (res) => {
        //console.table("My Request:::",res['error'])
        if (JSON.stringify(res).includes("Request_ResourceNotFound")) {
          this.userCloudAccessTable = res['error'];
          this.recordcount = "0";
          this.showErrMsg = true;
          this.loading = false;
          this.errorMessage = "User does not exists !!"
          this.hasRecords = false;
          
        }
        else {
          if (res['value'].length > 0) {
            this.userCloudAccessTable = res['value'];
            this.userCloudAccessTableDataSource.data = this.userCloudAccessTable;
            this.recordcount = res['@odata.count'];
            var userRole = sessionStorage.getItem('Role');
            if (userRole.includes("Admin")) {
              this.isAdmin = true;
              //console.log("this.isAdmin",this.isAdmin);
            }

            //console.log(this.paginator)
            //this.userCloudAccessTableDataSource.paginator = this.paginator,
            this.loading = false;
            this.hasRecords = true;
            this.showErrMsg = false;
          }
          else {
            this.hasRecords = false;
            this.showErrMsg = true;
            this.loading = false;
            this.errorMessage = "User does not have cloud memberships !!"
          }
        }

      },
      (error) => {
        console.log("error message:" + error['message']);
        //console.log("error error:" + JSON.stringify(error));

      }
    )



  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.userCloudAccessTableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.userCloudAccessTableDataSource.filteredData.forEach(row => this.selection.select(row));
  }

  logSelection() {
    //this.selection.selected.forEach(s => console.log(s.id));
    //console.log(this.selection.selected)
    var selectedGroupIDs = this.selection.selected.map(function (elem) {
      return {
        id: elem.id + ":" + elem.displayName,

      };
    });
    //console.log(selectedGroupIDs.length)
    //console.log("UserID", this.userID)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userID: this.userID,
      selectedGroupIDs: selectedGroupIDs
    };
    const dialogRef = this.dialog.open(ManageaccessmessageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Save') {
          this.getUserAccess()
          this.hasDeleted = true;
          this.successMessage = "Access revoke request processed sucessfully.This action will take a minute to reflect in below list"
          this.selection.clear()
          
        }

        else if (userResponse == 'Failed') {
        }
        else {

        }
      }
    );

    


  }
}
