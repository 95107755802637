import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Ng2CsvModule } from 'ng2csv';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyMaterialModule } from '../material/material.module';
import { NavigationComponent } from './navigation/navigation.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './login/login.component'
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { ResourcesComponent } from './resources/resources.component';
import { AwslistComponent } from './awslist/awslist.component';
import { AwsaccessrequestComponent } from './awsaccessrequest/awsaccessrequest.component';
import { ActionrequestsComponent } from './actionrequests/actionrequests.component';
import { AwsaccessgrantComponent } from './awsaccessgrant/awsaccessgrant.component';
import { AwsaccessauditComponent } from './awsaccessaudit/awsaccessaudit.component';
import { AwsaccountsnapshotComponent } from './awsaccountsnapshot/awsaccountsnapshot.component';
import { AwseditComponent } from './awsedit/awsedit.component';
import { AwsuseropenrequestsComponent } from './awsuseropenrequests/awsuseropenrequests.component';
import { SendemailsComponent } from './sendemails/sendemails.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AwsaddComponent } from './awsadd/awsadd.component';
import { AwsissuesComponent } from './awsissues/awsissues.component';
import { FooterComponent } from './footer/footer.component';
import { DocumentsComponent } from './documents/documents.component';
import { ManagedocumentsComponent } from './managedocuments/managedocuments.component';
import { AwsinventoryComponent } from './awsinventory/awsinventory.component';
import { ChartistModule } from 'ng-chartist';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {
  AsyncChartComponent,
  DynamicChartComponent,
  LiveChartComponent,
  TooltipChartComponent
} from './awsinventory/components';
import { OcieditComponent } from './ociedit/ociedit.component';
import { OciaccessauditComponent } from './ociaccessaudit/ociaccessaudit.component';
import { AuditaccessComponent } from './auditaccess/auditaccess.component';
import { AuditaccessdataComponent } from './auditaccessdata/auditaccessdata.component';
import { DelegaterequestsComponent } from './delegaterequests/delegaterequests.component';
import { AwssupportrequestsComponent } from './awssupportrequests/awssupportrequests.component';
import { UseraccessforcloudComponent } from './useraccessforcloud/useraccessforcloud.component';
import { RequestazureaccessComponent } from './requestazureaccess/requestazureaccess.component';
import { AzureeditComponent } from './azureedit/azureedit.component';
import { AzurepimComponent } from './azurepim/azurepim.component';
import { AzuremgaccessComponent } from './azuremgaccess/azuremgaccess.component';
import { PimstgpageComponent } from './pimstgpage/pimstgpage.component';
import { AzureissuesComponent } from './azureissues/azureissues.component';
import { DeclineaccessrequestComponent } from './declineaccessrequest/declineaccessrequest.component';
import { ManageaccessmessageComponent } from './manageaccessmessage/manageaccessmessage.component';
import { PmcdataComponent } from './pmcdata/pmcdata.component';
import { PmcrequestaccessComponent } from './pmcrequestaccess/pmcrequestaccess.component';
import { SavingsdashboardComponent } from './savingsdashboard/savingsdashboard.component';
import { RequestazureadssoComponent } from './requestazureadsso/requestazureadsso.component';
import { AzuressorequestsComponent } from './azuressorequests/azuressorequests.component';
import { AzureadssoactionrequestsComponent } from './azureadssoactionrequests/azureadssoactionrequests.component';
import { AadactionsdialogComponent } from './aadactionsdialog/aadactionsdialog.component';
import { AzurekeyComponent } from './azurekey/azurekey.component';
import { AzurekeyAuditComponent } from './azurekey-audit/azurekey-audit.component';
import { AwsmgaccessComponent } from './awsmgaccess/awsmgaccess.component';
const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'resources' , component: ResourcesComponent },
  { path: 'login', component: LoginComponent },
  { path: 'auth' , component: AuthComponent },
  { path: 'cloudlist' , component: AwslistComponent },
  { path: 'awsaccessrequest' , component: AwsaccessrequestComponent },
  { path: 'actionrequests', component: ActionrequestsComponent },
  { path: 'grantaccess' , component: AwsaccessgrantComponent },
  { path: 'awsaccessaudit' , component: AwsaccessauditComponent },
  { path: 'awsaccountsnapshot' , component: AwsaccountsnapshotComponent },
  { path: 'awsedit' , component: AwseditComponent },
  { path: 'cloudopenrequests' , component: AwsuseropenrequestsComponent },
  { path: 'contact' , component: SendemailsComponent },
  { path: 'feedback' , component: FeedbackComponent },
  { path: 'awsadd' , component: AwsaddComponent },
  { path: 'awsissues' , component: AwsissuesComponent },
  { path: 'listdocs' , component: DocumentsComponent },
  { path: 'managedocs' , component: ManagedocumentsComponent },
  { path: 'awsinventory' , component: AwsinventoryComponent },
  { path: 'ociedit' , component: OcieditComponent },
  { path: 'ociaccessaudit' , component: OciaccessauditComponent },
  { path: 'auditaccess' , component: AuditaccessComponent },
  { path: 'auditaccessdata' , component: AuditaccessdataComponent },
  { path: 'delegaterequests' , component: DelegaterequestsComponent },
  { path: 'awstickets' , component: AwssupportrequestsComponent },
  { path: 'usercloudaccess' , component: UseraccessforcloudComponent },
  { path: 'azureaccess' , component: RequestazureaccessComponent },
  { path: 'azureedit' , component: AzureeditComponent },
  { path: 'azurepim' , component: AzurepimComponent },
  { path: 'azuremgaccess' , component: AzuremgaccessComponent },
  { path: 'awsmgaccess' , component: AwsmgaccessComponent },
  { path: 'pimstg' , component: PimstgpageComponent },
  { path: 'azureissues' , component: AzureissuesComponent },
  { path: 'denyrequest' , component: DeclineaccessrequestComponent },
  { path: 'manageaccessalert' , component: ManageaccessmessageComponent },
  { path: 'pmcdata' , component: PmcdataComponent },
  { path: 'pmcaccess' , component: PmcrequestaccessComponent },
  { path: 'savingsdashboard' , component: SavingsdashboardComponent },
  { path: 'reqazuresso' , component: RequestazureadssoComponent },
  { path: 'azuressorequests' , component: AzuressorequestsComponent },
  { path: 'aadssorequests' , component: AzureadssoactionrequestsComponent },
  { path: 'aadssodialogs' , component: AadactionsdialogComponent },
  { path: 'azurekey' , component: AzurekeyComponent },
  { path: 'azurekeyaudit' , component: AzureeditComponent }


  

  //{ path: 'home', redirectTo: 'https://w3.gdc-rad.com' },
  //{ path: '**', redirectTo: ''}
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    AuthComponent,
    LoginComponent,
    AppComponent,
    NavigationComponent,
    LoginComponent,
    ResourcesComponent,
    AwslistComponent,
    AwsaccessrequestComponent,
    ActionrequestsComponent,
    AwsaccessgrantComponent,
    AwsaccessauditComponent,
    AwsaccountsnapshotComponent,
    AwseditComponent,
    AwsuseropenrequestsComponent,
    SendemailsComponent,
    FeedbackComponent,
    AwsaddComponent,
    AwsissuesComponent,
    FooterComponent,
    DocumentsComponent,
    ManagedocumentsComponent,
    AwsinventoryComponent,
    AsyncChartComponent,
    DynamicChartComponent,
    LiveChartComponent,
    TooltipChartComponent,
    OcieditComponent,
    OciaccessauditComponent,
    AuditaccessComponent,
    AuditaccessdataComponent,
    DelegaterequestsComponent,
    AwssupportrequestsComponent,
    UseraccessforcloudComponent,
    RequestazureaccessComponent,
    AzureeditComponent,
    AzurepimComponent,
    AzuremgaccessComponent,
    PimstgpageComponent,
    AzureissuesComponent,
    DeclineaccessrequestComponent,
    ManageaccessmessageComponent,
    PmcdataComponent,
    PmcrequestaccessComponent,
    SavingsdashboardComponent,
    RequestazureadssoComponent,
    AzuressorequestsComponent,
    AzureadssoactionrequestsComponent,
    AadactionsdialogComponent,
    AzurekeyComponent,
    AzurekeyAuditComponent,
    AwsmgaccessComponent

  ],
  entryComponents: [AzurekeyAuditComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    FormsModule,
    ClipboardModule,
    ReactiveFormsModule,
    MyMaterialModule,
    HttpClientModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    FlexLayoutModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCarouselModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTreeModule,
    Ng2CsvModule,
    ChartistModule,
    MatAutocompleteModule
  ],
  
  providers: [AuthService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
