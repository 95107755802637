import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAuditInput } from './../interface/accessAuditInput';
import { RepositoryService } from './../service/repository.service';
import { IAWSGroupAudit } from './../interface/awsgroupaudit';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';
import { MatTable } from '@angular/material/table';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-auditaccessdata',
  templateUrl: './auditaccessdata.component.html',
  styleUrls: ['./auditaccessdata.component.css']
})
export class AuditaccessdataComponent implements OnInit {
  local_data: any;
  noRecords: boolean = false;
  awsGroupAudit: IAWSGroupAudit[] = [];
  awsGroupAuditTableDataSource = new MatTableDataSource<IAWSGroupAudit>(this.awsGroupAudit);
  filterVal = '';
  loading = true;
  pageSize = 5;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  displayedColumns: string[] = ['approverID', 'requestorName', 'statusComments', 'updateTime', 'requestType'];
  auditGroupName = '';
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(DOCUMENT) private document: any, private rs: RepositoryService, public dialogRef: MatDialogRef<AuditaccessdataComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAuditInput) {
    this.local_data = data;
    //console.log("data in dialog:::::::::" + JSON.stringify(data));
    //console.table(this.local_data);
    //console.log("data in dialog:::::::::" + data['userID']);
    //console.log("data in dialog:::::::::" + data['groupName']);
    //console.log("data in dialog:::::::::" + data['groupID']);
    //console.log("data in dialog:::::::::" + data['userType']);

  }

  ngOnInit() {
    this.auditGroupName = this.local_data['groupName']
    this.rs.getAuditforAWSAccess(this.local_data['groupID'], this.local_data['userID'], this.local_data['userType']).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("Error") == -1) {
        //console.log('Success');
        if (res['Items'].length > 0) {
          this.awsGroupAudit = res['Items'];
          this.awsGroupAuditTableDataSource.data = this.awsGroupAudit;
          this.loading = false;
          this.showMsg = true;
          this.noRecords = false;
        }
        else{
          this.noRecords = true;
        }

      }
      else {
        this.showErrMsg = true;
      }


    })
    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }
  ngAfterViewInit() {
    this.awsGroupAuditTableDataSource.paginator = this.paginator,
    this.awsGroupAuditTableDataSource.sort = this.sort

  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  applyFilter(filterValue: string) {
    this.awsGroupAuditTableDataSource.filter = filterValue.trim().toLowerCase();
  }
}
