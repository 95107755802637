import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { AuditaccessdataComponent } from './../auditaccessdata/auditaccessdata.component';
import { Router } from '@angular/router';
import { IAWSGroupAudit } from './../interface/awsgroupaudit';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { environment } from './../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatRadioChange } from '@angular/material/radio';
@Component({
  selector: 'app-auditaccess',
  templateUrl: './auditaccess.component.html',
  styleUrls: ['./auditaccess.component.css']
})
export class AuditaccessComponent implements OnInit {
  awsList: any
  ociList: any
  azureList: any
  awsGroups: any
  ociGroups: any
  azureGroups: any
  auditFormAWS: FormGroup;
  auditFormOCI: FormGroup;
  auditFormAzure: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  hasRecords: boolean = true;
  accessReposponse: string;
  selectAccessForm: FormGroup;
  resourceTypes: string[] = ['Management Group', 'Subscription', 'Resource Group','Azure Resources'];
  showSubscription: boolean = false;
  showMG: boolean = false;
  showRG: boolean = false;
  groupScope: string;
  showRGIAM: boolean = false;

  awsGroupAudit: IAWSGroupAudit[] = [];
  awsGroupAuditTableDataSource = new MatTableDataSource<IAWSGroupAudit>(this.awsGroupAudit);
  filterVal = '';
  loading = true;
  pageSize = 5;

  formGroupDirective: FormGroupDirective;
  constructor(@Inject(DOCUMENT) private document: any, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private auth: AuthService, private rs: RepositoryService, private router: Router, private fb: FormBuilder, private dialog: MatDialog) {
    this.matIconRegistry.addSvgIcon(
      "aws-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Amazon_Web_Services_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "azure-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Azure_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "oci-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/OCI_Logo.svg")
    );
  }

  ngOnInit() {


    this.getAllAWSAccounts();
    this.selectAccessForm = this.fb.group({
      resourceType: ['Subscription', [Validators.required]],
      groupScope: ['Subscription', [Validators.required]]
    });
    this.auditFormAWS = this.fb.group({
      aws_account: ['', [Validators.required]],
      aws_role: ['', [Validators.required]],
      wwids: ['', [Validators.maxLength(5)]]
    });

    this.auditFormOCI = this.fb.group({
      oci_account: ['', [Validators.required]],
      oci_role: ['', [Validators.required]],
      oci_wwids: ['', [Validators.maxLength(5)]]
    });
    this.auditFormAzure = this.fb.group({
      azure_account: ['', [Validators.required]],
      azure_role: ['', [Validators.required]],
      azure_wwids: ['', [Validators.maxLength(5)]]
    });
    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  _setDataSource(indexNumber) {
    //console.log("indexNumber:", indexNumber)
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.loading = true;
          this.getAllAWSAccounts();
          break;
        case 1:
          this.loading = true;
          this.getAllOCIAccounts();

          break;
        case 2:
          this.showMsg = false;
          this.showErrMsg = false;
          let selectedValue  = "";
          //console.log("Checked Value::", this.selectAccessForm.getRawValue()['groupScope'])
          if (this.selectAccessForm.getRawValue()['groupScope'] === "ManagementGroup") {
            this.showSubscription = false;
            this.showMG = true;
            this.showRG = false;
            this.showRGIAM = false;
            this.auditFormAzure.markAsUntouched();
            this.auditFormAzure.controls.azure_wwids.setValue("");
            this.auditFormAzure.controls.azure_role.setValue("");
            selectedValue = "Management Group";
          }
          else if (this.selectAccessForm.getRawValue()['groupScope'] === "ResourceGroup") {
            this.showSubscription = false;
            this.showMG = false;
            this.showRG = true;
            this.showRGIAM = false;
            this.auditFormAzure.markAsUntouched();
            this.auditFormAzure.controls.azure_wwids.setValue("");
            this.auditFormAzure.controls.azure_role.setValue("");
            selectedValue = "Resource Group";
          }
          else if (this.selectAccessForm.getRawValue()['groupScope'] === "AzureResource") {
            this.showSubscription = false;
            this.showMG = false;
            this.showRG = false;
            this.showRGIAM = true;
            this.auditFormAzure.markAsUntouched();
            this.auditFormAzure.controls.azure_wwids.setValue("");
            this.auditFormAzure.controls.azure_role.setValue("");
            selectedValue = "AzureResource";
          }
          else {
            this.showSubscription = true;
            this.showMG = false;
            this.showRG = false;
            this.showRGIAM = false;
            this.auditFormAzure.markAsUntouched();
            this.auditFormAzure.controls.azure_wwids.setValue("");
            this.auditFormAzure.controls.azure_role.setValue("");
            selectedValue = "Subscription";
          }
          this.getAllAzureAccounts(selectedValue);
        //!this.ociCompartmentTableDataSource.paginator ? this.ociCompartmentTableDataSource.paginator = this.paginatorOCI : null;
        //this.ociCompartmentTableDataSource.sort = this.sortOCI;
      }
    });
  }
  getAllAWSAccounts() {
    try {
      this.rs.getAWSAccounts(sessionStorage.getItem("wwid"), "List").subscribe(res => {
        //console.log("res['Items']",res['Items'].length);
        if (res['Items'].length > 0) {
          this.awsList = res['Items'];
          this.hasRecords = true;
        }
        else {
          this.hasRecords = false;
          this.accessReposponse = 'You are not owner of AWS Accounts. You cannot audit access'
        }
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }

  getAllOCIAccounts() {
    try {
      this.rs.getOCIAccounts(sessionStorage.getItem("wwid"), "List").subscribe(res => {
        //console.log("res['Items']",res['Items'].length);
        if (res['Items'].length > 0) {
          this.ociList = res['Items'];
          this.hasRecords = true;
        }
        else {
          this.hasRecords = false;
          this.accessReposponse = 'You are not owner of OCI Accounts. You cannot audit access'
        }
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }
  getAllAzureAccounts(azureResourceType: string) {

    try {
      this.rs.getAzureAccounts(sessionStorage.getItem("wwid"), "List", azureResourceType).subscribe(res => {
        //console.log(res['Items']);
        if (res['Items'].length > 0) {
          this.azureList = res['Items'];
          this.hasRecords = true;
        }
        else {
          this.hasRecords = false;
          this.accessReposponse = 'You are not owner of Azure Accounts. You cannot grant access to users.'

        }
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }
  get f() {
    return this.auditFormAWS.controls;
  }
  get aws_role() {
    return this.auditFormAWS.get('aws_role');
  }
  get wwids() {
    return this.auditFormAWS.get('wwids');
  }


  get ocif() {
    return this.auditFormOCI.controls;
  }
  get oci_role() {
    return this.auditFormOCI.get('oci_role');
  }
  get oci_wwids() {
    return this.auditFormOCI.get('oci_wwids');
  }
  get azuref() {
    return this.auditFormAzure.controls;
  }
  get azure_wwids() {
    return this.auditFormAzure.get('azure_wwids');
  }
  get azure_role() {
    return this.auditFormAzure.get('azure_role');
  }
  onSelectionChange(event: MatRadioChange) {
    //console.log(event.value)
    if (event.value == "Subscription") {
      this.showSubscription = true;
      this.showMG = false;
      this.showRG = false;
      this.showRGIAM = false;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("Subscription");
      this.auditFormAzure.markAsUntouched();
      this.auditFormAzure.controls.azure_wwids.setValue("");
      this.auditFormAzure.controls.azure_role.setValue("");
      this.getAllAzureAccounts(event.value)
    }
    else if (event.value == "Management Group") {
      this.showSubscription = false;
      this.showMG = true;
      this.showRG = false;
      this.showRGIAM = false;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("ManagementGroup");
      this.auditFormAzure.markAsUntouched();
      this.auditFormAzure.controls.azure_wwids.setValue("");
      this.auditFormAzure.controls.azure_role.setValue("");
      this.getAllAzureAccounts(event.value)
    }
    else if (event.value == "Resource Group") {
      this.showSubscription = false;
      this.showMG = false;
      this.showRG = true;
      this.showRGIAM = false;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("ResourceGroup");
      this.auditFormAzure.markAsUntouched();
      this.auditFormAzure.controls.azure_wwids.setValue("");
      this.auditFormAzure.controls.azure_role.setValue("");
      this.getAllAzureAccounts(event.value)

    }
    else {
      this.showSubscription = false;
      this.showMG = false;
      this.showRG = false;
      this.showRGIAM = true;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("AzureResource");
      this.auditFormAzure.markAsUntouched();
      //need to check
      this.auditFormAzure.controls.azure_wwids.setValue("");
      this.auditFormAzure.controls.azure_role.setValue("");
      //Up Need to check
      this.getAllAzureAccounts("AzureResource")

    }
  }
  awsLevelChangeAction(data: string) {
    //console.table('data: ' + JSON.stringify(data));
    let awsselected: string = data['azureADRef'];
    //console.log('awsselected: ' + awsselected);
    this.rs.getAWSAccountGroups(awsselected).subscribe(res => {
      this.awsGroups = res['value'];
      //console.log('awsGroups: ' + this.awsGroups);
    })
  }

  ociLevelChangeAction(data: string) {
    //console.table('data: ' + JSON.stringify(data));
    let oci_azureADRef: string = data['azureADRef'];
    let oci_selected: string = data['accountName'];
    if (oci_selected.includes("(root)")) {
      oci_selected = oci_selected.substr(0, oci_selected.indexOf(' '));
    }
    //console.log('oci_azureADRef: ' + oci_azureADRef);
    //console.log('oci_selected: ' + oci_selected);
    this.rs.getOCICompartmentGroups(oci_azureADRef, oci_selected).subscribe(res => {
      this.ociGroups = res['value'];
      //console.log('awsGroups: ' + this.awsGroups);
    })
  }
  azureLevelChangeAction(data: string) {
    //console.table('data: ' + JSON.stringify(data));
    let groupScopeValue = this.selectAccessForm.getRawValue()['groupScope'];
    let resourceGroupID = "";
    let subscriptionID = "";
    let resourceID = "";
    let managementGroupID = "";
    let departmentName = data['department'];
    if (groupScopeValue === "Subscription") {
      resourceGroupID = "NA";
      resourceID = "NA";
      subscriptionID = data['subscriptionID'];
      managementGroupID = data['mainAccount'];
    }
    else if (groupScopeValue === "ManagementGroup") {
      resourceGroupID = "NA";
      subscriptionID = "NA";
      resourceID = "NA";
      managementGroupID = data['MGName'];
      if (managementGroupID === "CBS-IT" || managementGroupID === "Non_CBS_IT" || managementGroupID === "CBS-IT-CN-Legacy" || managementGroupID === "CBS-IT-CN"  ){
        groupScopeValue = "RootManagementGroup";
      }
    }
    else if (groupScopeValue === "AzureResource"){
      resourceGroupID = data['parentResource'];
      subscriptionID = data['subscriptionID'];
      resourceID = data['scope'];
      managementGroupID = "NA";
      departmentName = "CBS-IT"
    }
    else {
      resourceID = "NA";
      resourceGroupID = data['resourceGroupName'];
      subscriptionID = data['subscriptionID'];
      managementGroupID = "NA";
    }

    //console.log('departmentName: ' + departmentName);
    //console.log('groupScope: ' + groupScopeValue);
    //console.log('resourceGroupID: ' + resourceGroupID);
    //console.log('subscriptionID: ' + subscriptionID);
    //console.log('managementGroupID: ' + managementGroupID);
    try {
      this.rs.getAzureGroups(groupScopeValue, resourceGroupID, subscriptionID, managementGroupID,resourceID,departmentName).subscribe(res => {
        this.azureGroups = res['value'];
        //console.log('azureGroups: ' + this.azureGroups);
      })
    }
    catch (error) {
      //console.log("error message:" + error['message']);
    }

  }
  getAudit(clouldType: string) {
    this.submitted = true;
    let userID = "";
    let groupID = "";
    let userType = 'owner';
    //console.log("clouldType:", clouldType);
    if (clouldType == 'AWS') {
      if (this.auditFormAWS.invalid) {
        return;
      }
      userID = this.auditFormAWS.value['wwids'];
      groupID = this.auditFormAWS.value['aws_role'].principalId;
    }
    else {
      if (this.auditFormOCI.invalid) {
        return;
      }
      userID = this.auditFormOCI.value['oci_wwids'];
      groupID = this.auditFormOCI.value['oci_role'].principalId;
    }


    //console.log(JSON.stringify(this.requestAccessForm.value));
    //console.table(this.grantAccessForm.value);


    if (userID === '') {
      userID = 'None';
    }
    //console.log('userID: ' + userID);
    //console.log('userID: ' + userID);
    //console.log('groupID: ' + groupID);
    //console.log('userType: ' + userType);
    this.rs.getAuditforAWSAccess(groupID, userID, userType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("Error") == -1) {
        //console.log('Success');
        this.awsGroupAudit = res['Items'];
        this.awsGroupAuditTableDataSource.data = this.awsGroupAudit;
        this.loading = false;
        this.showMsg = true;
        //this.resetEverything();
      }
      else {
        this.showErrMsg = true;
      }

    })

  }
  getAuditData(cloudType: string) {
    this.submitted = true;
    let userID = "";
    let groupID = "";
    let userType = 'owner';
    let groupName = "";
    const dialogConfig = new MatDialogConfig();
    let dialogRef;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    //console.log("auditFormAWS:::::",this.auditFormAWS)
    //console.log("clouldType:",cloudType);
    if (cloudType == 'AWS') {
      if (this.auditFormAWS.invalid) {
        return;
      }
      userID = this.auditFormAWS.value['wwids'];
      groupID = this.auditFormAWS.value['aws_role'].principalId;
      groupName = this.auditFormAWS.value['aws_role'].principalDisplayName;
      if (userID === '') {
        userID = 'None';
      }
      dialogConfig.data = {
        userID: userID,
        groupID: groupID,
        cloudType: cloudType,
        userType: userType,
        groupName: groupName
      };
    }
    else if (cloudType == 'Azure') {
      if (this.auditFormAzure.invalid) {
        return;
      }
      userID = this.auditFormAzure.value['azure_wwids'];
      groupID = this.auditFormAzure.value['azure_role'].id;
      groupName = this.auditFormAzure.value['azure_role'].displayName;
      if (userID === '') {
        userID = 'None';
      }
      dialogConfig.data = {
        userID: userID,
        groupID: groupID,
        cloudType: cloudType,
        userType: userType,
        groupName: groupName
      };
    }
    else {
      if (this.auditFormOCI.invalid) {
        return;
      }
      userID = this.auditFormOCI.value['oci_wwids'];
      groupID = this.auditFormOCI.value['oci_role'].principalId;
      groupName = this.auditFormOCI.value['oci_role'].principalDisplayName;

      if (userID === '') {
        userID = 'None';
      }
      dialogConfig.data = {
        userID: userID,
        groupID: groupID,
        cloudType: cloudType,
        userType: userType,
        groupName: groupName
      };
    }

    dialogRef = this.dialog.open(AuditaccessdataComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
      }
    );
  }

}
