import { IAWSAccessRequests } from './../interface/awsaccessrequests';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from './../service/repository.service';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DelegaterequestsComponent } from '../delegaterequests/delegaterequests.component';
import { DeclineaccessrequestComponent } from '../declineaccessrequest/declineaccessrequest.component';

@Component({
  selector: 'app-actionrequests',
  templateUrl: './actionrequests.component.html',
  styleUrls: ['./actionrequests.component.css']
})
export class ActionrequestsComponent implements OnInit {
  awsRequestsTable: IAWSAccessRequests[] = [];
  requestsTableDataSource = new MatTableDataSource<IAWSAccessRequests>(this.awsRequestsTable);
  selection = new SelectionModel<IAWSAccessRequests>(true, []);
  loading = true;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  filterVal: string = '';
  hasRecords: boolean = true;
  accessReposponse: string;
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns: string[] = ['requestorName', 'accountName', 'groupName', 'comments', 'actions'];
  pageSize = 5;
  successMessage: string;
  errorMessage: string;



  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(DOCUMENT) private document: any,  private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,private auth: AuthService, private http: HttpClient, private rs: RepositoryService, private router: Router, private dialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) { 

    this.matIconRegistry.addSvgIcon(
      "request-forward",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/forward.svg")
    );
  }

  ngOnInit(): void {

    this.getPendingAccessRequests();

    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);
  }

  ngAfterViewInit() {
    this.requestsTableDataSource.paginator = this.paginator,
      this.requestsTableDataSource.sort = this.sort
  }

  getPendingAccessRequests() {
    try {
      this.rs.getAWSAccessRequests().subscribe(
        (res) => {
          //console.log("res['Items'].length:" + res['Items'].length);
          if (res['Items'].length > 0) {
            this.awsRequestsTable = res['Items'];
            this.requestsTableDataSource.data = this.awsRequestsTable;
            this.loading = false;
            this.hasRecords = true;
          }
          else {
            this.hasRecords = false;
            this.accessReposponse = 'No requests to action. You’re all caught up !'
          }
        },
        (error) => {
          this.showErrMsg = true;
          this.showMsg = false;
          //console.log("error message:" + error['message']);
          //console.log("error error:" + JSON.stringify(error));
        }
      )
    }
    catch (err) {
      console.log("error message:" + err['message']);

    }
  }
  applyFilter(filterValue: string) {
    this.requestsTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  actionRequest(action: string, accessRequests: string) {
    //console.log("accessRequests:" + accessRequests['requestID']);
    //console.table(accessRequests);
    
    let wwids = accessRequests['requestorID'];
    let groupID = accessRequests['groupID'];
    let groupName = accessRequests['groupName'];
    let accountName = accessRequests['accountName'];
    let accountIDAzureRef = accessRequests['accountIDAzureRef'];
    let requestType = "SSR";
    let approverID = sessionStorage.getItem("wwid");
    let requestID = accessRequests['requestID'];
    let additionalApprovers = accessRequests['additionalApprovers'];
    let requestorName = accessRequests['requestorName'];
    let delegatedUserID = "None";
    let comments = "None";



    this.rs.actionRequestsAWSAccess(wwids, groupID, groupName, accountName, accountIDAzureRef, requestType, action, approverID, requestID, additionalApprovers, requestorName,delegatedUserID, comments).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("declined") == -1) {
        //console.log('Approved');
        //console.log(res['body']);
        this.accessReposponse = res['body']
        this.showMsg = true;
        this.showErrMsg = false;
        this.getPendingAccessRequests();
      }
      else {
        //console.log('Declined');
        this.showErrMsg = true;
        this.showMsg = false;
        this.errorMessage = "Error while processing the request. Please try again"
        this.getPendingAccessRequests();
      }

    })
  }

  actionRequestDeny(action: string, accessRequests: string) {
    const dialogConfig = new MatDialogConfig();
    //console.table(awsAccount);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userAction: action,
      accessRequests: accessRequests
    };
    const dialogRef = this.dialog.open(DeclineaccessrequestComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", data)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Save') {
          this.showMsg = true;
          this.showErrMsg = false;
          this.accessReposponse = "Sucessfully declined the request."
          this.getPendingAccessRequests();
          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
        }
        else if (userResponse == 'Failed') {
          this.showMsg = true;
          this.showErrMsg = false;
          this.accessReposponse = "Error while processing the request. Please try again"
          this.getPendingAccessRequests();
          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
        }
        else {
          //console.log("userResponse:::",userResponse)
          this.showErrMsg = false;
          this.showMsg = false;
          //this.getPendingAccessRequests();

        }
      }
    );
    
  }

  delegateRequest(action: string, accessRequests: string) {
    const dialogConfig = new MatDialogConfig();
    //console.table(awsAccount);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userAction: action,
      accessRequests: accessRequests
    };
    const dialogRef = this.dialog.open(DelegaterequestsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      dialogData => {
        //console.log("Dialog output:", data)
        //console.table(dialogData)
        let userResponse = dialogData['event'];
        //console.log("userResponse:", userResponse)
        if (userResponse == 'Save') {
          this.showMsg = true;
          this.showErrMsg = false;
          this.accessReposponse = "Sucessfully delegated the request."
          this.getPendingAccessRequests();
          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
        }
        else if (userResponse == 'Failed') {
          this.showMsg = true;
          this.showErrMsg = false;
          this.accessReposponse = "Error while processing the request. Please try again"
          this.getPendingAccessRequests();
          //console.log("userResponse:::",userResponse)
          //console.log("groupName:::",dialogData['groupName'])
          //console.log("accountName:::",dialogData['accountName'])
        }
        else {
          //console.log("userResponse:::",userResponse)
          this.showErrMsg = false;
          this.showMsg = false;
          //this.getPendingAccessRequests();

        }
      }
    );

  }


}

