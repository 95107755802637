import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAzureRecommendations } from './../interface/AzureRecommendations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepositoryService } from './../service/repository.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-azureissues',
  templateUrl: './azureissues.component.html',
  styleUrls: ['./azureissues.component.css']
})
export class AzureissuesComponent implements OnInit {
  local_data: any;
  action: string;
  accountName: string;
  accountID: string;
  secureScore: string;
  costIssues: any;
  rightSizeData:any;
  securityIssues: string[];
  loading = true;
  noRecords: boolean = false;
  azureAccountLink: string;
  showRightSizeRecommendations: boolean = false;
  showCostIssues: boolean = false;
  showSecurityIssues: boolean = false;

  azureTable: IAzureRecommendations[] = [];
  azureTableDataSource = new MatTableDataSource<IAzureRecommendations>(this.azureTable);
  selection = new SelectionModel<IAzureRecommendations>(true, []);
  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns: string[] = ['instanceID', 'currentType', 'targetType'];
  pageSize = 5;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public dialogRef: MatDialogRef<AzureissuesComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAzureRecommendations, private rs: RepositoryService) { 
    this.local_data = data;
    //console.table(this.local_data);

  }

  ngOnInit(): void {
    this.azureAccountLink = "https://portal.azure.com/#blade/Microsoft_Azure_Expert/AdvisorMenuBlade"
    this.action = this.local_data['dataType'];
    //console.log(this.action)
    this.accountName = this.local_data['azureAccountInfo']['subscriptionName']
    this.accountID = this.local_data['azureAccountInfo']['subscriptionID']
    this.secureScore = this.local_data['azureAccountInfo']['secureScore']

    if (this.action === "cost") {
      this.costIssues = this.local_data['azureAccountInfo']['errorReport']
      //this.accountIssues = this.accountIssues.filter(item => item !== "MFA on Root Account");
      this.showRightSizeRecommendations = false;
      this.showCostIssues = true;
      this.showSecurityIssues = false;
      this.noRecords = false;
    
    }
    else if (this.action === "security") {
      this.securityIssues = this.local_data['azureAccountInfo']['warningReport']
      this.securityIssues = this.securityIssues.filter(obj => obj !== "NA");

      this.showRightSizeRecommendations = false;
      this.showCostIssues = false;
      this.showSecurityIssues = true;
      this.noRecords = false;
     
    }
    else {
      this.azureTable = this.local_data['azureAccountInfo']['rightSize']
      this.azureTableDataSource.data = this.azureTable;
      this.loading = false;
      //this.rightSizeData = this.local_data['azureAccountInfo']['rightSize']
      //console.log(this.azureTable.length)
      if (this.azureTable.length == undefined || this.azureTable.length == 0){
        //console.log("inside")
        this.noRecords = true;
        this.showRightSizeRecommendations = false;
        this.showCostIssues = false;
        this.showSecurityIssues = false;
      }
      else{
        this.noRecords = false;
        this.showRightSizeRecommendations = true;
        this.showCostIssues = false;
        this.showSecurityIssues = false;
      }
     
    
    
    }

  }
  goToLink(url: string){
    window.open(url, "_blank");
  } 
  closeDialog() {
    this.dialogRef.close({ event: 'Close' });
  }

}
