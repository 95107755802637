import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { IAWSAccessRequests } from '../interface/awsaccessrequests';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-delegaterequests',
  templateUrl: './delegaterequests.component.html',
  styleUrls: ['./delegaterequests.component.css']
})
export class DelegaterequestsComponent implements OnInit {
  delegateAccessForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  local_data: any;
  disableButton = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  // Tetsing code 

  searchUserCtrl = new FormControl();
  filteredUser: any;
  isLoading = false;
  errorMsg: string;


  /// testing code




  constructor(@Inject(DOCUMENT) private document: any, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccessRequests, public dialogRef: MatDialogRef<DelegaterequestsComponent>, private http: HttpClient, private rs: RepositoryService, private router: Router, private fb: FormBuilder) {
    this.local_data = data;
    //console.table(this.local_data);
    //console.log(this.local_data['accessRequests']['groupName'])
  }
  ngOnInit() {
    this.delegateAccessForm = this.fb.group({
      cloudAccount: [{ value: this.local_data['accessRequests']['accountName'], disabled: true }],
      groupName: [{ value: this.local_data['accessRequests']['groupName'], disabled: true }],
      accountIDAzureRef: [{ value: this.local_data['accessRequests']['accountIDAzureRef'], disabled: true }],
      groupID: [{ value: this.local_data['accessRequests']['groupID'], disabled: true }],
      requestID: [{ value: this.local_data['accessRequests']['requestID'], disabled: true }],
      additionalApprovers: [{ value: this.local_data['accessRequests']['additionalApprovers'], disabled: true }],
      requestorID: [{ value: this.local_data['accessRequests']['requestorID'], disabled: true }],
      requestorName: [{ value: this.local_data['accessRequests']['requestorName'], disabled: true }],
      comments: [{ value: this.local_data['accessRequests']['comments'], disabled: true }],
      //approverID: [{ value: sessionStorage.getItem("wwid"), disabled : true }],
      wwid: ['', [Validators.required, Validators.maxLength(5),Validators.minLength(5)]],





      //approverID : [{ value: this.local_data['awsAccountInfo']['accountOwners'], disabled: true }],
      //accountID : [{ value: this.local_data['awsAccountInfo']['id'], disabled: true }],
      // accountIDAzureRef : [{ value: this.local_data['awsAccountInfo']['azureADRef'], disabled: true }],
      //requestorID : [{ value:  sessionStorage.getItem('wwid'), disabled: true }],
      // requestorName : [{ value:  sessionStorage.getItem('Username'), disabled: true }],
      //requestcloudType : [{ value:  cloudType, disabled: true }]

    });

    this.onChanges();
    /*
    this.searchUserCtrl.valueChanges
    .pipe(
      debounceTime(500),
      tap(() => {
        this.errorMsg = "";
        this.filteredUser = [];
        this.isLoading = true;
      }),
      switchMap(value => this.rs.getUserDetails(value)
        .pipe(
          finalize(() => {
            this.isLoading = false
          }),
        )
      )
    )
    .subscribe(data => {
      //console.log(data)
      if (data['value'] == undefined) {
        this.errorMsg = data['Error'];
        this.filteredUser = [];
      } else {
        this.errorMsg = "";
        this.filteredUser = data['value'];
      }

      //console.log(this.filteredUser);
    });
 */

  }

  onChanges() {
    this.delegateAccessForm.valueChanges.subscribe(userInput => {
      
      //console.log(userInput['wwid']);
      //if (userInput['wwid'].length > 3) {
        this.isLoading = true;
        this.rs.getUserDetails(userInput['wwid']).subscribe(usersList => {
          //console.log('usersList: ' + JSON.stringify(usersList));
          if (usersList['value'] == undefined) {
            this.errorMsg = usersList['Error'];
            this.filteredUser = [];
          } else {
            this.isLoading = false
            this.errorMsg = "";
            this.filteredUser = usersList['value'];
          }
        })
      //}

    });
  }
  get f() {
    return this.delegateAccessForm.controls;
  }
  get wwid() {
    return this.delegateAccessForm.get('wwid');
  }
  get cloud_account() {
    return this.delegateAccessForm.get('cloudAccount');
  }
  get cloud_role() {
    return this.delegateAccessForm.get('groupName').value;
  }



  delegateRequest() {
    this.submitted = true;
    this.disableButton = true;
    if (this.delegateAccessForm.invalid) {
      return;
    }
    this.delegateAccessForm.getRawValue()
    //console.table("accessRequests:" + JSON.stringify(this.delegateAccessForm.getRawValue()));


    let wwids = this.delegateAccessForm.getRawValue()['requestorID'];
    let groupID = this.delegateAccessForm.getRawValue()['groupID'];
    let groupName = this.delegateAccessForm.getRawValue()['groupName'];
    let accountName = this.delegateAccessForm.getRawValue()['cloudAccount'];
    let accountIDAzureRef = this.delegateAccessForm.getRawValue()['accountIDAzureRef'];
    let requestType = "SSR";
    let action = "delegate";
    let approverID = sessionStorage.getItem("wwid");
    let requestID = this.delegateAccessForm.getRawValue()['requestID'];
    let additionalApprovers = this.delegateAccessForm.getRawValue()['additionalApprovers'];
    let requestorName = this.delegateAccessForm.getRawValue()['requestorName'];
    let delegatedUserID = this.delegateAccessForm.getRawValue()['wwid'];
    let comments = this.delegateAccessForm.getRawValue()['comments'];
    //console.log("delegatedUserID:" + delegatedUserID);


    this.rs.actionRequestsAWSAccess(wwids, groupID, groupName, accountName, accountIDAzureRef, requestType, action, approverID, requestID, additionalApprovers, requestorName, delegatedUserID, comments).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("declined") == -1) {
        //console.log('Approved');
        //console.log(res['body']);
        this.showMsg = true;
        this.showErrMsg = false;
        this.dialogRef.close({ event: 'Save' });
      }
      else {
        //console.log('Declined');
        this.showErrMsg = true;
        this.showMsg = false;
        this.dialogRef.close({ event: 'Falied' });
      }

    })
  }
  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}
