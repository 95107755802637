import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatRadioChange } from '@angular/material/radio';
@Component({
  selector: 'app-awsaccessgrant',
  templateUrl: './awsaccessgrant.component.html',
  styleUrls: ['./awsaccessgrant.component.css']
})
export class AwsaccessgrantComponent implements OnInit {
  awsList: any
  awsGroups: any
  ociList: any
  ociGroups: any
  azureList: any
  azureGroups: any
  grantAccessFormAWS: FormGroup;
  grantAccessFormOCI: FormGroup;
  grantAccessFormAzure: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  accessReposponse: string[];
  hasRecords: boolean = true;
  hasRecordsMessage: string;
  disableButton: boolean;
  selectAccessForm: FormGroup;
  resourceTypes: string[] = ['Management Group', 'Subscription', 'Resource Group','Azure Resources'];
  showSubscription:boolean = false;
  showMG:boolean = false;
  showRG:boolean = false;
  showRGIAM: boolean = false;
  groupScope : string;
  constructor(@Inject(DOCUMENT) private document: any, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,private http: HttpClient, private rs: RepositoryService, private router: Router, private fb: FormBuilder) { 

    this.matIconRegistry.addSvgIcon(
      "aws-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Amazon_Web_Services_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "azure-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/Azure_Logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "oci-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/OCI_Logo.svg")
    );
  }


  ngOnInit() {
    this.disableButton = false;
 
    this.getAllAWSAccounts();

    this.selectAccessForm = this.fb.group({
      resourceType: ['Subscription', [Validators.required]],
      groupScope:['Subscription', [Validators.required]]
    });

    this.grantAccessFormAWS = this.fb.group({
      aws_account: ['', [Validators.required]],
      aws_role: ['', [Validators.required]],
      wwids: ['', [Validators.required, Validators.maxLength(30)]]
    });
    this.grantAccessFormOCI = this.fb.group({
      oci_account: ['', [Validators.required]],
      oci_role: ['', [Validators.required]],
      oci_wwids: ['', [Validators.required, Validators.maxLength(30)]]
    });
    this.grantAccessFormAzure = this.fb.group({
      azure_account: ['', [Validators.required]],
      azure_role: ['', [Validators.required]],
      azure_wwids: ['', [Validators.required, Validators.maxLength(30)]]
    });
  }

  _setDataSource(indexNumber) {
    //console.log("indexNumber:", indexNumber)
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.showMsg = false;
          this.showErrMsg = false;
          this.getAllAWSAccounts()
          break;
        case 1:
          this.showMsg = false;
          this.showErrMsg = false;
          this.getAllOCIAccounts();
          
          break;
        case 2:
          this.showMsg = false;
          this.showErrMsg = false;
         
          let selectedValue  = "";
          //console.log("Checked Value::", this.selectAccessForm.getRawValue()['groupScope'])
          if (this.selectAccessForm.getRawValue()['groupScope'] === "ManagementGroup") {
            this.showSubscription = false;
            this.showMG = true;
            this.showRG = false;
            this.showRGIAM = false;
            
            this.grantAccessFormAzure.markAsUntouched();
            this.grantAccessFormAzure.controls.azure_wwids.setValue("");
            this.grantAccessFormAzure.controls.azure_role.setValue("");
            selectedValue = "Management Group";
          }
          else if (this.selectAccessForm.getRawValue()['groupScope'] === "ResourceGroup") {
            this.showSubscription = false;
            this.showMG = false;
            this.showRG = true;
            this.showRGIAM = false;
            this.grantAccessFormAzure.markAsUntouched();
            this.grantAccessFormAzure.controls.azure_wwids.setValue("");
            this.grantAccessFormAzure.controls.azure_role.setValue("");
            selectedValue = "Resource Group";
          }
          else if (this.selectAccessForm.getRawValue()['groupScope'] === "AzureResource") {
            this.showSubscription = false;
            this.showMG = false;
            this.showRG = false;
            this.showRGIAM = true;
            this.grantAccessFormAzure.markAsUntouched();
            this.grantAccessFormAzure.controls.azure_wwids.setValue("");
            this.grantAccessFormAzure.controls.azure_role.setValue("");
            selectedValue = "AzureResource";
          }
          else {
            this.showSubscription = true;
            this.showMG = false;
            this.showRG = false;
            this.showRGIAM = false;
            this.grantAccessFormAzure.markAsUntouched();
            this.grantAccessFormAzure.controls.azure_wwids.setValue("");
            this.grantAccessFormAzure.controls.azure_role.setValue("");
            selectedValue = "Subscription";
          }
          this.getAllAzureAccounts(selectedValue);
        //!this.ociCompartmentTableDataSource.paginator ? this.ociCompartmentTableDataSource.paginator = this.paginatorOCI : null;
        //this.ociCompartmentTableDataSource.sort = this.sortOCI;
      }
    });
  }
  getAllAWSAccounts() {
    try {
      this.rs.getAWSAccounts(sessionStorage.getItem("wwid"), "List").subscribe(res => {
        //console.log("res['Items']", res['Items'].length);
        if (res['Items'].length > 0) {
          this.awsList = res['Items'];
          this.hasRecords = true;
        }
        else {
          this.hasRecords = false;
          this.hasRecordsMessage = 'You are not owner of AWS Accounts. You cannot grant access to users'
        }
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
  }
  
  getAllAzureAccounts(azureResourceType:string) {

    try{
    this.rs.getAzureAccounts(sessionStorage.getItem("wwid"), "List",azureResourceType).subscribe(res => {
      //console.log(res['Items']);
      if(res['Items'].length>0){
        this.azureList = res['Items'];
        this.hasRecords = true;
      }
      else{
        this.hasRecords = false;
        this.hasRecordsMessage = 'You are not owner of Azure Accounts. You cannot grant access to users'
      }
    })
  }
  catch (error){
    console.log("error message:" + error['message']);
  }
  }
  getAllOCIAccounts() {
    try{
    this.rs.getOCIAccounts(sessionStorage.getItem("wwid"), "List").subscribe(res => {
      //console.log("res['Items']",res['Items'].length);
      if(res['Items'].length>0){
        this.ociList = res['Items'];
        this.hasRecords = true;
      }
      else{
        this.hasRecords = false;
        this.hasRecordsMessage = 'You are not owner of OCI Accounts. You cannot grant access to users'
      }
    })
  }
  catch (error){
    console.log("error message:" + error['message']);
  }
  }
  get f() {
    return this.grantAccessFormAWS.controls;
  }
  get wwids() {
    return this.grantAccessFormAWS.get('wwids');
  }
  get ocif() {
    return this.grantAccessFormOCI.controls;
  }
  get oci_wwids() {
    return this.grantAccessFormOCI.get('oci_wwids');
  }
  get oci_role() {
    return this.grantAccessFormOCI.get('oci_role');
  }

  get azuref() {
    return this.grantAccessFormAzure.controls;
  }
  get azure_wwids() {
    return this.grantAccessFormAzure.get('azure_wwids');
  }
  get azure_role() {
    return this.grantAccessFormAzure.get('azure_role');
  }
  onSelectionChange(event: MatRadioChange) {
    //log(event.value)
    if (event.value == "Subscription") {
      this.showSubscription = true;
      this.showMG = false;
      this.showRG = false;
      this.showRGIAM = false;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("Subscription");
      this.grantAccessFormAzure.markAsUntouched();
      this.grantAccessFormAzure.controls.azure_wwids.setValue("");
      this.getAllAzureAccounts(event.value)
    }
    else if (event.value == "Management Group") {
      this.showSubscription = false;
      this.showMG = true;
      this.showRG = false;
      this.showRGIAM = false;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("ManagementGroup");
      this.grantAccessFormAzure.markAsUntouched();
      this.grantAccessFormAzure.controls.azure_wwids.setValue("");
      this.getAllAzureAccounts(event.value)
    }
    else if (event.value == "Resource Group") {
      this.showSubscription = false;
      this.showMG = false;
      this.showRG = true;
      this.showRGIAM = false;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("ResourceGroup");
      this.grantAccessFormAzure.markAsUntouched();
      //need to check
      this.grantAccessFormAzure.controls.azure_wwids.setValue("");
      //Up Need to check
      this.getAllAzureAccounts(event.value)

    }
    else {
      this.showSubscription = false;
      this.showMG = false;
      this.showRG = false;
      this.showRGIAM = true;
      this.azureGroups = [];
      this.showMsg = false;
      this.showErrMsg = false;
      this.selectAccessForm.controls.groupScope.setValue("AzureResource");
      this.grantAccessFormAzure.markAsUntouched();
      //need to check
      this.grantAccessFormAzure.controls.azure_wwids.setValue("");
      //Up Need to check
      this.getAllAzureAccounts("AzureResource")

    }

  }
  awsLevelChangeAction(data: string) {
    let awsselected: string = data['azureADRef'];
    //console.log('awsselected: ' + awsselected);
    this.rs.getAWSAccountGroups(awsselected).subscribe(res => {
      this.awsGroups = res['value'];
      //console.log('awsGroups: ' + this.awsGroups);
    })
  }
  ociLevelChangeAction(data: string) {
    //console.table('data: ' + data);
    let oci_azureADRef: string = data['azureADRef'];
    let oci_selected: string = data['accountName'];
    if(oci_selected.includes("(root)")){
      oci_selected = oci_selected.substr(0,oci_selected.indexOf(' '));
    }
    //console.log('oci_azureADRef: ' + oci_azureADRef);
    //console.log('oci_selected: ' + oci_selected);
    this.rs.getOCICompartmentGroups(oci_azureADRef, oci_selected).subscribe(res => {
      this.ociGroups = res['value'];
      //console.log('awsGroups: ' + this.awsGroups);
    })
  }
  azureLevelChangeAction(data: string) {
    //console.table('data: ' + JSON.stringify(data));
    //added on 5/3/2021 to fix clearing value when selection changes
    this.grantAccessFormAzure.markAsUntouched();
    this.azureGroups = [];
    this.grantAccessFormAzure.controls.azure_wwids.setValue("");
    //added on 5/3/2021 to fix clearing value when selection changes
    let groupScopeValue = this.selectAccessForm.getRawValue()['groupScope'];
    //console.log('groupScopeValue: ' + groupScopeValue);

    let resourceGroupID = "";
    let resourceID = "";
    let subscriptionID ="";
    let managementGroupID ="";
    let departmentName = data['department'];
    if (groupScopeValue === "Subscription"){
      resourceGroupID = "NA";
      subscriptionID = data['subscriptionID'];
      managementGroupID = data['mainAccount'];
      resourceID = "NA";
    }
    else if (groupScopeValue === "ManagementGroup"){
      resourceGroupID = "NA";
      subscriptionID = "NA";
      resourceID = "NA";
      managementGroupID = data['MGName'];
      if (managementGroupID === "CBS-IT" || managementGroupID === "Non_CBS_IT" || managementGroupID === "CBS-IT-CN-Legacy" || managementGroupID === "CBS-IT-CN"  ){
        groupScopeValue = "RootManagementGroup";
      }
    }
    else if (groupScopeValue === "AzureResource"){
      resourceGroupID = data['parentResource'];
      subscriptionID = data['subscriptionID'];
      resourceID = data['scope'];
      managementGroupID = "NA";
      departmentName = "CBS-IT"
    }
    else{
      resourceID = "NA";
      resourceGroupID = data['resourceGroupName'];
      subscriptionID = data['subscriptionID'];
      managementGroupID = "NA";
    }
    
    
    //console.log('groupScope: ' + groupScopeValue);
    //console.log('resourceGroupID: ' + resourceGroupID);
    //console.log('subscriptionID: ' + subscriptionID);
    //console.log('managementGroupID: ' + managementGroupID);
    try {
      this.rs.getAzureGroups(groupScopeValue, resourceGroupID, subscriptionID, managementGroupID,resourceID,departmentName).subscribe(res => {
        this.azureGroups = res['value'];
        //console.log('azureGroups: ' + this.azureGroups);
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
    
  }

  grantAccess(cloudType:string) {
    this.submitted = true;
    this.disableButton = true;
    let wwids = "";
    let groupID  = "";
    let groupName = "";
    let accountName = "";
    let accountIDAzureRef = "";
    let requestID = "";
    let requestType = "OIR";
    let action = "approved";
    let comments = "None";
    let approverID = sessionStorage.getItem("wwid");
    let additionalApprovers = "None";
    let requestorName = sessionStorage.getItem("Username");
    let delegatedUserID = "None";
    let groupScopeValue = this.selectAccessForm.getRawValue()['groupScope'];
    //console.log("groupScopeValue::",groupScopeValue);
    //console.log(JSON.stringify(this.requestAccessForm.value));
    //console.table(this.grantAccessFormAzure.value);
    if(cloudType == "AWS"){
      if (this.grantAccessFormAWS.invalid) {
        return;
      }
      wwids = this.grantAccessFormAWS.value['wwids'];
      groupID = this.grantAccessFormAWS.value['aws_role'].principalId;
      groupName = this.grantAccessFormAWS.value['aws_role'].principalDisplayName;
      accountName = this.grantAccessFormAWS.value['aws_account'].accountName;
      accountIDAzureRef = this.grantAccessFormAWS.value['aws_account'].azureADRef;
      requestID = cloudType;
    }
    else if(cloudType == "Azure"){
      //console.table(this.grantAccessFormAzure.value);
      if (this.grantAccessFormAzure.invalid) {
        return;
      }
      if (groupScopeValue === "Subscription"){
        accountName = this.grantAccessFormAzure.value['azure_account'].subscriptionName;
        accountIDAzureRef = this.grantAccessFormAzure.value['azure_account'].subscriptionID;
        requestID = "Azure Subscription";
      }
      else if (groupScopeValue === "ManagementGroup"){
        requestID = "Azure Management Group";
        accountName = this.grantAccessFormAzure.value['azure_account'].MGName;
        accountIDAzureRef = this.grantAccessFormAzure.value['azure_account'].MGName;
      }
      else if (groupScopeValue === "AzureResource"){
        requestID = "Azure Resources";
        accountName = /[^/]*$/.exec(this.grantAccessFormAzure.value['azure_account'].azureResource)[0] +" ("+this.grantAccessFormAzure.value['azure_account'].resourceGroup+")";
        accountIDAzureRef = /[^/]*$/.exec(this.grantAccessFormAzure.value['azure_account'].azureResource)[0];
      }
      else{
        requestID = "Azure Resource Group";
        accountName = this.grantAccessFormAzure.value['azure_account'].resourceGroupName;
        accountIDAzureRef = this.grantAccessFormAzure.value['azure_account'].resourceGroupName;
      }
      wwids = this.grantAccessFormAzure.value['azure_wwids'];
      groupID = this.grantAccessFormAzure.value['azure_role'].id;
      groupName = this.grantAccessFormAzure.value['azure_role'].displayName;
      
    }
    else{
      if (this.grantAccessFormOCI.invalid) {
        return;
      }
      wwids = this.grantAccessFormOCI.value['oci_wwids'];
      groupID = this.grantAccessFormOCI.value['oci_role'].principalId;
      groupName = this.grantAccessFormOCI.value['oci_role'].principalDisplayName;
      accountName = this.grantAccessFormOCI.value['oci_account'].accountName;
      accountIDAzureRef = this.grantAccessFormOCI.value['oci_account'].azureADRef;
      requestID = cloudType;
      
    }



    //console.log('wwids: ' + wwids);
    //console.log('approverID: ' + approverID);
    //console.log('accountID: ' + accountID);
    //console.log('accountIDAzureRef: ' + accountIDAzureRef);
    //console.log('accountName: ' + accountName);
    //console.log('comments: ' + comments);
    //console.log('requestorID: ' + requestorID);
    

    this.rs.actionRequestsAWSAccess(wwids, groupID, groupName, accountName, accountIDAzureRef, requestType, action, approverID, requestID, additionalApprovers, requestorName,delegatedUserID,comments).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("Error") == -1) {
        this.accessReposponse = res['body']
        //console.table(this.accessReposponse);
        console.log('Success');
        this.showMsg = true;
        this.showErrMsg = false;
        this.disableButton = false;
        this.resetEverything();
      }
      else {
        this.showMsg = false;
        this.showErrMsg = true;
      }

    })

  }
  resetEverything() {
    this.grantAccessFormAWS.reset();
    this.grantAccessFormOCI.reset();
    this.formGroupDirective.resetForm();
  }

  revokeAccess(cloudType:string) {
    //console.log("Clicked doActionSave")
    this.disableButton = true;
    let wwids = "";
    let groupID  = "";
    let groupName = "";
    let accountName = "";
    let accountIDAzureRef = "";
    let requestID = "";
    let requestType = "Revoke";
    let action = "approved";
    let comments = "None";
    let approverID = sessionStorage.getItem("wwid");
    let additionalApprovers = "None";
    let requestorName = sessionStorage.getItem("Username");
    let delegatedUserID = "None";
    let groupScopeValue = this.selectAccessForm.getRawValue()['groupScope'];
    //console.log("groupScopeValue::",groupScopeValue);
    if(cloudType == "AWS"){
      if (this.grantAccessFormAWS.invalid) {
        return;
      }
       wwids = this.grantAccessFormAWS.value['wwids'];
       groupID = this.grantAccessFormAWS.value['aws_role'].principalId;
       groupName = this.grantAccessFormAWS.value['aws_role'].principalDisplayName;
       accountName = this.grantAccessFormAWS.value['aws_account'].accountName;
       accountIDAzureRef = this.grantAccessFormAWS.value['aws_account'].azureADRef;
       requestID = cloudType;
    }
    else if(cloudType == "Azure"){
      //console.table(this.grantAccessFormAzure.value);
      if (this.grantAccessFormAzure.invalid) {
        return;
      }
      if (groupScopeValue === "Subscription"){
        accountName = this.grantAccessFormAzure.value['azure_account'].subscriptionName;
        accountIDAzureRef = this.grantAccessFormAzure.value['azure_account'].subscriptionID;
        requestID = "Azure Subscription";
      }
      else if (groupScopeValue === "ManagementGroup"){
        requestID = "Azure Management Group";
        accountName = this.grantAccessFormAzure.value['azure_account'].MGName;
        accountIDAzureRef = this.grantAccessFormAzure.value['azure_account'].MGName;
      }
      else if (groupScopeValue === "AzureResource"){
        requestID = "Azure Resources";
        accountName = /[^/]*$/.exec(this.grantAccessFormAzure.value['azure_account'].azureResource)[0] +" ("+this.grantAccessFormAzure.value['azure_account'].resourceGroup+")";
        accountIDAzureRef = /[^/]*$/.exec(this.grantAccessFormAzure.value['azure_account'].azureResource)[0];
      }
      else{
        requestID = "Azure Resource Group";
        accountName = this.grantAccessFormAzure.value['azure_account'].resourceGroupName;
        accountIDAzureRef = this.grantAccessFormAzure.value['azure_account'].resourceGroupName;
      }
      wwids = this.grantAccessFormAzure.value['azure_wwids'];
      groupID = this.grantAccessFormAzure.value['azure_role'].id;
      groupName = this.grantAccessFormAzure.value['azure_role'].displayName;
      
    }
    else{
      if (this.grantAccessFormOCI.invalid) {
        return;
      }
      wwids = this.grantAccessFormOCI.value['oci_wwids'];
      groupID = this.grantAccessFormOCI.value['oci_role'].principalId;
      groupName = this.grantAccessFormOCI.value['oci_role'].principalDisplayName;
      accountName = this.grantAccessFormOCI.value['oci_account'].accountName;
      accountIDAzureRef = this.grantAccessFormOCI.value['oci_account'].azureADRef;
      requestID = cloudType;
    }
   
   
    //console.table(this.grantAccessForm)
    this.rs.actionRequestsAWSAccess(wwids, groupID, groupName, accountName, accountIDAzureRef, requestType, action, approverID, requestID, additionalApprovers, requestorName,delegatedUserID,comments).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      if (JSON.stringify(res).indexOf("Error") == -1) {
        this.accessReposponse = res['body']
        //console.table(this.accessReposponse);
        console.log('Success');
        this.showMsg = true;
        this.showErrMsg = false;
        this.disableButton = false;
        this.resetEverything();
      }
      else {
        this.showMsg = false;
        this.showErrMsg = true;
      }

    })
  }
}
