import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { IAWSAccessRequests } from '../interface/awsaccessrequests';

@Component({
  selector: 'app-pmcrequestaccess',
  templateUrl: './pmcrequestaccess.component.html',
  styleUrls: ['./pmcrequestaccess.component.css']
})
export class PmcrequestaccessComponent implements OnInit {
  pmcAccessForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  local_data: any;
  disableButton = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  // Tetsing code 

  searchUserCtrl = new FormControl();
  filteredUser: any;
  isLoading = false;
  errorMsg: string;


  /// testing code




  constructor(@Inject(DOCUMENT) private document: any, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccessRequests, public dialogRef: MatDialogRef<PmcrequestaccessComponent>, private http: HttpClient, private rs: RepositoryService, private router: Router, private fb: FormBuilder) {
    this.local_data = data;
   
    //console.table(this.local_data);
    //console.log(this.local_data['accessRequests']['groupName'])
  }
  ngOnInit() {
      let cloudType = this.local_data['cloudType']
      this.pmcAccessForm = this.fb.group({
      cloudAccount: [{ value: this.local_data['pmcDataInfo']['teamName'], disabled: true }],
      appID: [{ value: this.local_data['pmcDataInfo']['appid'], disabled: true }],
      teamID: [{ value: this.local_data['pmcDataInfo']['teamid'], disabled: true }],
      requestcloudType : [{ value:  cloudType, disabled: true }],
      comments: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]]





      //approverID : [{ value: this.local_data['awsAccountInfo']['accountOwners'], disabled: true }],
      //accountID : [{ value: this.local_data['awsAccountInfo']['id'], disabled: true }],
      // accountIDAzureRef : [{ value: this.local_data['awsAccountInfo']['azureADRef'], disabled: true }],
      //requestorID : [{ value:  sessionStorage.getItem('wwid'), disabled: true }],
      // requestorName : [{ value:  sessionStorage.getItem('Username'), disabled: true }],
      //requestcloudType : [{ value:  cloudType, disabled: true }]

    });



  }


  get f() {
    return this.pmcAccessForm.controls;
  }
  get comments() {
    return this.pmcAccessForm.get('comments');
  }
  get cloud_account() {
    return this.pmcAccessForm.get('cloudAccount').value;
  }
  get cloud_role() {
    return this.pmcAccessForm.get('groupName').value;
  }



  requestPMCAccess() {
    this.submitted = true;
    this.disableButton = true;
    if (this.pmcAccessForm.invalid) {
      return;
    }
    this.pmcAccessForm.getRawValue()
    //console.table("accessRequests:" + JSON.stringify(this.delegateAccessForm.getRawValue()));
    let groupID = "11ba8f33-2e51-45d1-9142-283f5ef6d1ae";
    let groupName = "ParkMyCloud_Users";
    let accountName = this.pmcAccessForm.getRawValue()['cloudAccount'];
    let accountIDAzureRef = this.pmcAccessForm.getRawValue()['teamID'];
    let approverID = "it069";
    let requestorID = sessionStorage.getItem("wwid");
    let requestorName = sessionStorage.getItem("Username");
    let comments = this.pmcAccessForm.getRawValue()['comments'];
    let requestcloudType = this.pmcAccessForm.getRawValue()['requestcloudType'];
    let accountID = this.pmcAccessForm.getRawValue()['appID'];

    //console.log("comments:" + comments);
    this.rs.requestCloudAccess(groupName, groupID, approverID, accountID, accountIDAzureRef, accountName, comments, requestorID, requestorName, requestcloudType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      //console.log(JSON.stringify(res).indexOf("Error"))
      if (JSON.stringify(res).indexOf("Error") == -1) {
        console.log('Success');
        this.showMsg = true;
        this.dialogRef.close({ event: 'Save', groupName: groupName, accountName: accountName });
        //this.resetEverything();
      }
      else {
        this.showErrMsg = true;
        this.dialogRef.close({ event: 'Falied', groupName: groupName, accountName: accountName });
      }

    })
  }
  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}