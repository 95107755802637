import { IAWSSupportRequests } from '../interface/awssupportrequests';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from './../service/repository.service';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTable } from '@angular/material/table';
import { AuthService } from './../auth/auth.service';
@Component({
  selector: 'app-awssupportrequests',
  templateUrl: './awssupportrequests.component.html',
  styleUrls: ['./awssupportrequests.component.css']
})
export class AwssupportrequestsComponent implements OnInit {

  myawsRequestsTable:  IAWSSupportRequests[] = [];
  myrequestsTableDataSource = new MatTableDataSource<IAWSSupportRequests>(this.myawsRequestsTable);
  selection = new SelectionModel<IAWSSupportRequests>(true, []);
  loading = true;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  successMessage: string;
  errorMessage: string;
  filterVal:string = '';
  hasRecords : boolean = true;
  accessReposponse: string;

  @ViewChild(MatTable) table: MatTable<any>;
  displayedColumns: string[] = ['displayId', 'AccountId','timeCreated', 'status','severityCode', 'subject'];
  pageSize = 5;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(DOCUMENT) private document: any, private auth: AuthService,private http: HttpClient, private rs: RepositoryService, private router: Router, private dialog: MatDialog, private changeDetectorRefs: ChangeDetectorRef) { 
  
  }

  ngOnInit(): void {
    this.getOpenSupportTickets();
    setTimeout(() => {
      this.loading = false;
    },
      environment.spinner_delay_time);

  }
  ngAfterViewInit() {
    this.myrequestsTableDataSource.paginator = this.paginator,
    this.myrequestsTableDataSource.sort = this.sort
  }
  

  getOpenSupportTickets() {
    try {
      let groupID = '';
      let userID = sessionStorage.getItem('wwid');
      let userType = 'user';
      this.rs.getAWSSupportTickets("Open").subscribe(
        (res) => {
          
          //console.table("My Request:::",res)
          this.myawsRequestsTable = res["Items"];
          this.myrequestsTableDataSource.data = this.myawsRequestsTable;
          this.loading = false;
          this.hasRecords = true;
      
        },
        (error) => {
          console.log("error message:" + error['message']);
          //console.log("error error:" + JSON.stringify(error));

        }
      )
    }
    catch (err) {
      console.log("error message:" + err['message']);

    }
  }

  getResolvedSupportTickets() {
    try {
      let groupID = '';
      let userID = sessionStorage.getItem('wwid');
      let userType = 'user';
      this.rs.getAWSSupportTickets("Resolved").subscribe(
        (res) => {
          
          //console.table("My Request:::",res)
          this.myawsRequestsTable = res["Items"];
          this.myrequestsTableDataSource.data = this.myawsRequestsTable;
          this.loading = false;
          this.hasRecords = true;
      
        },
        (error) => {
          console.log("error message:" + error['message']);
          //console.log("error error:" + JSON.stringify(error));

        }
      )
    }
    catch (err) {
      console.log("error message:" + err['message']);

    }
  }
  applyFilter(filterValue: string) {
    this.myrequestsTableDataSource.filter = filterValue.trim().toLowerCase();
  }
  showOptions(event:MatCheckboxChange): void {
    //console.log(event.checked);
    if (event.checked == true){
      this.loading = true;
      this.getResolvedSupportTickets();
    }
    else{
      this.loading = true;
      this.getOpenSupportTickets();
    }
}
}
