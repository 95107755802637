import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RepositoryService } from '../service/repository.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AzurekeyAuditComponent } from '../azurekey-audit/azurekey-audit.component';
import { MatSelect } from '@angular/material/select';
import { ClipboardModule } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-azurekey',
  templateUrl: './azurekey.component.html',
  styleUrls: ['./azurekey.component.css']
})
export class AzurekeyComponent implements OnInit {
  requestRenewalForm: FormGroup;
  //requestRenewalForm.valid: boolean =true;
  showMsg: boolean = false;
  adminView: boolean = true;
  showErrMsg: boolean = false;
  local_data: any;
  hasRecords: boolean = true;
  submitted: boolean = false;
  copybtnshow: boolean = false;
  spinit: boolean = false;
  NewRenewedvalue: any;
  RID: any;
  ID: any;
  @ViewChild(FormGroupDirective)
  //@ViewChild('NewRenewed') key_Secret: MatSelect;
  formGroupDirective: FormGroupDirective;
  awsList: any;
  awsapp: any;
  awsappname: any;
  isValuePresent=false;
  //comments: any;
  //approverNames: any;
  BusinessOwner: any;
  Apps = [];
  appID: any;
  selectedApps: any;
  copyme: any;
  BusinessOwnername: any;
  audit_Enabled: boolean =false; // change this to false before deployment
  formBuilder: any;
  expiry: any;
  loggedin_User: string;
  loggedin_Role: string;
  //expiryDate: any;
 // valid: boolean=true;
  disableButton = false;
  dialog: any;
  // approverNames: any;
  constructor(@Inject(DOCUMENT) private document: any,
  private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,
  private http: HttpClient, private rs: RepositoryService, private router: Router,
  private fb: FormBuilder, private matDialog: MatDialog, private clipboard: ClipboardModule) { }

  ngOnInit(){

    //this.showMsg = true;
    //this.requestRenewalForm.valid;
    this.loggedin_User = sessionStorage.getItem("wwid");
    this.loggedin_Role = sessionStorage.getItem("Role");

    //CHG0104119 changes starts
    // if(this.loggedin_Role == 'CloudOps'){
    //   this.audit_Enabled = true;
    // }
    if(this.loggedin_Role.includes('CloudOps')){
      this.audit_Enabled = true;
    }
    //CHG0104119 changes ends


    this.rs.getExpiredApplications(this.loggedin_User,this.loggedin_Role).subscribe(res => {

      this.awsList = res['Items'];
      for(let i=0;i<res['Items'].length;i++){
        this.Apps.push(res['Items'][i]['appName']);
      }

     // console.log(">>>>>>>>>",this.SelectedApps);
      //let status = res;

      //this.dialogRef.close({ event: 'Save', data: status });
    })

    this.selectedApps = this.Apps;

    //console.log("data in dialog:::::::::" + loggedin_User);
    this.requestRenewalForm = this.fb.group({
      awsappname: ["", [Validators.required]],
      approverNames: [{}, [Validators.required]],
      OwnerName: [{}, [Validators.required]],
      expiryDate: [{}, [Validators.required]],
      comments: ["", [Validators.required, Validators.maxLength(200)]],
      NewRenewed: [""]
     // app_name: ['', [Validators.required]]
    });


  }

  get f() {
    return this.requestRenewalForm.controls;
  }
  get comments() {
    //console.log("value are "+ this.awsappname +"," + this.expiry +this.BusinessOwner + this.comments);
    return this.requestRenewalForm.get('comments');
  }
  get approverNames() {
    //console.log("value are "+ this.awsappname +"," + this.expiry +this.BusinessOwner + this.comments);
    return this.requestRenewalForm.get('approverNames').value;
  }

  onKey(value) {
    //console.log(value);

    this.selectedApps = this.search(value);
    }

  search(value: string) {
      let filter = value.toLowerCase();
      //if(filter != '' || filter != null){
      return this.Apps.filter(option => option.toLowerCase().includes(filter));
      //} else {
        //return this.SelectedApps;
      //}
      //console.log(filter);

    }

  appSelectionChangeAction(value) {
    console.log(value);

    let selection_value = this.awsList.filter(awselected => awselected.appName == value);
    console.log(selection_value);
    this.awsappname=selection_value[0]['appName'];
    this.BusinessOwner = selection_value[0]['ITOwner'];
    this.BusinessOwnername = selection_value[0]['ITOwnerName'];//sessionStorage.getItem("Username");
    this.expiry = selection_value[0]['ExpiryTimeSecret'];
    this.expiry = this.expiry.replace('Z','').replace('T',' ');
    this.RID = selection_value[0]['RecordID'];
    this.ID = selection_value[0]['ID'];
    this.appID = selection_value[0]['APPID'];
    console.log("##@@###@",this.ID);

    this.requestRenewalForm.get('comments').setValue('');
    //this.requestRenewalForm.get('NewRenewed').setValue({});
    //this.comments = '';
    this.NewRenewedvalue = '';
    this.copybtnshow = false;
    this.copyme = false;
    this.showMsg = false;

    console.log(this.requestRenewalForm.status);
   // this.comments= value[]
    // this.requestRenewalForm = this.fb.group({
    //   awsappname: [, [Validators.required]],
    //   approverNames: ['', [Validators.required]],
    //   expiryDate: ['', [Validators.required]],
    //   comments: ['', [Validators.required, Validators.maxLength(100)]]
    //  // app_name: ['', [Validators.required]]
    // });

  }

  // chackValid(){
  //   console.log("value are "+ this.awsappname +"," + this.expiry +this.BusinessOwner + this.comments);
  // }
  disablenable() {
    if (this.requestRenewalForm.invalid || this.spinit == true) {
      return true;
    }else{
      return false;
    }
  }

  doActionSave() {
    console.log("Clicked doActionSave");
    this.spinit = true;
    this.submitted = true;
    this.disableButton = true;
    if (this.requestRenewalForm.invalid) {
      return;
    }
    //console.table(this.requestAccessForm.getRawValue())
    //let valuess = this.onFormSubmit(this.requestRenewalForm.getRawValue());
    console.log("setting value",this.awsappname,this.BusinessOwner,this.BusinessOwnername,this.expiry,this.comments.value);



    // this.NewRenewedvalue = "xyz%.*123akdgb7523jxxi2";
    //bj: String;
    // let bj = this.requestRenewalForm.get('comments').value();

    this.rs.renewTheKey(this.loggedin_User,this.loggedin_Role,this.awsappname,
      this.BusinessOwner,this.BusinessOwnername,this.RID,this.ID, this.comments.value,this.appID).subscribe(res => {
        this.NewRenewedvalue = res['secretText']
        this.spinit = false;
        this.BusinessOwnername = '';
        this.requestRenewalForm.get('awsappname').setValue('');
      });
    //this.key_Secret.focus();
    //console.log("sleeping");
    setTimeout(() => {
      //console.log("awake");
      this.copybtnshow = true;
      this.copyme = true;
      this.showMsg = true;
    },1000);


    // console.log("sleeping");
    // // setTimeout(() => {
    //   console.log("awake");


    //},25000);

    //this.dialogRef.close({event:'Save', data: this.requestAccessForm.getRawValue() });

  }

  onFormSubmit(awsAccountsRequest:object) {

  }

  resetform(){
     // this.requestRenewalForm.get('NewRenewed').setValue({});
      this.requestRenewalForm.get('awsappname').setValue({});
      this.requestRenewalForm.get('OwnerName').setValue({});
      this.requestRenewalForm.get('approverNames').setValue({});
      this.requestRenewalForm.get('expiryDate').setValue({});
      this.requestRenewalForm.get('comments').setValue('');
      this.requestRenewalForm.clearValidators();
      this.requestRenewalForm.clearAsyncValidators();
      this.NewRenewedvalue = '';
      this.BusinessOwner = '';
      this.expiry = '';
      this.awsapp = '';
      this.NewRenewedvalue = '';
      this.BusinessOwnername = '';
      this.showMsg = false;
      this.copybtnshow = false;
      this.copyme = false;
  }

  openHistory() {
    //console.log("PRINT click");
    const dialogConfig = new MatDialogConfig();


    //console.table(awsAccount);
    //console.log("action:::"+action);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width= "120%";
    dialogConfig.height= "75%";
    dialogConfig.data = {
      Name: this.BusinessOwner
    };


    const dialogRef = this.matDialog.open(AzurekeyAuditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

    });
    //console.log("dialogConfig:::"+JSON.stringify(dialogConfig));

  }

}
