import { Component,OnInit,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'CGPortal-Frontend';

  constructor(@Inject(DOCUMENT) private document: any) { }

  ngOnInit() { }   

  
}