import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { IAWSAccounts } from '../interface/awsaccounts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
@Component({
  selector: 'app-requestazureaccess',
  templateUrl: './requestazureaccess.component.html',
  styleUrls: ['./requestazureaccess.component.css']
})
export class RequestazureaccessComponent implements OnInit {
  //awsAccoutsTable: IAWSAccounts[] = [];
  //awsAccoutsTableDataSource = new MatTableDataSource<IAWSAccounts>(this.awsAccoutsTable);
  loading = true;
  azureGroups: any
  azureResourceGroups: any
  filteredResourceGroups : any
  requestAccessForm: FormGroup;
  selectAccessForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  card_header: string = "";
  card_sub_header: string = "";
  showErrMsg: boolean = false;
  showRG: boolean = false;
  showRGIAM: boolean = false;
  local_data: any;
  disableButton = false;
  access_scope: string = "Subscription";
  access_resoruce: string = "";
  hasRecords: boolean = false;
  rgRecords: boolean = false;
  hasRecordsMessage: string = "";
  access_Susbcrition_ID: string = "";
  access_MG_name: string = "";
  access_Susbcrition_Name: string = "";
  azure_location: string = "";
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  resourceTypes: string[];
  constructor(@Inject(DOCUMENT) private document: any, public dialogRef: MatDialogRef<RequestazureaccessComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccounts, private http: HttpClient, private rs: RepositoryService, private router: Router, private fb: FormBuilder) {
    this.local_data = data;

  }

  ngOnInit() {


    //console.table(this.local_data['azureAccountInfo']);
    //console.log('Cloud Type::: ',this.local_data['cloudType']);
    //console.table(this.local_data);
    let cloudType = this.local_data['cloudType']
    this.azure_location = this.local_data['azureAccountInfo']['department']
    if( this.azure_location === "CBS-IT"){
      this.resourceTypes= ['Management Group', 'Subscription', 'Resource Group', 'Azure Resources'];
    }
    else if ( this.azure_location === "Non_CBS_IT"){
      //this.resourceTypes= ['Management Group', 'Subscription'];
      //added on 5/3/2021 to fix not showing management group access to Substription owners
      this.resourceTypes= ['Subscription'];
    }
    else if ( this.azure_location === "CBS-IT-CN"){
       this.resourceTypes= ['Subscription', 'Resource Group'];
    }
    else if ( this.azure_location === "CBS-IT-CN-Legacy"){
      this.resourceTypes= ['Subscription', 'Resource Group'];
    }
   // else if ( this.azure_location === "CBS-IT-CN-POC"){
    else{
      this.resourceTypes= ['Subscription', 'Resource Group'];
    }
   
    this.access_Susbcrition_Name = this.local_data['azureAccountInfo']['subscriptionName']
    this.access_Susbcrition_ID = this.local_data['azureAccountInfo']['subscriptionID']
    this.access_MG_name = /[^/]*$/.exec(this.local_data['azureAccountInfo']['mainAccount'])[0];
    this.access_resoruce = this.access_Susbcrition_Name
    this.card_header = "Request Access to " + this.access_scope + " : " + this.access_Susbcrition_Name
    this.card_sub_header ="Subscription level access is elevated access, typically provided to Cloud Operations Team."
    //console.log("access_MG_name:::", this.access_MG_name)
    this.azureLevelChangeAction(this.access_scope, "NA", this.access_Susbcrition_ID, this.access_MG_name,"NA",this.azure_location);


    this.selectAccessForm = this.fb.group({
      resourceType: ['Subscription', [Validators.required]],
      azure_account: [{ value: this.access_Susbcrition_Name, disabled: true }],
      accountID: [{ value: this.access_Susbcrition_ID, disabled: true }],
      mainAccount: [{ value: this.access_MG_name, disabled: true }],
      departmentName: [{ value: this.azure_location, disabled: true }]
      
    });


    this.requestAccessForm = this.fb.group({
      azure_account: [{ value: this.access_Susbcrition_Name, disabled: true }],
      azure_role: ['', [Validators.required]],
      rg_name : ['NA', [Validators.required]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      approverID: [{ value: this.local_data['azureAccountInfo']['accountOwners'], disabled: true }],
      approverNames: [{ value: this.local_data['azureAccountInfo']['accountOwnerNames'], disabled: true }],
      accountID: [{ value: this.access_Susbcrition_ID, disabled: true }],
      requestorID: [{ value: sessionStorage.getItem('wwid'), disabled: true }],
      requestorName: [{ value: sessionStorage.getItem('Username'), disabled: true }],
      requestcloudType: [{ value: cloudType, disabled: true }],
      mainAccount: [{ value: this.access_MG_name, disabled: true }],
      departmentName: [{ value: this.azure_location, disabled: true }],
      selectedResource: ['Subscription', [Validators.required]],


    });
  }

  onSelectionChange(event: MatRadioChange) {
    this.loading = true;
    this.hasRecords = false;
    //console.log(event.value)
    if (event.value == "Subscription") {
      this.showRG = false
      this.showRGIAM = false
      this.access_scope = "Subscription"
      this.requestAccessForm.controls.approverNames.setValue(this.local_data['azureAccountInfo']['accountOwnerNames']);
      this.requestAccessForm.controls.approverID.setValue(this.local_data['azureAccountInfo']['accountOwners']);
      this.requestAccessForm.controls.selectedResource.setValue(this.access_scope);
      //Added to clear form on selection changes
      this.requestAccessForm.markAsUntouched();
      this.azureGroups = [];
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      //Added to clear form on selection changes  
      //console.log(event.value)
      //console.log(this.selectAccessForm.getRawValue()['azure_account'])
      //console.log(this.selectAccessForm.getRawValue()['accountID'])
      //console.log(this.selectAccessForm.getRawValue()['mainAccount'])
      this.card_header = "Request Access to " + this.access_scope + " : " + this.selectAccessForm.getRawValue()['azure_account']
      this.card_sub_header ="Subscription level access is elevated access, typically used by Cloud Operations Team."

      this.azureLevelChangeAction(this.access_scope, "NA",this.selectAccessForm.getRawValue()['accountID'],this.selectAccessForm.getRawValue()['mainAccount'],"NA",this.selectAccessForm.getRawValue()['departmentName']);

    }
    else if (event.value == "Management Group") {
      this.showRG = false
      this.showRGIAM = false
      this.access_scope = "Management Group"
      this.requestAccessForm.controls.approverNames.setValue(this.local_data['azureAccountInfo']['accountOwnerNames']);
      this.requestAccessForm.controls.approverID.setValue(this.local_data['azureAccountInfo']['accountOwners']);
      this.requestAccessForm.controls.selectedResource.setValue(this.access_scope);
      //Added to clear form on selection changes
      this.requestAccessForm.markAsUntouched();
      this.azureGroups = [];
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      //Added to clear form on selection changes    
      this.card_header = "Request Access to " + this.access_scope + " : " + this.selectAccessForm.getRawValue()['mainAccount']
      this.card_sub_header ="Management Group level access is elevated access, typically provided to Cloud Operations Team."

      //console.log(event.value)
      //console.log(this.selectAccessForm.getRawValue()['azure_account'])
      //console.log(this.selectAccessForm.getRawValue()['accountID'])
      //console.log(this.selectAccessForm.getRawValue()['mainAccount'])
      this.azureLevelChangeAction("ManagementGroup", "NA",this.selectAccessForm.getRawValue()['accountID'], this.selectAccessForm.getRawValue()['mainAccount'],"NA",this.selectAccessForm.getRawValue()['departmentName']);

    }

    else if (event.value == "Azure Resources") {
      this.showRG = false
      this.showRGIAM = true
      this.access_scope = "Azure Resources"
      this.card_header = "Select the Azure Resources from Drop Down. This list will be in 'Resource - Resource Group' Format"
      this.card_sub_header ="Azure Resources access is provided to Application Teams by Resource Group owners. This acccess will be specific to Resources"

      this.requestAccessForm.controls.selectedResource.setValue(this.access_scope);
      // Remove values to clear the form when we switch to Resource group role
      this.requestAccessForm.controls.approverNames.setValue("");
      this.requestAccessForm.controls.approverID.setValue("");
      //Added to clear form on selection changes
      this.requestAccessForm.markAsUntouched();
      this.azureGroups = [];
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      //Added to clear form on selection changes  
      this.getResourcesGroupsWithResource(this.selectAccessForm.getRawValue()['accountID'])
    }
    else {
      this.showRG = true
      this.showRGIAM = false
      this.access_scope = "Resource Group"
      this.card_header = "Select the Resource Group from Drop Down to display roles"
      this.card_sub_header ="Resource Groups access is provided to Application Teams by Resource Group owners."

      this.requestAccessForm.controls.selectedResource.setValue(this.access_scope);
      // Remove values to clear the form when we switch to Resource group role
      this.requestAccessForm.controls.approverNames.setValue("");
      this.requestAccessForm.controls.approverID.setValue("");
      //Added to clear form on selection changes
      this.requestAccessForm.markAsUntouched();
      this.azureGroups = [];
      this.requestAccessForm.controls.comments.setValue("");
      this.requestAccessForm.controls.azure_role.setValue("");
      //Added to clear form on selection changes  
      this.getSubscriptionResourceGroups("All","List",this.selectAccessForm.getRawValue()['accountID'])

    }




    //this.onFormSubmit(this.requestAccessForm.getRawValue())
  }

  azureRGSelectAction(data: string){
    this.loading = true;
    //console.table(data)
    //Added to clear form on selection changes
    this.requestAccessForm.markAsUntouched();
    this.azureGroups = [];
    this.requestAccessForm.controls.comments.setValue("");
    this.requestAccessForm.controls.azure_role.setValue("");
     //Added to clear form on selection changes
    let rgselected: string = data['resourceGroupName'];
    let subscriptionselected: string = data['subscriptionID'];
    let techowner: string = data['techowner'];
    let resourceGroupType: string = data['billtag'];
    let RGTechOnwerName: string = data['RGTechOnwerName'];
    //console.log('techowner: ', techowner);
    //console.log('resourceGroupType: ', resourceGroupType);
    if (resourceGroupType === "infra" || resourceGroupType === "resource"){
     
      this.access_scope = "Subscription"
      this.requestAccessForm.controls.approverNames.setValue(this.local_data['azureAccountInfo']['accountOwnerNames']);
      this.requestAccessForm.controls.approverID.setValue(this.local_data['azureAccountInfo']['accountOwners']);
      //To Get Resource Group Name
      this.requestAccessForm.controls.selectedResource.setValue("Resource Group");
      //console.log(this.selectAccessForm.getRawValue()['azure_account'])
      //console.log(this.selectAccessForm.getRawValue()['accountID'])
      //console.log(this.selectAccessForm.getRawValue()['mainAccount'])
      this.card_header = "Request Access to Resource Group ("+ resourceGroupType +"): "+ rgselected
      this.card_sub_header ="Resource Groups access is provided to Application Teams by Resource Group owners."

      this.azureLevelChangeAction(this.access_scope, "NA",this.selectAccessForm.getRawValue()['accountID'],this.selectAccessForm.getRawValue()['mainAccount'],"NA",this.selectAccessForm.getRawValue()['departmentName']);

    }
    else{
      
      this.card_header = "Request Access to Resource Group : " + rgselected
      this.card_sub_header ="Resource Groups access is provided to Application Teams by Resource Group owners."

      this.requestAccessForm.controls.approverNames.setValue(RGTechOnwerName);
      this.requestAccessForm.controls.approverID.setValue(techowner);
      this.azureLevelChangeAction("ResourceGroup", rgselected ,subscriptionselected, "NA","NA",this.selectAccessForm.getRawValue()['departmentName']);
    }
    
    
  }

  azureRGSelectActionIAM(data: string){
    this.loading = true;
    //console.table(data)
    //Added to clear form on selection changes
    this.requestAccessForm.markAsUntouched();
    this.azureGroups = [];
    this.requestAccessForm.controls.comments.setValue("");
    this.requestAccessForm.controls.azure_role.setValue("");
    //Added to clear form on selection changes     
    let rgselected: string = data['resourceGroup'];
    let parentResource: string = data['parentResource'];
    let resourceScope: string = data['scope'];
    let subscriptionselected: string = data['subscriptionID'];
    let techowner: string = data['techowner'];
    let resourceGroupType: string = data['billtag'];
    let RGTechOnwerName: string = data['RGTechOnwerName'];
    //console.log('techowner: ', techowner);
    //console.log('resourceGroupType: ', resourceGroupType);
    if (resourceGroupType === "infra" || resourceGroupType === "resource"){
     
      this.access_scope = "Subscription"
      this.requestAccessForm.controls.approverNames.setValue(this.local_data['azureAccountInfo']['accountOwnerNames']);
      this.requestAccessForm.controls.approverID.setValue(this.local_data['azureAccountInfo']['accountOwners']);
      //To Get Resource Group Name
      this.requestAccessForm.controls.selectedResource.setValue("Resource Group");
      //console.log(this.selectAccessForm.getRawValue()['azure_account'])
      //console.log(this.selectAccessForm.getRawValue()['accountID'])
      //console.log(this.selectAccessForm.getRawValue()['mainAccount'])
      this.card_header = "Request Access to Resource Group ("+ resourceGroupType +"): "+ rgselected
      this.card_sub_header ="Resource Groups access is provided to Application Teams by Resource Group owners."

      this.azureLevelChangeAction(this.access_scope, "NA",this.selectAccessForm.getRawValue()['accountID'],this.selectAccessForm.getRawValue()['mainAccount'],"NA",this.selectAccessForm.getRawValue()['departmentName']);

    }
    else{
      
      this.card_header = "Request Access to Resource : " + resourceScope.split('/providers/').pop();
      this.card_sub_header ="Azure Resources access is provided to Application Teams by Resource Group owners. This acccess will be specific to Resources"

      this.requestAccessForm.controls.approverNames.setValue(RGTechOnwerName);
      this.requestAccessForm.controls.approverID.setValue(techowner);
      this.azureLevelChangeAction("AzureResource", parentResource ,subscriptionselected, "NA", resourceScope,this.selectAccessForm.getRawValue()['departmentName']);
    }
    
    
  }
  get f() {
    return this.requestAccessForm.controls;
  }
  get comments() {
    return this.requestAccessForm.get('comments');
  }


  get card_title() {
    return this.card_header;
  }
  get card_subtitle() {
    return this.card_sub_header;
  }
  get displaynoroles() {
    return this.hasRecordsMessage;
  }

  azureLevelChangeAction(groupScope: string, resourceGroupID: string, subscriptionID: string, managementGroupID: string, resourceID:string, department:string) {
    this.rgRecords = false;
    this.hasRecords = false;
    //console.log('groupScope: ' + groupScope);
    //console.log('resourceGroupID: ' + resourceGroupID);
    //console.log('subscriptionID: ' + subscriptionID);
    //console.log('managementGroupID: ' + managementGroupID);
    try {
      this.rs.getAzureGroups(groupScope, resourceGroupID, subscriptionID, managementGroupID, resourceID, department).subscribe(res => {
        //console.log("My Response:::",res)
       
        this.loading = false;
        
        if (res['value'].length==0){
          console.log("No Roles for RG.")
          
          this.hasRecords = true;
          this.hasRecordsMessage = "No Roles for this RG"
          this.azureGroups = [];

        }
        else {
          console.log("Has Roles for RG.")
          this.azureGroups = res['value'];
          this.hasRecords = false;
          
          this.hasRecordsMessage = ""
          

        }
        //console.log('azureGroups: ' + this.azureGroups);
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }


  }
  getResourcesGroupsWithResource(subscriptionID: string){
    try {
      this.rs.getResourceGroupsWithResourceIAM(subscriptionID,"All","All").subscribe(res => {
        //console.log(JSON.stringify(res))
        this.azureResourceGroups = res;
        this.loading = false;
        if (this.azureResourceGroups.length > 0){
        this.filteredResourceGroups = this.azureResourceGroups;
        this.rgRecords = false;
        }
        else{
          this.rgRecords = true;
        }
        //console.log('azureResourceGroups: ' + this.azureResourceGroups);
        //console.log('filteredResourceGroups: ' + this.filteredResourceGroups);
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }
    
  }
  getSubscriptionResourceGroups(onwer:string,queryType:string, subscriptionID: string) {
   
    //console.log('subscriptionID: ' + subscriptionID);
    try {
      this.rs.getAzureSubscriptionResourceGroups(onwer, queryType, subscriptionID).subscribe(res => {

        this.azureResourceGroups = res['Items'];
         this.filteredResourceGroups = this.azureResourceGroups;
         this.loading = false;
        //console.log('azureResourceGroups: ' + this.azureResourceGroups.length);
        if (this.azureResourceGroups.length > 0){
         
          this.rgRecords = false;
        }
        else{
          this.rgRecords = true;
        }
        
        //console.log('filteredResourceGroups: ' + this.filteredResourceGroups);
      })
    }
    catch (error) {
      console.log("error message:" + error['message']);
    }


  }
  
  onKey(value) { 
    this.azureResourceGroups = this.search(value);
    }
    
    search(value: string) { 
      let filter = value.toLowerCase();
      //console.log(filter)
      return this.filteredResourceGroups.filter(option => option.resourceGroupName.toLowerCase().includes(filter));
    }

    onKeyIAM(value) { 
      //console.log(value)
      this.azureResourceGroups = this.searchIAM(value);
      }
      
      searchIAM(value: string) { 
        //console.log(value)

        let filter = value.toLowerCase();
        //console.log(filter)
        return this.filteredResourceGroups.filter(option => option.azureResource.toLowerCase().includes(filter));
      }
  onFormSubmit(azureAccountsRequest: object) {
    //this.submitted = true;
    //console.log("Clicked onFormSubmit")
    //console.log(azureAccountsRequest);
    let groupName = "";
    let groupID = "";
    let approverID = "";
    let accountID = "";
    let accountIDAzureRef = "";
    let accountName = "";
    let comments = "";
    let requestorID = "";
    let requestorName = "";
    let requestcloudType = "";

    if (azureAccountsRequest['selectedResource'] == "Resource Group"){
    	groupName = azureAccountsRequest['azure_role']['displayName'];
      groupID = azureAccountsRequest['azure_role']['id'];
      approverID = azureAccountsRequest['approverID'];
      accountID = azureAccountsRequest['azure_account'];
      accountIDAzureRef = azureAccountsRequest['accountID'];
      if (azureAccountsRequest['rg_name']['billtag'] === "infra" || azureAccountsRequest['rg_name']['billtag'] === "resource"){
        accountName = azureAccountsRequest['rg_name']['resourceGroupName']+" ("+azureAccountsRequest['rg_name']['billtag']+")";
      }
      else{
        accountName = azureAccountsRequest['rg_name']['resourceGroupName'];
      }
      comments = azureAccountsRequest['comments'];
      requestorID = azureAccountsRequest['requestorID'];
      requestorName = azureAccountsRequest['requestorName'];
      requestcloudType = "Azure "+ azureAccountsRequest['selectedResource'];  
    }
    else if (azureAccountsRequest['selectedResource'] == "Azure Resources"){
      //console.log("INSIDE RESOURCES")
    	groupName = azureAccountsRequest['azure_role']['displayName'];
      groupID = azureAccountsRequest['azure_role']['id'];
      approverID = azureAccountsRequest['approverID'];
      accountID = azureAccountsRequest['rg_name']['resourceGroup'];
      accountIDAzureRef = azureAccountsRequest['accountID'];
      if (azureAccountsRequest['rg_name']['billtag'] === "infra" || azureAccountsRequest['rg_name']['billtag'] === "resource"){
        accountName = azureAccountsRequest['rg_name']['resourceGroup']+" ("+azureAccountsRequest['rg_name']['billtag']+")";
      }
      else{
        accountName = /[^/]*$/.exec(azureAccountsRequest['rg_name']['azureResource'])[0] +" ("+azureAccountsRequest['rg_name']['resourceGroup']+")";
      }
      comments = azureAccountsRequest['comments'];
      requestorID = azureAccountsRequest['requestorID'];
      requestorName = azureAccountsRequest['requestorName'];
      requestcloudType = azureAccountsRequest['selectedResource'];  
    }
    else{
      groupName = azureAccountsRequest['azure_role']['displayName'];
      groupID = azureAccountsRequest['azure_role']['id'];
      approverID = azureAccountsRequest['approverID'];
      accountID = azureAccountsRequest['azure_account'];
      accountIDAzureRef = azureAccountsRequest['accountID'];
      accountName = azureAccountsRequest['azure_account'];
      comments = azureAccountsRequest['comments'];
      requestorID = azureAccountsRequest['requestorID'];
      requestorName = azureAccountsRequest['requestorName'];
      requestcloudType = "Azure "+ azureAccountsRequest['selectedResource'];
    }
    

    //console.log('groupName: ' + groupName);
    //console.log('groupID: ' + groupID);
    //console.log('approverID: ' + approverID);
    //console.log('accountID: ' + accountID);
    //console.log('accountIDAzureRef: ' + accountIDAzureRef);
    //console.log('accountName: ' + accountName);
    //console.log('comments: ' + comments);
    //console.log('requestorID: ' + requestorID);
    //console.log('requestorName: ' + requestorName);
    //console.log('requestcloudType: ' + requestcloudType);
    
    this.rs.requestCloudAccess(groupName, groupID, approverID, accountID, accountIDAzureRef, accountName, comments, requestorID, requestorName, requestcloudType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      //console.log(JSON.stringify(res).indexOf("Error"))
      if (JSON.stringify(res).indexOf("Error") == -1) {
        console.log('Success');
        this.showMsg = true;
        this.dialogRef.close({ event: 'Save', groupName: groupName, accountName: accountName });
        //this.resetEverything();
      }
      else {
        this.showErrMsg = true;
        this.dialogRef.close({ event: 'Falied', groupName: groupName, accountName: accountName });
      }

    })

  }
  resetEverything() {
    this.requestAccessForm.reset();
    this.formGroupDirective.resetForm();
  }
 
  //Need to check the usage - Testing while OCI Integration
  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;
    this.disableButton = true;
    if (this.requestAccessForm.invalid) {
      return;
    }
    //console.table(this.requestAccessForm.getRawValue())
    this.onFormSubmit(this.requestAccessForm.getRawValue())
    //this.dialogRef.close({event:'Save', data: this.requestAccessForm.getRawValue() });

  }

  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}

