import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from './../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthService]
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService,private router: Router) { }

  ngOnInit() {
    var isLoggedIn = localStorage.getItem('isLoggedIn');
    var isAuthSuccess = sessionStorage.getItem('isAuthSuccess');
	  if (isLoggedIn == 'true' && isAuthSuccess =='true') {
      this.router.navigate(['resources']);
    }
  }
 
  onSubmit() {
    this.authService.login();
  }

}
