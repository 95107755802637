import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { IAWSAccessRequests } from '../interface/awsaccessrequests';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-aadactionsdialog',
  templateUrl: './aadactionsdialog.component.html',
  styleUrls: ['./aadactionsdialog.component.css']
})
export class AadactionsdialogComponent implements OnInit {
  denyAccessForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  local_data: any;
  disableButton = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  // Tetsing code 

  searchUserCtrl = new FormControl();
  filteredUser: any;
  isLoading = false;
  errorMsg: string;


  /// testing code




  constructor(@Inject(DOCUMENT) private document: any, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccessRequests, public dialogRef: MatDialogRef<AadactionsdialogComponent>, private http: HttpClient, private rs: RepositoryService, private router: Router, private fb: FormBuilder) {
    this.local_data = data;
    //console.table(this.local_data);
    //console.log(this.local_data['accessRequests']['groupName'])
  }
  ngOnInit() {
    this.denyAccessForm = this.fb.group({
     actionCommets: ['', [Validators.required, Validators.maxLength(100),Validators.minLength(3)]],
    });

    

  }

 
  get f() {
    return this.denyAccessForm.controls;
  }
  get actionCommets() {
    return this.denyAccessForm.get('actionCommets');
  }
 


  declineRequest() {
    this.submitted = true;
    this.disableButton = true;
    if (this.denyAccessForm.invalid) {
      return;
    }

   let actionCommets = this.denyAccessForm.getRawValue()['actionCommets'];
   
   this.dialogRef.close({ event: 'Save', data: actionCommets  });
      
  }
  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}