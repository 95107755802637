import { Component, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  contactForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
  constructor(private fb: FormBuilder, private rs: RepositoryService, private router: Router) { }

  ngOnInit() {
    var isAuthSuccess = sessionStorage.getItem('isAuthSuccess');
	  //if (isLoggedIn == null || isAuthSuccess == null) {
    if ( isAuthSuccess == null) {
      this.router.navigate(['']);
    }
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required, Validators.maxLength(50)]],
      comments: ['', [Validators.required, Validators.maxLength(250)]],
      requestorID: [{ value: sessionStorage.getItem('wwid'), disabled: true }],
      requestorName: [{ value: sessionStorage.getItem('Username'), disabled: true }],
      messageType: [{ value: 'feedback', disabled: true }]
    });

  }
  get f() {
    return this.contactForm.controls;
  }
  get comments() {
    return this.contactForm.get('comments');
  }

  get subject() {
    return this.contactForm.get('subject');
  }
  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;    
    if (this.contactForm.invalid) {
      return;
    }
    //console.table(this.contactForm.getRawValue())
    let toAddress = 'cbsit.cloudgovernance <cbsit.cloudgovernance@cummins.com>';
    let requestorID = this.contactForm.getRawValue().requestorID;
    let requestorName = this.contactForm.getRawValue().requestorName;
    let message = this.contactForm.getRawValue().comments;
    let subject = this.contactForm.getRawValue().subject;
    let accountName = 'None';
    let groupName = 'None';
    let messageType = this.contactForm.getRawValue().messageType;

    //console.log("toAddress:", toAddress);

    this.rs.sendEmails(toAddress, requestorID, requestorName, message, subject, accountName, groupName, messageType).subscribe(res => {
      //console.log('res: ' + JSON.stringify(res));
      //console.log(JSON.stringify(res).indexOf("Error"))
      if(JSON.stringify(res).indexOf("Error") == -1){
       // console.log('Success');
        this.showMsg = true;
        this.resetEverything()
      }
      else{
        //console.log('Fail');
        this.showErrMsg = true;
        //this.resetEverything()
      }
    })
    //this.dialogRef.close({event:'Send', data: this.contactForm.getRawValue() });
  }
  resetEverything() {
    this.contactForm.reset();
    this.formGroupDirective.resetForm();
  }
}
