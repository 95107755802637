import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pimstgpage',
  templateUrl: './pimstgpage.component.html',
  styleUrls: ['./pimstgpage.component.css']
})
export class PimstgpageComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document,private router: Router) { }

  ngOnInit(): void {
    let arraccessToken: string[] = this.document.location.href.split("access_token=");

    if(arraccessToken[1]){
      var access_token = arraccessToken[1].split('&token_type')[0];
      sessionStorage.setItem(environment.accessToken, access_token);
    }
  //console.log("User access_token::::",access_token)
  this.router.navigate(['azurepim']);
  }

}
