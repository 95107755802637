import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { RepositoryService } from './../service/repository.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormGroupDirective } from '@angular/forms';
import { IAWSAccounts } from '../interface/awsaccounts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
 
@Component({
  selector: 'app-awsaccessrequest',
  templateUrl: './awsaccessrequest.component.html',
  styleUrls: ['./awsaccessrequest.component.css']
})
export class AwsaccessrequestComponent implements OnInit {
  //awsAccoutsTable: IAWSAccounts[] = [];
  //awsAccoutsTableDataSource = new MatTableDataSource<IAWSAccounts>(this.awsAccoutsTable);
  awsList: any
  awsGroups: any
  requestAccessForm: FormGroup;
  submitted: boolean = false;
  showMsg: boolean = false;
  showErrMsg: boolean = false;
  local_data: any;
  cloudTypeCheck:boolean ;
  disableButton = false;
  @ViewChild(FormGroupDirective)
  formGroupDirective: FormGroupDirective;
 
  constructor(@Inject(DOCUMENT) private document: any, public dialogRef: MatDialogRef<AwsaccessrequestComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: IAWSAccounts,private http: HttpClient, private rs: RepositoryService, private router: Router, private fb: FormBuilder)
  {
    this.local_data = data;
  }
  ngOnInit() {
   
    //console.log('azureADRef::: ' + this.local_data['awsAccountInfo']['azureADRef']);
    //console.log('Cloud Type::: ',this.local_data['cloudType']);
    //console.table(this.local_data);
    let cloudType = this.local_data['cloudType']
    if(cloudType=="AWS"){
      this.cloudTypeCheck = true;
      this.awsLevelChangeAction(this.local_data['awsAccountInfo']['azureADRef']);
    }
    else{
      this.cloudTypeCheck = false;
      this.ociLevelChangeAction(this.local_data['awsAccountInfo']['azureADRef'],this.local_data['awsAccountInfo']['accountName']);
    }
   
   
   
    this.requestAccessForm = this.fb.group({
      aws_account: [{ value: this.local_data['awsAccountInfo']['accountName'], disabled : true }],
      aws_role: ['', [Validators.required]],
      comments: ['', [Validators.required, Validators.maxLength(100)]],
      approverID : [{ value: this.local_data['awsAccountInfo']['accountOwners'], disabled: true }],
      approverNames : [{ value: this.local_data['awsAccountInfo']['accountOwnerNames'], disabled: true }],
      accountID : [{ value: this.local_data['awsAccountInfo']['id'], disabled: true }],
      accountIDAzureRef : [{ value: this.local_data['awsAccountInfo']['azureADRef'], disabled: true }],
      requestorID : [{ value:  sessionStorage.getItem('wwid'), disabled: true }],
      requestorName : [{ value:  sessionStorage.getItem('Username'), disabled: true }],
      requestcloudType : [{ value:  cloudType, disabled: true }]
 
    });
  }
  /*getAllAWSAccounts() {
    this.rs.getAWSAccounts("All", "List").subscribe(res => {
      this.awsList = res['Items'];
    })
  }*/
  get f() {
    return this.requestAccessForm.controls;
  }
  get comments() {
    return this.requestAccessForm.get('comments');
  }
  get aws_account() {
    return this.requestAccessForm.get('aws_account').value;
  }
  awsLevelChangeAction(data: string) {
    let awsselected: string = data;
    //console.log('awsselected: ' + awsselected);
    try{
      this.rs.getAWSAccountGroups(awsselected).subscribe(res => {
        this.awsGroups = res['value'];
        //console.log('awsGroups: ' + JSON.stringify(this.awsGroups));
      })
    }
    catch (error){
      console.log("error message:" + error['message']);
    }
   
   
  }
 
  ociLevelChangeAction(data: string, compartment:string) {
 
    //console.log('compartment: ' + compartment);
    if(compartment.includes("(root)")){
      compartment = compartment.substr(0,compartment.indexOf(' '));
    }
    try{
      //console.log('compartment: ' + compartment);
      this.rs.getOCICompartmentGroups(data,compartment).subscribe(res => {
        this.awsGroups = res['value'];
        //console.log('awsGroups: ' + this.awsGroups);
      })
    }
    catch (error){
      console.log("error message:" + error['message']);
    }
   
   
  }

  onFormSubmit(awsAccountsRequest:object) {
    //this.submitted = true;
    let cloudType = this.local_data['cloudType']
    // console.log(JSON.stringify(this.requestAccessForm.value));
    let groupName;
    let groupID;
    let approverID;
    let accountID = awsAccountsRequest['accountID'];
    let accountIDAzureRef = awsAccountsRequest['accountIDAzureRef'];
    let accountName = awsAccountsRequest['aws_account'];
    let comments = awsAccountsRequest['comments'];
    let requestorID = awsAccountsRequest['requestorID'];
    let requestorName = awsAccountsRequest['requestorName'];
    let requestcloudType = awsAccountsRequest['requestcloudType'];
 
    if(cloudType=="OCI") {
      groupName = awsAccountsRequest['aws_role']['displayName']
      groupID = awsAccountsRequest['aws_role']['id'];
      if( awsAccountsRequest['aws_role']['description'].toLowerCase().includes('appid')) {
        approverID = awsAccountsRequest['aws_role']['description'];
      }
      else {
        approverID = awsAccountsRequest['approverID'];
      }
    }
 
    else {
      groupName = awsAccountsRequest['aws_role']['principalDisplayName'];
      groupID = awsAccountsRequest['aws_role']['principalId'];
      approverID = awsAccountsRequest['approverID'];
    }
 
    console.log('groupName: ' + groupName);
    console.log('groupID: ' + groupID);
    console.log('approverID: ' + approverID);
    console.log('accountID: ' + accountID);

 
    //console.log('groupName: ' + groupName);
    //console.log('groupID: ' + groupID);
    //console.log('approverID: ' + approverID);
    //console.log('accountID: ' + accountID);
    //console.log('accountIDAzureRef: ' + accountIDAzureRef);
    //console.log('accountName: ' + accountName);
    //console.log('comments: ' + comments);
    //console.log('requestorID: ' + requestorID);
    this.rs.requestCloudAccess(groupName, groupID, approverID, accountID, accountIDAzureRef, accountName, comments, requestorID, requestorName, requestcloudType).subscribe(res => {
          //console.log('res: ' + JSON.stringify(res));
          //console.log(JSON.stringify(res).indexOf("Error"))
          if(JSON.stringify(res).indexOf("Error") == -1){
            console.log('Success');
            this.showMsg= true;
            this.dialogRef.close({event:'Save', groupName: groupName, accountName:accountName });
            //this.resetEverything();
          }
          else{
            this.showErrMsg= true;
            this.dialogRef.close({event:'Falied', groupName: groupName, accountName:accountName });
          }
         
    })
 
  }
  resetEverything() {
    this.requestAccessForm.reset();
    this.formGroupDirective.resetForm();
  }
  //Need to check the usage - Testing while OCI Integration
  doActionSave() {
    //console.log("Clicked doActionSave")
    this.submitted = true;
    this.disableButton = true;  
    if (this.requestAccessForm.invalid) {
      return;
    }
    //console.table(this.requestAccessForm.getRawValue())
    this.onFormSubmit(this.requestAccessForm.getRawValue())
    //this.dialogRef.close({event:'Save', data: this.requestAccessForm.getRawValue() });
 
  }
 
  closeDialog() {
    //console.table("Clicked Cancel")
    this.dialogRef.close({ event: 'Cancel' });
  }
}